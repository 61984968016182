import { styled } from "@mui/system";
import { Card, CardContent, Box, Grid2 as Grid } from "@mui/material";

export const StyledCard = styled(Card)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[300]}`,
  borderRadius: "10px",
  boxShadow: "none",
  height: "11rem",
  width: "100%",
}));

export const StyledCardContent = styled(CardContent)(({ theme }) => ({
  padding: "1rem 0 1rem 1rem",
}));

export const StyledBox = styled(Box)(({ theme }) => ({
  fontSize: "1rem",
  color: theme.palette.grey[600],
}));

export const DrawerStyleGrid = styled(Grid)(({ theme }) => ({
  fontFamily: "inherit",
  background: theme.palette.grey[100],
  border: `1px solid ${theme.palette.grey[100]}`,
  borderRadius: " 12px",
  alignItems: "center",
}));
