import { styled } from "@mui/system";
import { Grid2 as Grid, Box } from "@mui/material";

export const TableHeader = styled(Grid)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  color: theme.palette.white.light,
  width: "100%",
  minHeight: "3rem",
  height: "auto",
  borderRadius: "0.75rem 0.75rem 0rem 0rem",
  borderTop: `1px solid ${theme.palette.text.secondary}`,
  borderRight: `1px solid ${theme.palette.text.secondary}`,
  borderLeft: `1px solid ${theme.palette.text.secondary}`,
  background: theme.palette.pending.main,
  padding: "0.5rem 1rem 0.5rem 0.5rem ",
}));

export const TableContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  overflowXX: "auto",
  fontSize: "1rem",
}));

export const TableCell = styled(Grid)(({ theme }) => ({
  textAlign: "left",
  display: "flex",
  alignItems: "center",
}));

export const SortableHeader = styled(TableCell)(({ theme }) => ({
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
}));

export const TableRow = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  borderRadius: "0.75rem",
  border: `1px solid ${theme.palette.grey[400]}`,
  background: theme.palette.grey[100],
  marginTop: "0.75rem",
  padding: "0 0.5rem 0 0.5rem",
  minHeight: "6rem",
}));
