import React, { useState } from "react";
import { Grid2 as Grid, Tooltip, Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import {
  BreathIcon,
  HumidityIcon,
  TemperatureIcon,
  GearIcon,
  CloudIcon,
  SensorIcon,
  ConnectivityIcon,
  Co2Icon,
} from "components/icons";
import { iconStyle } from "pages/Devices/Devices.styles";
import { SignalIcon } from "components/icons/SignalIcon";
import { useTranslation } from "react-i18next";

interface IndicatorProps {
  indicator: {
    icon: string;
    tooltip: string;
    showValueOnHoover?: boolean;
    showValue: boolean;
    value: string;
    status: string;
    cardColor: any;
  };
}

export enum Backgrounds {
  breath = "breath",
  humidity = "humidity",
  temperature = "temperature",
  gear = "gear",
  cloud = "cloud",
  sensor = "sensor",
  connection = "connection",
  co2 = "co2",
  noSignal = "noSignal",
  lowSignal = "lowSignal",
  excellentSignal = "excellentSignal",
  goodSignal = "goodSignal",
  none = "none",
}

const ICON_COMPONENTS: { [key in Backgrounds]: React.ElementType } = {
  [Backgrounds.temperature]: TemperatureIcon,
  [Backgrounds.humidity]: HumidityIcon,
  [Backgrounds.sensor]: SensorIcon,
  [Backgrounds.connection]: ConnectivityIcon,
  [Backgrounds.breath]: BreathIcon,
  [Backgrounds.gear]: GearIcon,
  [Backgrounds.cloud]: CloudIcon,
  [Backgrounds.co2]: Co2Icon,
  [Backgrounds.noSignal]: SignalIcon,
  [Backgrounds.lowSignal]: SignalIcon,
  [Backgrounds.excellentSignal]: SignalIcon,
  [Backgrounds.goodSignal]: SignalIcon,
  [Backgrounds.none]: React.Fragment,
};

const IndicatorContent: React.FC<{
  icon: React.ElementType;
  cardColor: any;
  showValue: boolean;
  value: string;
  hoverHandlers: {
    onMouseEnter: () => void;
    onMouseLeave: () => void;
  };
}> = ({ icon: IconComponent, cardColor, showValue, value, hoverHandlers }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Grid display="flex" alignItems="center" {...hoverHandlers}>
      <IconComponent sx={iconStyle(theme, cardColor.main)} />
      {showValue && (
        <Typography sx={{ marginLeft: theme.spacing(1) }}>
          {t(value)}
        </Typography>
      )}
    </Grid>
  );
};

export const Indicator: React.FC<IndicatorProps> = ({ indicator }) => {
  const [hover, setHover] = useState(!indicator.showValueOnHoover);

  const IconComponent =
    ICON_COMPONENTS[indicator.icon as Backgrounds] || React.Fragment;

  const showValue = indicator.showValueOnHoover ? hover : indicator.showValue;

  const hoverHandlers = {
    onMouseEnter: () => setHover(true),
    onMouseLeave: () => setHover(!indicator.showValueOnHoover),
  };

  return (
    <Grid sx={{ cursor: "pointer" }}>
      {indicator.tooltip ? (
        <Tooltip
          title={
            <Typography
              sx={{ fontSize: "1rem" }}
              dangerouslySetInnerHTML={{ __html: indicator.tooltip }}
            />
          }
          key={indicator.icon}
        >
          <div>
            <IndicatorContent
              icon={IconComponent}
              cardColor={indicator.cardColor}
              showValue={showValue}
              value={indicator.value}
              hoverHandlers={hoverHandlers}
            />
          </div>
        </Tooltip>
      ) : (
        <IndicatorContent
          icon={IconComponent}
          cardColor={indicator.cardColor}
          showValue={showValue}
          value={indicator.value}
          hoverHandlers={hoverHandlers}
        />
      )}
    </Grid>
  );
};
