import React from "react";
import { SvgIcon } from "@mui/material";

interface IconProps {
  sx?: any;
}

export const AlertIcon: React.FC<IconProps> = ({ sx }) => {
  return (
    <SvgIcon viewBox="0 0 40 40" sx={sx}>
      <g clipPath="url(#clip0_110_161)">
        <path
          d="M19.9999 13.3333V20M19.9999 26.6666H20.0166M36.6666 20C36.6666 29.2047 29.2047 36.6666 19.9999 36.6666C10.7952 36.6666 3.33325 29.2047 3.33325 20C3.33325 10.7952 10.7952 3.33331 19.9999 3.33331C29.2047 3.33331 36.6666 10.7952 36.6666 20Z"
          stroke="red"
          strokeWidth="3.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
};
