import { useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import { getDeviceStatus } from "services/fleet.service";

import { QueryKeys } from "constants/queryKeys.constants";

interface Options {
  serialNumber: string;
  refetchInterval?: number;
}

function useDeviceApi(options: Options) {
  const queryClient = useQueryClient();
  const [refetchInterval, setRefetchInterval] = useState(
    options.refetchInterval || 5000
  );

  const device = useQuery({
    queryKey: [QueryKeys.DEVICE_STATUS, options.serialNumber],
    queryFn: async () => getDeviceStatus(options.serialNumber),
    refetchInterval,
    refetchIntervalInBackground: false,
    enabled: !!options.serialNumber,
  });

  const refreshDevice = () => {
    queryClient.invalidateQueries({
      queryKey: [QueryKeys.DEVICE_STATUS, options.serialNumber],
    });
  };

  return {
    device,
    setRefetchInterval,
    refreshDevice,
  };
}

export default useDeviceApi;
