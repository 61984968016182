import React, { useEffect, useState } from "react";
import moment from "moment";
import Grid from "@mui/material/Grid2";
import {
  useHeaderStore,
  ProgressBarStatus,
  InitProgressBarOptions,
  InitHeaderOptions,
} from "stores/header.store";
import {
  SortOrder,
  containerStyle,
  iconsLink,
  participantPerPage,
  graphType,
} from "../../constants";
import { ROUTES } from "constants/routes.constants";
import { useParams } from "react-router-dom";
import useTrialApi from "hooks/useTrialApi.hook";
import StudyTabs from "./Components/studyTabs.component";
import StudyHeader from "./Components/studyHeader.component";
import { useLeftNavigationStore } from "stores/navigation.store";
import { useNavigate } from "react-router-dom";
import { buildLocalUrl } from "utils/url.utils";
import { useIsMobile } from "hooks/media.hook";
import { useTheme } from "@mui/material/styles";
import StudyTabContent from "./Components/studyTabContent.component";
import { StyledTitle } from "pages/Participants/Participants.styles";
import Button, { ButtonColor } from "components/Button";
import Search from "components/Search.component";
import { MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import ParticipantsList from "pages/Participants/Components/ParticipantsList.component";
import NewParticipant from "features/trials/components/createTrial/NewParticipant";
import {
  ParticipantSampleCapturedStatus,
  TRIAL_ANALYTICS_EXPAND,
  TrialAnnotationStatus,
} from "services/cde.service";
import Loading from "components/Loading";
import useTrialCardApi from "features/trials/TrialCards/useTrialCardApi.hook";
import { useTranslation } from "react-i18next";
import {
  StyledGrid,
  annotationCountFontStyle,
  annotationTotalFontStyle,
  graphHoverFontStyle,
} from "./StudiesDashboard.styles";
import { useStudiesStore } from "./studies.store";
import Chart from "components/Chart/Chart.component";
import useStudiesDashboardApi, {
  TIMEPERIOD,
  TIMEPERIOD_DATEFORMAT,
} from "hooks/useStudiesDashboardApi.hook";
import { CardStatus, getCardColor } from "components/Card";
import { useHelpers } from "pages/Participants/usehelpers";
import { CustomError } from "utils/privateHttp.utils";
import StudyGraphHeading from "./Components/studyGraphHeading.component";
import useTrialStore from "stores/trial.store";

const StudyParticipants = () => {
  const { t } = useTranslation();
  const { trialId } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const [total, setTotal] = useState(0);
  const initHeader = useHeaderStore((state) => state.initialize);
  const isMobile = useIsMobile();
  const [openNewParticipant, setOpenNewParticipant] = useState(false);
  const reset = useStudiesStore((state) => state.reset);

  const pieChartconfig = useStudiesStore(
    (state) => state.studiesPieChartConfig
  );
  const barChartconfig = useStudiesStore(
    (state) => state.studiesBarChartConfig
  );
  const studiesPieChartLayout = useStudiesStore(
    (state) => state.studiesPieChartLayout
  );
  const studiesBarChartLayout = useStudiesStore(
    (state) => state.studiesBarChartLayout
  );
  const setEngagementChartData = useStudiesStore(
    (state) => state.setStudiesBarChartData
  );
  const setTrialData = useTrialStore((state) => state.setTrialData);
  const getTrialName = useTrialStore((state) => state.getTrialName());
  const getMinExperimentPerParticipant = useTrialStore((state) =>
    state.getMinExperimentPerParticipant()
  );
  const trialFields = useTrialStore((state) => state.otherFields);
  const pieChartData = useStudiesStore((state) => state.studiesPieChartData);
  const setEngagementChartDurationUnit = useStudiesStore(
    (state) => state.setEngagementChartDurationUnit
  );
  const durationUnit = useStudiesStore(
    (state) => state.engagementChartDurationUnit
  );
  const engagementChartData = useStudiesStore(
    (state) => state.studiesBarChartData
  );
  const setStudiesPieChartData = useStudiesStore(
    (state) => state.setStudiesPieChartData
  );
  const { addNavigationState, switchNavigationState } = useLeftNavigationStore(
    (state) => state
  );
  const dashboardCdes = useLeftNavigationStore((state) => state.dashboardCdes);
  const canViewDashboard = useStudiesStore((state) => state.canViewDashboard);
  const queries = useTrialApi({
    trialId: trialId || "",
    take: participantPerPage,
    skip: 0,
    expand: `${TRIAL_ANALYTICS_EXPAND.ANNOTATIONS}|`,
    participants: true,
    order: `createdAt|${SortOrder.ASC}`,
  });

  useEffect(() => {
    setTrialData(queries.trial.data || {});
  }, [queries.trial.isFetched]);

  const minExperiments = getMinExperimentPerParticipant;

  const participantsQueries = useStudiesDashboardApi({
    trialId: trialId,
    resultsGraph: false,
    engagementGraph: true,
    participantsGraph: true,
    take: 0,
    skip: 0,
    expand: `${TRIAL_ANALYTICS_EXPAND.ANNOTATIONS}|`,
    period: TIMEPERIOD.LASTFIFTEENDAYS,
  });

  const { deleteParticipant } = useTrialCardApi({
    trialId: trialId || "",
    participants: false,
    annotations: false,
  });

  const handleNavigation = (path: string) => {
    navigate(
      buildLocalUrl({
        path,
        params: { trialId },
      })
    );
  };

  const onSubmit = (params: any) => {
    const options = {
      ...queries.options,
      ...{ pui: params.search },
      skip: 0,
    };
    queries.setOptions(options);
  };

  const handleDeleteParticipant = async (participant: any) => {
    const uuid = participant.uuid;
    deleteParticipant.mutate(uuid);
    queries.refreshTrial();
  };

  const handleClickOpen = () => {
    setOpenNewParticipant(true);
  };

  const handleClose = async () => {
    setOpenNewParticipant(false);
  };

  const handleUnitsChange = (event: SelectChangeEvent) => {
    const unit = event.target.value;
    setEngagementChartData([]);
    setEngagementChartDurationUnit(unit as TIMEPERIOD);

    const options = {
      ...participantsQueries.options,
      period: unit,
      participantsGraph: false,
    };
    participantsQueries.setOptions(options);
  };

  const { createNewParticipant, handleSorting } = useHelpers(
    queries,
    trialId || "",
    handleClose
  );

  useEffect(() => {
    const cdeId = trialFields.cdeId;
    if (!cdeId) return;
    const cdeExists = canViewDashboard(cdeId);
    if (!cdeExists) {
      throw new CustomError(
        t("studiesDashboard.forbidden.error.message"),
        "403"
      );
    }
    return () => {
      reset();
    };
  }, [queries.trial.isFetched, dashboardCdes, trialFields.cdeId]);

  useEffect(() => {
    setEngagementChartDurationUnit(TIMEPERIOD.LASTFIFTEENDAYS);
    addNavigationState("studiesDashboard", [
      {
        icon: iconsLink.back,
        label: "studiesDashboard.leftnavigation.backtostudies.label",
        onClick: () => handleNavigation(ROUTES.STUDIES_DASHBOARD),
        backgroundColor: theme.palette.common.white,
        color: theme.palette.primary.dark,
      },
      {
        icon: iconsLink.studyOverview,
        label: "studiesDashboard.study.overview.tab.label",
        onClick: () =>
          handleNavigation(ROUTES.STUDIES_DASHBOARD_STUDY_OVERVIEW),
        color: theme.palette.grey[600],
      },
      {
        icon: iconsLink.studyParticipants,
        label: "studiesDashboard.study.participants.tab.label",
        onClick: () =>
          handleNavigation(ROUTES.STUDIES_DASHBOARD_STUDY_PARTICIPANTS),
        backgroundColor: theme.palette.grey[400],
        color: theme.palette.common.white,
      },
      {
        icon: iconsLink.studyDevices,
        label: "studiesDashboard.study.devices.tab.label",
        onClick: () => handleNavigation(ROUTES.STUDIES_DASHBOARD_STUDY_DEVICES),
        color: theme.palette.grey[600],
      },
    ]);
    switchNavigationState("studiesDashboard");
    initHeader(
      {
        mainTitle: {
          label: "header.title.main.label.study",
          text: getTrialName,
        },
        subTitleItems: [],
        showMenu: true,
      } as InitHeaderOptions,
      {
        activeStep: 1,
        allowAction: true,
        steps: [
          {
            label: "progressBar.step.trialsManagement",
            status: ProgressBarStatus.SUCCESS,
            link: ROUTES.STUDIES_DASHBOARD,
            enableLink: true,
          },
          {
            label: "progressBar.step.studyParticipants",
            status: ProgressBarStatus.IN_PROGRESS,
            link: ROUTES.STUDIES_DASHBOARD_STUDY_OVERVIEW,
            enableLink: false,
          },
        ],
      } as InitProgressBarOptions,
      [
        {
          key: "trialId",
          value: trialId || "",
        },
      ]
    );
  }, [getTrialName]);

  useEffect(() => {
    if (!participantsQueries.TrialAllParticipants.isFetched) return;
    if (!participantsQueries.TrialAllParticipants.data) return;
    const participantsStatus = [
      {
        key: ParticipantSampleCapturedStatus.COMPLETED,
        value: 0,
        placeholder: `studyParticipant.statusChart.${ParticipantSampleCapturedStatus.COMPLETED}.label`,
        color: theme.palette.success.main,
      },
      {
        key: ParticipantSampleCapturedStatus.NOTCOMPLETED,
        value: 0,
        placeholder: `studyParticipant.statusChart.${ParticipantSampleCapturedStatus.NOTCOMPLETED}.label`,
        color: theme.palette.warning.main,
      },
    ];
    let sampleCount = 0;
    participantsQueries.TrialAllParticipants?.data?.data.forEach(
      (participant: any) => {
        if (participant.annotations.complete) {
          sampleCount++;
          participant.annotations.complete >= minExperiments
            ? participantsStatus.find(
                (status) =>
                  status.key === ParticipantSampleCapturedStatus.COMPLETED
              )!.value++
            : participantsStatus.find(
                (status) =>
                  status.key === ParticipantSampleCapturedStatus.NOTCOMPLETED
              )!.value++;
        }
        setTotal(sampleCount);
      }
    );

    const result = participantsStatus.reduce<{
      values: number[];
      placeholders: string[];
      colors: string[];
    }>(
      (acc, { value, placeholder, color }) => {
        if (value > 0) {
          acc.values.push(value);
          acc.placeholders.push(t(placeholder));
          acc.colors.push(color);
        }
        return acc;
      },
      { values: [], placeholders: [], colors: [] }
    );
    setStudiesPieChartData([{ type: graphType.EMPTY }]);
    if (!result.values.length) return;

    setStudiesPieChartData([
      {
        values: result.values,
        labels: result.placeholders,
        type: "pie",
        hole: 0.6,
        marker: {
          colors: result.colors,
        },
        pull: [0.01, 0.01, 0.01, 0.01],
        textinfo: result.values.length > 1 ? "value" : "none",
        textfont: {
          size: 20,
          color: theme.palette.common.white,
        },
      },
    ]);
  }, [participantsQueries.TrialAllParticipants.isFetched]);

  const pieChartLayout = {
    ...studiesPieChartLayout,
    annotations: [
      {
        showarrow: false,
        text: `${total}`,
        x: 0.5,
        y: 0.5,
        font: annotationTotalFontStyle(theme),
      },
      {
        showarrow: false,
        text: t("studyParticipants.status.chart.total.label"),
        x: 0.5,
        y: 0.43,
        font: annotationCountFontStyle(theme),
      },
    ],
  };

  studiesBarChartLayout.shapes.forEach((_: any, index: number) => {
    studiesBarChartLayout.shapes[index].line.color = theme.palette.grey[300];
  });

  const getStatusByType = (type: TrialAnnotationStatus) => {
    switch (type) {
      case TrialAnnotationStatus.complete:
        return CardStatus.SUCCESS;
      case TrialAnnotationStatus.terminated:
        return CardStatus.WARNING;
      case TrialAnnotationStatus.timeout:
        return CardStatus.FAIL;
      case TrialAnnotationStatus.inProgress:
        return CardStatus.IN_PROGRESS;
      default:
        return CardStatus.INFO;
    }
  };

  useEffect(() => {
    const analytics = participantsQueries.ParticipantsEngagementAnalytics.data;
    if (!analytics) return;
    setEngagementChartData([{ type: graphType.EMPTY }]);
    const isEmpty = !analytics.total;
    if (isEmpty) return;

    const format = TIMEPERIOD_DATEFORMAT[durationUnit];
    const buildChartData = (annotationType: TrialAnnotationStatus) => {
      const data = Object.entries(analytics.bucket).map(([date, value]) => {
        const annotationData = value as { annotations: any };
        return {
          x: date,
          y: annotationData.annotations[annotationType],
        };
      });

      return {
        x: data.map((item) =>
          moment(item.x, "YYYY-MM-DD HH:00").format(format)
        ),
        y: data.map((item) => item.y),
        name: `studyParticipants.engagement.chart.${annotationType}.label`,
        type: "bar",
        marker: {
          color: getCardColor(
            getStatusByType(annotationType as TrialAnnotationStatus),
            theme
          ).main,
        },
      };
    };
    const chartData = (
      Object.values(TrialAnnotationStatus) as TrialAnnotationStatus[]
    )
      .filter((status) => status !== TrialAnnotationStatus.total)
      .map((status) => buildChartData(status));
    studiesBarChartLayout.xaxis = {
      ...studiesBarChartLayout.xaxis,
      range: [-0.5, 12],
      rangeslider: {
        thickness: 0.03,
        bgcolor: theme.palette.grey[100],
        bordercolor: theme.palette.grey[200],
        borderwidth: 1,
        autorange: true,
      },
    };
    setEngagementChartData(chartData);
  }, [
    participantsQueries.ParticipantsEngagementAnalytics.isFetched,
    durationUnit,
  ]);

  return (
    <>
      {!queries.trial.isFetched ? (
        <Loading />
      ) : (
        <Grid
          display="flex"
          flexDirection={"column"}
          gap={2}
          sx={containerStyle}
        >
          <Grid flexGrow={1}>
            <StudyHeader name={getTrialName} trialId={trialId} />
          </Grid>
          <Grid flexGrow={1}>
            <StudyTabs tabValue={1} trialId={trialId} />
            <StudyTabContent value={1} index={1}>
              <Grid display="flex" flexDirection={"column"} gap={2}>
                <Grid flexGrow={1}>
                  <Grid
                    container
                    display={"flex"}
                    pt={3}
                    gap={3}
                    sx={{ flexDirection: { md: "row", xs: "column" } }}
                  >
                    <StyledGrid p={1} sx={{ alignItems: "start" }}>
                      <Grid
                        container
                        display="flex"
                        flexDirection="column"
                        flexGrow={"1"}
                      >
                        <Grid flexGrow={"1"} justifyContent={"left"}>
                          <StudyGraphHeading
                            heading={t(
                              "studyParticipants.status.chart.heading"
                            )}
                          />
                        </Grid>
                        <Grid
                          flexGrow={"1"}
                          display={"flex"}
                          justifyContent={"center"}
                        >
                          {pieChartData.length ? (
                            <Typography
                              sx={{ width: { md: "20rem", sm: "auto" } }}
                              component="div"
                            >
                              <Chart
                                data={pieChartData}
                                layout={{
                                  ...pieChartLayout,
                                  font: { family: theme.typography.fontFamily },
                                  hoverlabel: {
                                    font: graphHoverFontStyle(theme),
                                  },
                                }}
                                config={pieChartconfig}
                                sx={{ margin: "0.3rem" }}
                              />
                            </Typography>
                          ) : (
                            <Loading sx={{ height: "20rem" }} />
                          )}
                        </Grid>
                      </Grid>
                    </StyledGrid>
                    <StyledGrid flexGrow={1} p={1} sx={{ alignItems: "start" }}>
                      <Grid
                        container
                        display="flex"
                        flexDirection="column"
                        flexGrow={1}
                      >
                        <Grid flexGrow={"1"}>
                          <Grid
                            container
                            alignItems={"center"}
                            justifyContent={"space-between"}
                          >
                            <Grid>
                              <StudyGraphHeading
                                heading={t(
                                  "studyParticipants.engagement.chart.heading.label"
                                )}
                              />
                            </Grid>
                            <Grid>
                              <Select
                                sx={{ height: "2rem", marginLeft: "0.5rem" }}
                                id="items-per-page"
                                value={durationUnit}
                                onChange={handleUnitsChange}
                              >
                                {Object.values(TIMEPERIOD).map((value) => (
                                  <MenuItem key={value} value={value}>
                                    {t(value)}
                                  </MenuItem>
                                ))}
                              </Select>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid flexGrow={"1"}>
                          <Typography component="div" sx={{ width: "100%" }}>
                            {engagementChartData.length ? (
                              <Chart
                                data={engagementChartData}
                                layout={{
                                  ...studiesBarChartLayout,
                                  font: { family: theme.typography.fontFamily },
                                }}
                                config={barChartconfig}
                                sx={{ margin: "0.3rem" }}
                              />
                            ) : (
                              <Loading sx={{ height: "20rem" }} />
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </StyledGrid>
                  </Grid>
                </Grid>
                <Grid flexGrow={1}>
                  <Grid container>
                    <Grid flexGrow={1}>
                      <StyledTitle>
                        {t("studyParticipants.participants.listing.heading")}
                      </StyledTitle>
                    </Grid>
                    <Grid>
                      <Button
                        onClick={handleClickOpen}
                        color={ButtonColor.primary}
                      >
                        {t(
                          "studyParticipants.participants.newParticipant.button.label"
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid flexGrow={1}>
                  <Grid container display={"flex"} sx={{ flexWrap: "nowrap" }}>
                    {!isMobile ? (
                      <Grid sx={{ flex: "1" }}>
                        <Typography>
                          {t(
                            "studyParticipants.participants.listing.subheading"
                          )}
                        </Typography>
                      </Grid>
                    ) : null}
                    <Grid sx={{ flex: "1" }}>
                      <Search
                        onSearch={onSubmit}
                        placeholder={
                          "studyParticipants.participantSearch.label.search"
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  flexGrow={1}
                  sx={{ fontFamily: theme.typography.fontFamily }}
                >
                  <ParticipantsList
                    queries={queries}
                    handleDeleteParticipant={handleDeleteParticipant}
                    showStatus={true}
                    handleSorting={handleSorting}
                  />
                </Grid>
                {openNewParticipant && queries.trial.isFetched ? (
                  <NewParticipant
                    isOpen={openNewParticipant}
                    onClose={handleClose}
                    onSubmit={createNewParticipant}
                  />
                ) : null}
              </Grid>
            </StudyTabContent>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default StudyParticipants;
