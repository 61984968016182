import { Box, Grid2 as Grid, Typography } from "@mui/material";

interface NotificationProps {
  message?: string;
  height?: string;
}
const NotFound: React.FC<NotificationProps> = ({ message, height }) => {
  return (
    <Grid size={{ xs: 12, md: 12, sm: 12 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: height ? height : "100%",
          flexDirection: "row",
        }}
      >
        <Box>
          <Typography variant="h6">{message}</Typography>
        </Box>
      </Box>
    </Grid>
  );
};

export default NotFound;
