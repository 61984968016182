import React from "react";
import { SvgIcon } from "@mui/material";
import { CSSProperties } from "styled-components";

interface IconProps {
  sx?: CSSProperties;
}

export const SignalIcon: React.FC<IconProps> = ({ sx }) => {
  return (
    <SvgIcon viewBox="0 0 100 100" sx={sx}>
      <rect width="100" height="100" rx="8" fill={sx?.backgroundColor} />
      <g clipPath="url(#clip0_1424_15998)">
        <path d="M49.4605 17.5C65.0821 17.7112 77.861 23.1963 88.3333 34.0344C89.7876 35.5393 89.8842 37.4403 88.6013 38.7208C87.2854 40.0365 85.3786 39.9045 83.8649 38.3357C77.1778 31.4118 69.2319 26.633 59.757 24.8002C43.1863 21.5945 28.784 25.9157 16.7212 37.8099C16.4598 38.0673 16.2137 38.3401 15.9457 38.5888C14.5266 39.9089 12.6461 39.9551 11.4312 38.7076C10.1856 37.4271 10.1395 35.5305 11.5806 34.206C14.2981 31.711 16.9826 29.139 19.9593 26.9828C27.0287 21.8674 35.0164 18.9411 43.6718 17.9026C45.7764 17.6496 47.9029 17.6034 49.4605 17.5Z" />
        <path d="M50.889 35.4082C60.6846 35.6216 69.685 39.5841 77.2004 47.0076C78.5624 48.3519 78.5997 50.266 77.3629 51.5113C76.091 52.794 74.2896 52.8006 72.8682 51.4233C68.5317 47.2188 63.5713 44.0615 57.6618 42.6534C46.3877 39.967 36.5723 42.9108 28.0574 50.6093C27.8135 50.8293 27.5873 51.0691 27.3456 51.2935C25.7793 52.7544 23.9779 52.8337 22.6752 51.5025C21.39 50.1868 21.5021 48.3013 22.9959 46.836C26.959 42.946 31.491 39.9186 36.6865 37.923C40.9967 36.2663 45.465 35.439 50.8912 35.4104L50.889 35.4082Z" />
        <path d="M49.8554 53.4358C55.7187 53.6602 60.7275 55.8604 64.9432 59.8845C66.2262 61.11 66.2635 63 65.1343 64.2321C63.9371 65.5368 62.1203 65.6468 60.7143 64.3905C58.9503 62.813 57.0456 61.4819 54.818 60.6656C49.3326 58.6546 44.4864 60.0055 40.0642 63.517C39.6358 63.8581 39.2557 64.2629 38.8186 64.5907C37.4346 65.6336 35.7123 65.4466 34.5831 64.1529C33.5176 62.9318 33.5264 61.1452 34.7303 59.9747C38.9438 55.8846 43.9833 53.6734 49.8554 53.4336V53.4358Z" />
        <path d="M49.9203 82.5009C47.0842 82.5273 44.7973 80.2985 44.7621 77.4757C44.727 74.6836 47.0117 72.3514 49.8126 72.3206C52.6399 72.2898 54.9334 74.5428 54.9488 77.3678C54.9664 80.2369 52.7739 82.4723 49.9203 82.4987V82.5009Z" />
      </g>
    </SvgIcon>
  );
};
