import React, { ReactNode } from "react";
import { Drawer, Grid } from "@mui/material";
import DrawerHeader from "./DrawerHeader";
import { ConfigurationService } from "services/configuration.service";
import { useTheme } from "@mui/material/styles";

interface CustomDrawerProps {
  open: boolean;
  onClose: (reason: CloseReason) => void;
  children: ReactNode;
  additionalProps?: Record<string, any>;
}

type CloseReason = "backdropClick" | "escapeKeyDown" | "closeButtonClick";

const CustomDrawer: React.FC<CustomDrawerProps> = ({
  open,
  children,
  onClose,
  additionalProps,
}) => {
  const theme = useTheme();
  const configurationData = ConfigurationService.configuration;
  const { titleText, headerWidth, ...otherProps } = additionalProps || {};
  return (
    <Drawer
      onClose={(_, reason) => onClose(reason)}
      {...otherProps}
      open={open}
      transitionDuration={configurationData.config.drawerTransitionDuration}
      PaperProps={{
        sx: {
          width: { md: 750, sm: 650, xs: "100%", xl: 850 },
          right: 0,
          overflowX: "hidden",
        },
      }}
      anchor="right"
      aria-hidden={!open}
    >
      <Grid
        container
        flexDirection="column"
        sx={{ fontFamily: theme.typography.fontFamily }}
      >
        <Grid item>
          {titleText && (
            <DrawerHeader
              title={titleText}
              headerWidth={headerWidth}
              onClose={() => onClose("closeButtonClick")}
            />
          )}
        </Grid>
        <Grid item>{children}</Grid>
      </Grid>
    </Drawer>
  );
};

export default CustomDrawer;
