import { Grid2 as Grid, Typography } from "@mui/material";
import { getAssetsPath } from "utils/assets.utils";
import {
  StyledDataCardBox,
  StyledDataCardResult,
  StyledDataCardResultType,
} from "./DataCard.styles";

interface DataCardProps {
  image: string;
  result: string;
  resultType: string;
}

const DataCard: React.FC<DataCardProps> = ({ image, result, resultType }) => {
  const StyledBox = StyledDataCardBox("div");

  return (
    <Grid size={{ xs: 12, md: 4, sm: 4 }}>
      <StyledBox>
        <Grid container>
          <Grid size={{ xs: 12, md: 12, sm: 12 }}>
            <Typography
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                src={getAssetsPath(image)}
                alt="TODO: add translations and descriptions"
              />
            </Typography>
          </Grid>
          <Grid size={{ xs: 12, md: 12, sm: 12 }}>
            <StyledDataCardResult>{result}</StyledDataCardResult>
            <StyledDataCardResultType>{resultType}</StyledDataCardResultType>
          </Grid>
        </Grid>
      </StyledBox>
    </Grid>
  );
};

export default DataCard;
