import React, { CSSProperties } from "react";
import { Grid2 as Grid, Typography, Box, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CardSize, CardStatus } from "components/Card";
import { Theme, useTheme } from "@mui/system";

import {
  SpinnerIcon,
  PendingIcon,
  SuccessIcon,
  FailIcon,
  WarningIcon,
} from "components/icons";
import useGuideStore from "components/TroubleShootGuide/guide.store";

interface RuleProps {
  label: string;
  color: string;
  status: CardStatus;
  sx?: CSSProperties;
  guideMetaData?: { show: boolean; contextTag: string[] };
}

const MetricComponent: React.FC<RuleProps> = ({
  label,
  color,
  status,
  sx,
  guideMetaData = { show: false, contextTag: [] },
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { setContextTag, setOpen: setGuideOpen } = useGuideStore();

  const openHelp = () => {
    if (!guideMetaData.show) return;
    if (guideMetaData.contextTag.length)
      setContextTag(guideMetaData.contextTag);
    setGuideOpen(true);
  };

  const getIcon = (
    status: CardStatus,
    theme: Theme,
    color: string,
    size: CardSize = CardSize.large
  ) => {
    switch (status) {
      case CardStatus.PENDING:
        return <PendingIcon sx={{ color: color, fontSize: "3rem" }} />;
      case CardStatus.IN_PROGRESS:
        return (
          <SpinnerIcon
            sx={{
              baseColor: color,
              fontSize: "3rem",
              animation: "spin 2s infinite linear",
            }}
          />
        );
      case CardStatus.SUCCESS:
        return <SuccessIcon sx={{ fill: color, fontSize: "3rem" }} />;
      case CardStatus.FAIL:
        return <FailIcon sx={{ fill: color, fontSize: "3rem" }} />;
      case CardStatus.WARNING:
        return <WarningIcon sx={{ fill: color, fontSize: "3rem" }} />;
      default:
        return <PendingIcon sx={{ color: color, fontSize: "3rem" }} />;
    }
  };

  return (
    <Grid key={label}>
      <Box display="flex" alignItems="center" sx={{ ...sx }}>
        {getIcon(status, theme, color)}
        <Typography
          component="div"
          sx={{
            fontWeight: "600",
            color: color,
            fontSize: "1.1rem",
            m: 2,
            ...sx,
          }}
        >
          {guideMetaData.show ? (
            <Link
              sx={{
                color: color,
                cursor: "pointer",
                textDecoration: "underline",
              }}
              onClick={openHelp}
            >
              {t(label)}
            </Link>
          ) : (
            t(label)
          )}
        </Typography>
      </Box>
    </Grid>
  );
};

export default MetricComponent;
