import { Typography, CircularProgress, Grid2 as Grid } from "@mui/material";

interface StatsCardProps {
  imgSrc: string;
  statsData: string | number | undefined;
  statsLabel: string;
}

const StatsCard: React.FC<StatsCardProps> = ({
  imgSrc,
  statsData,
  statsLabel,
}) => {
  return (
    <Grid container display="flex" alignItems="center" gap={2}>
      <Grid display="flex" alignItems="center" gap={2}>
        <img src={imgSrc} alt="" />
      </Grid>
      <Grid display="flex" alignItems="center" gap={2}>
        <Grid
          container
          display="flex"
          alignItems="center"
          flexDirection="column"
          flexWrap="wrap"
        >
          <Grid display="flex" alignItems="center" justifyContent="center">
            {statsData !== undefined ? (
              <Typography>{statsData}</Typography>
            ) : (
              <CircularProgress size={20} />
            )}
          </Grid>
          <Grid display="flex" alignItems="center">
            <Typography sx={{ width: "5rem", textAlign: "center" }}>
              {statsLabel}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default StatsCard;
