import * as React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { SortOrder } from "constants/index";
import { ParticipantsSortingState } from "services/cde.service";
import Table from "components/Table";

import ParticipantSampleCount from "./ParticipantSampleCount.component";
import { buildLocalUrl } from "utils/url.utils";
import { ROUTES } from "constants/routes.constants";
import { getAssetsPath } from "utils/assets.utils";
import moment from "moment";
import Button, { ButtonColor } from "components/Button";
import IconButton from "components/IconButton";
import EditIcon from "components/icons/EditIcon";
import ParticipantDetails from "./ParticipantDetails.component";
import CustomPopover from "features/trials/components/createTrial/CustomPopover";
import { useIsMobile } from "hooks/media.hook";

interface ParticipantsActionsHandlerProps {
  queries: Record<string, any>;
  handleSorting: (order: string) => void;
  handleDeleteParticipant: (participant: any) => void;
  sortable: any;
  showStatus?: boolean | false;
}
const ParticipantsListActionsHandler: React.FC<
  ParticipantsActionsHandlerProps
> = ({
  queries,
  handleSorting,
  handleDeleteParticipant,
  sortable,
  showStatus,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [openParticipantDetails, setOpenParticipantDetails] = useState(false);
  const [selectedParticipant, setSelectedParticipant] = useState<any | null>(
    null
  );

  const isMobile = useIsMobile();
  const participants = queries.participants?.data?.data;
  const trial = queries.trial?.data;
  if (!trial) return;

  const minExperiments = queries.trial?.data?.configuration.data.options
    .participants
    ? queries.trial?.data?.configuration.data.options.participants
        .minExperiments
    : 5;

  const handleNavigation = (path: string, params: Record<string, string>) => {
    const url = buildLocalUrl({ path, params });
    navigate(url, { replace: true });
  };

  const handleclick = (participant: any) => {
    handleNavigation(ROUTES.TRIAL_PARTICIPANT_DEVICES, {
      trialId: trial.uuid,
      trialMemberId: participant.uuid,
    });
  };

  const handleSortToggle = (column: keyof ParticipantsSortingState) => {
    sortable[column] =
      sortable[column] === SortOrder.DESC ? SortOrder.ASC : SortOrder.DESC;
    handleSorting(`${column}|${sortable[column]}`);
  };

  const openPopover = (
    event: React.MouseEvent<HTMLElement>,
    participant: any
  ) => {
    participant.analytics = participant.annotations ?? {};
    setSelectedParticipant(participant);
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleStudyParticipantClickOpen = async (
    trialId: string,
    pui: string
  ) => {
    handleNavigation(ROUTES.PARTICIPANT_DASHBOARD, {
      trialId: trialId,
      trialMemberId: pui,
    });
  };

  const handleParticipantDetailsClose = () => {
    setOpenParticipantDetails(false);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenParticipantDetails(false);
  };

  const handleParticipantDetailsOpen = () => {
    setOpenParticipantDetails(true);
  };

  const columns = [
    {
      key: "pui",
      label: t("trialParticipant.participants.list.participant"),
      width: { md: 3, xs: 6, sm: 3 },
      sx: {
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
      },
      sortable: {
        onColumnClick: handleSortToggle,
        defaultDirection: sortable.pui,
      },
    },
    {
      key: "createdAt",
      label: t("trialParticipant.participants.list.registrationDate"),
      width: { md: 2, xs: 6, sm: 2 },
      sx: { display: "flex", justifyContent: "center", cursor: "pointer" },
      sortable: {
        onColumnClick: handleSortToggle,
        defaultDirection: sortable.createdAt,
      },
    },
    {
      key: "sampleCount",
      label: t("trialParticipant.participants.list.sample"),
      width: { md: 3, xs: 6, sm: 2 },
      sx: { display: "flex", justifyContent: "center" },
    },
    {
      key: "action",
      label: t("trialParticipant.participants.list.action"),
      width: { md: 2, xs: 6, sm: 3 },
      sx: { display: "flex", justifyContent: "center" },
    },
    {
      key: "more",
      label: t("trialParticipant.participants.list.more"),
      width: { md: 2, xs: 6, sm: 2 },
      sx: {
        display: "flex",
        justifyContent: "end",
      },
    },
  ];

  const data = participants.map((participant: any) => {
    const inUse = participant.annotations
      ? participant.annotations.inProgress
        ? true
        : false
      : false;

    return {
      pui: participant.pui,
      createdAt: moment.utc(participant?.createdAt).format("DD MMMM YYYY"),
      sampleCount: (
        <ParticipantSampleCount
          label={t(
            "trialParticipant.participantSearch.analyticsData.completed.label"
          )}
          isMobile={isMobile}
          tooltipText={t("trialParticipant.samplecaptured.tooltip").replace(
            "{{minExperiments}}",
            minExperiments
          )}
          minExperiments={minExperiments}
          count={
            participant.annotations ? participant.annotations.complete : null
          }
        />
      ),
      action: (
        <Button
          onClick={() => handleclick(participant)}
          disabled={inUse}
          color={ButtonColor.primary}
        >
          {inUse
            ? t("trialParticipant.participants.list.status.takingSamples.label")
            : t("trialParticipant.participants.list.button.select")}
        </Button>
      ),
      more: (
        <>
          {showStatus && (
            <img
              style={{ cursor: "pointer" }}
              src={getAssetsPath("icons/study_button.svg")}
              alt={participant.pui}
              onClick={() =>
                handleStudyParticipantClickOpen(trial.uuid, participant.uuid)
              }
            />
          )}
          <IconButton
            onClick={(event: any) => openPopover(event, participant)}
            sx={{ cursor: "pointer" }}
          >
            <EditIcon />
          </IconButton>
        </>
      ),
    };
  });

  return (
    <>
      <Table columns={columns} data={data} hideHeaderOnMobile={true} />
      {openParticipantDetails && (
        <ParticipantDetails
          isOpen={openParticipantDetails}
          participant={selectedParticipant}
          onClose={handleParticipantDetailsClose}
          trial={trial}
        />
      )}
      <CustomPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        participant={selectedParticipant}
        handleParticipantDetailsOpen={handleParticipantDetailsOpen}
        handleDeleteParticipant={() =>
          handleDeleteParticipant(selectedParticipant)
        }
      />
    </>
  );
};

export default ParticipantsListActionsHandler;
