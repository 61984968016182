import React from "react";
import { Grid2 as Grid, Divider } from "@mui/material";

import { useHeaderStore } from "stores/header.store";
import ProgressBar from "components/ProgressBar";
import Breadcrumb from "components/Breadcrumb";
import HeadingTitle from "components/HeadingTitle";
import { ProgressBarStyle } from "constants/index";
import { SignalIndicator } from "components/SignalIndicator/SignalIndicator.component";
import useTrialStore from "stores/trial.store";

const Header: React.FC = () => {
  const mainTitle = useHeaderStore((state) => state.mainTitle);
  const subTitleItems = useHeaderStore((state) => state.subTitleItems);
  const signalIndicator = useHeaderStore((state) => state.signalIndicator);
  const { progressBarType } = useHeaderStore((state) => state);
  const showDivider = useHeaderStore((state) => state.showDivider);
  const hasViewMertricsPermission = useTrialStore((state) =>
    state.hasViewMertricsPermission()
  );
  
  return (
    <Grid
      container
      flexDirection="row"
      alignItems={
        progressBarType === ProgressBarStyle.BREADCRUMB ? "start" : "center"
      }
      justifyContent="flex-end"
      sx={{ marginTop: "2rem" }}
    >
      <Grid flexGrow={1}>
        <HeadingTitle mainTitle={mainTitle} subTitleItems={subTitleItems} />
      </Grid>
      <Grid>
        <Grid container flexDirection="column">
          <Grid>
            {progressBarType === ProgressBarStyle.BREADCRUMB ? (
              <Breadcrumb />
            ) : (
              <ProgressBar />
            )}
          </Grid>
          {signalIndicator?.heading &&
            hasViewMertricsPermission && (
              <Grid
                display={"flex"}
                justifyContent={signalIndicator.heading.validate.orientation}
                sx={{ marginTop: "3rem" }}
              >
                <SignalIndicator indicators={signalIndicator} />
              </Grid>
            )}
        </Grid>
      </Grid>
      {showDivider && (
        <Divider
          sx={{ width: "100%", marginTop: "1.5rem", marginBottom: "2rem" }}
        />
      )}
    </Grid>
  );
};

export default Header;
