import { Field, ErrorMessage, FieldProps, FormikValues } from "formik";
import { DateTimePicker as MuiDateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import TextError from "./TextError";
import { FormControl } from "@mui/material";
import TextLabel from "../TextLabel";
import dayjs from "dayjs";

interface DatePickerProps {
  label: string;
  name: string;
}

const DateTimePicker = (props: DatePickerProps) => {
  const { label, name } = props;

  return (
    <FormControl sx={{ width: "100%" }}>
      <TextLabel label={label} />
      <Field name={name}>
        {({ form, field }: FieldProps<FormikValues>) => {
          const { setFieldValue } = form;
          const { value } = field;

          return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MuiDateTimePicker
                sx={{ width: "100%" }}
                slotProps={{ popper: { sx: { zIndex: 10000 } } }}
                onChange={(value) => setFieldValue(name, value, true)}
                value={value as dayjs.Dayjs}
              />
            </LocalizationProvider>
          );
        }}
      </Field>
      <ErrorMessage
        name={name}
        render={(msg: string) => <TextError>{msg}</TextError>}
        className="error"
      />
    </FormControl>
  );
};

export default DateTimePicker;
