import React, { ReactNode } from "react";
import { Button as MuiButton } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { Theme, styled, useTheme } from "@mui/system";

type ChangeEvent = React.MouseEvent<HTMLButtonElement>;

export enum ButtonColor {
  "primary" = "primary",
  "secondary" = "secondary",
  "success" = "success",
  "error" = "error",
  "info" = "info",
  "warning" = "warning",
  "progress" = "progress",
}
interface ButtonProps {
  type?: "button" | "submit" | "reset" | undefined;
  disabled?: boolean;
  color?: ButtonColor | undefined;
  onClick?: (event: ChangeEvent) => {} | void;
  sx?:
    | { [key: string]: string | number }
    | { [key: string]: { [key: string]: string } };
  size?: "small" | "large" | "medium" | undefined;
  isLoading?: boolean;
  children?: ReactNode;
  startIcon?: ReactNode;
}

const getBackground = (color: ButtonColor | undefined, theme: Theme) => {
  switch (color) {
    case ButtonColor.primary:
      return theme.palette.primary.main;
    case ButtonColor.secondary:
      return theme.palette.common.white;
    case ButtonColor.success:
      return theme.palette.primary.success;
    case ButtonColor.error:
      return theme.palette.primary.error;
    case ButtonColor.info:
      return theme.palette.primary.info;
    case ButtonColor.warning:
      return theme.palette.primary.warning;
    case ButtonColor.progress:
      return theme.palette.primary.progress;
    default:
      return theme.palette.primary.primary;
  }
};

const getColor = (color: ButtonColor | undefined, theme: Theme) => {
  switch (color) {
    case ButtonColor.primary:
      return theme.palette.text.primary;
    case ButtonColor.secondary:
      return theme.palette.primary.main;
    case ButtonColor.success:
      return theme.palette.success.main;
    case ButtonColor.error:
      return theme.palette.error.main;
    case ButtonColor.info:
      return theme.palette.info.main;
    case ButtonColor.warning:
      return theme.palette.warning.main;
    case ButtonColor.progress:
      return theme.palette.progress.main;
    default:
      return theme.palette.primary.main;
  }
};

const getContrastColor = (color: ButtonColor | undefined, theme: Theme) => {
  switch (color) {
    case ButtonColor.primary:
      return theme.palette.primary.main;
    case ButtonColor.secondary:
      return theme.palette.primary.main;
    case ButtonColor.success:
      return theme.palette.success.main;
    case ButtonColor.error:
      return theme.palette.error.main;
    case ButtonColor.info:
      return theme.palette.info.main;
    case ButtonColor.warning:
      return theme.palette.warning.main;
    default:
      return theme.palette.primary.main;
  }
};

const StyledButton = styled(MuiButton)<{ stylecolor?: ButtonColor }>(
  ({ theme, stylecolor }) => ({
    backgroundColor: getBackground(stylecolor, theme),
    minWidth: "10rem",
    borderRadius: "6rem",
    whiteSpace: "nowrap",
    transition: "0.1s",
    lineHeight: "1",
    color: getColor(stylecolor, theme),
    border: `2px solid ${getContrastColor(stylecolor, theme)}`,
    zIndex: "2",
    padding: theme.spacing(2),
  })
);

const Button: React.FC<ButtonProps> = ({
  type,
  disabled,
  color,
  onClick,
  sx,
  size,
  isLoading,
  children,
  startIcon,
}) => {
  const theme = useTheme();
  const mainColor = getColor(color ? color : ButtonColor.primary, theme);

  return (
    <StyledButton
      type={type ? type : "button"}
      stylecolor={color}
      disabled={disabled || isLoading}
      onClick={onClick}
      size={size}
      startIcon={startIcon}
      sx={{
        ...sx,
        textTransform: "none",
      }}
    >
      {isLoading && (
        <CircularProgress
          size={15}
          sx={{ marginRight: "0.5rem", color: mainColor }}
        />
      )}
      {children}
    </StyledButton>
  );
};

export default Button;
