import { DURATIONUNIT, TIMEPERIOD } from "hooks/useStudiesDashboardApi.hook";
import moment from "moment";

const getTitle = (title: string, fontSize: string = "1.4rem") => {
  return `<span style='font-size:${fontSize};'>${title}</span>`;
};

const dateCalculations = (timeperiod: TIMEPERIOD) => {
  const now = moment();

  const result = {
    from: undefined as string | undefined,
    to: undefined as string | undefined,
    durationUnit: DURATIONUNIT.MONTHS,
  };

  switch (timeperiod) {
    case TIMEPERIOD.LASTFIFTEENDAYS:
      result.from = now
        .clone()
        .subtract(15, "days")
        .startOf("day")
        .format("YYYY-MM-DD");
      result.to = now.clone().add(1, "days").endOf("day").format("YYYY-MM-DD");
      result.durationUnit = DURATIONUNIT.HOURS;
      break;

    case TIMEPERIOD.CURRENTMONTH:
      result.from = now.clone().startOf("month").format("YYYY-MM-DD");
      result.to = now.clone().add(1, "days").endOf("day").format("YYYY-MM-DD");
      result.durationUnit = DURATIONUNIT.DAYS;
      break;

    case TIMEPERIOD.LASTTHREEMONTHS:
      result.from = now
        .clone()
        .subtract(3, "months")
        .startOf("month")
        .format("YYYY-MM-DD");
      result.to = now.clone().add(1, "days").endOf("day").format("YYYY-MM-DD");
      result.durationUnit = DURATIONUNIT.DAYS;
      break;
  }
  return result;
};

export { getTitle, dateCalculations };
