import { Grid2 as Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DrawerHeader from "components/DrawerHeader";
import { FieldConfig } from "utils/form.utils";
import { UpdateTrialData } from "services/cde.service";

import FormContainer from "components/FormContainer";
import CustomDrawer from "components/CustomDrawer";

import TrialDevice from "./TrialDevice.component";
import useTrialDetailsApi from "./useTrialDetailsApi.hook";
import Loading from "components/Loading";
import useDevicesApi from "hooks/useDevicesApi.hook";
import { DeviceStatusEnum } from "constants/index";

interface TrialDetailsProps {
  isOpen: boolean;
  trialId: any;
  onClose: () => void;
}

const TrialDetails: React.FC<TrialDetailsProps> = ({
  isOpen,
  onClose,
  trialId,
}) => {
  const { t } = useTranslation();
  const [trialDevices, setTrialDevices] = useState<string[]>([]);
  const [updating, setUpdating] = useState(false);
  const queries = useTrialDetailsApi({ trialId });
  const devicesQueries = useDevicesApi({ serialNumbers: [] });

  useEffect(() => {
    if (queries.trialQuery.isFetched) {
      if (queries.trialQuery.data?.serialNumbers) {
        setTrialDevices(queries.trialQuery.data?.serialNumbers);
      }
    }
  }, [queries.trialQuery.isFetched]);

  useEffect(() => {
    devicesQueries.setOptions({ serialNumbers: trialDevices });
  }, [trialDevices]);

  const schemaData: FieldConfig[] =
    queries.trialQuery.data?.configuration?.data.forms.trialEdit.fields;

  const onSubmit = async (values: UpdateTrialData, { setSubmitting }: any) => {
    setUpdating(true);
    const { name, description } = values;
    const data = {
      name,
      description,
      trialId: queries.trialQuery.data?.uuid,
    };
    await queries.updateTrialDetails.mutateAsync(data);
    setUpdating(false);
  };

  const initialValues: { [key: string]: string } = {};
  schemaData &&
    schemaData.forEach((field) => {
      if (queries.trialQuery.data && field.name in queries.trialQuery.data) {
        initialValues[field.name] = (queries.trialQuery.data as any)[
          field.name
        ];
      } else {
        initialValues[field.name] = "";
      }
    });

  return (
    <CustomDrawer open={isOpen} onClose={onClose}>
      <DrawerHeader title={t("trial_details")} onClose={onClose} />
      <Grid container flexDirection="column" sx={{ padding: "0 1.5rem" }}>
        <Grid flexGrow={1}>
          {!queries.trialQuery.isFetched && <Loading height="10rem" />}
          {queries.trialQuery.isFetched && (
            <FormContainer
              schemaData={schemaData}
              initialValues={initialValues}
              onSubmit={onSubmit}
              loading={updating}
              labels={{ preSubmit: "update", postSubmit: "updating" }}
            />
          )}
        </Grid>
        <Grid flexGrow={1}>
          <Typography
            sx={{ fontSize: "1.2rem", fontWeight: "600", marginTop: "2.5rem" }}
          >
            {t("rightDrawer.trialDetails.devices.title")}
          </Typography>
        </Grid>
        <Grid flexGrow={1}>
          {!devicesQueries.devices.isFetched && <Loading height="50%" />}
          {trialDevices &&
            trialDevices.map((serialNumber: string) => {
              const status =
                devicesQueries.devices?.data?.find(
                  (device) => device.serialNumber === serialNumber
                )?.status || DeviceStatusEnum.OFFLINE;

              return (
                <TrialDevice
                  key={serialNumber}
                  serialNumber={serialNumber}
                  status={status}
                />
              );
            })}
        </Grid>
      </Grid>
    </CustomDrawer>
  );
};

export default TrialDetails;
