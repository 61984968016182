import { Grid2 as Grid } from "@mui/material";
import { styled } from "@mui/system";

export const GridStyle = styled(Grid)(({ theme }) => ({
  top: "0",
  position: "absolute",
  right: 0,
  bottom: 0,
  alignItems: "center",
  width: "50%",
}));

export const StyleTypography = styled(Grid)(({ theme }) => ({
  fontSize: "2rem",
  lineHeight: "2rem",
  textAlign: "center",
  height: "4.5rem",
  overflow: "hidden",
  alignContent: "center",
}));
