import React from "react";

import Grid from "@mui/material/Grid2";

import {
  Card,
  CardStatus,
  CardBackgrounds,
  CardSize,
  getCardColor,
} from "components/Card";

import { AnnotationEvent } from "services/annotation.service";
import { useTheme } from "@mui/system";
import { useExperimentStore } from "stores/experiment.store";

interface ProgressProps {
  event: AnnotationEvent;
}

export const Progress: React.FC<ProgressProps> = ({ event }) => {
  const annotationEvents = useExperimentStore(
    (state) => state.annotationEvents
  );
  const validation = useExperimentStore((state) => state.validation);
  const failedStep = useExperimentStore((state) => state.failedStep);

  const theme = useTheme();
  if (!event) return null;
  if (!event.progress) return null;
  if (!validation) return null;

  const getBorderColor = (status: CardStatus) => {
    switch (status) {
      case CardStatus.IN_PROGRESS:
        return theme.palette.success.main;
      case CardStatus.FAIL:
        return theme.palette.error.main;
      case CardStatus.WARNING:
        return theme.palette.warning.main;
      default:
        return theme.palette.grey[500];
    }
  };

  return event?.progress.cards.map((card) => {
    const name = card.name?.toLowerCase();
    const eventstatus = annotationEvents.find(
      (item) => item.name.toLowerCase() === name
    );

    const status =
      failedStep?.toLowerCase() === name
        ? CardStatus.FAIL
        : ((eventstatus && !validation.verdict
            ? eventstatus.status
            : card.status) as CardStatus);

    const cardColor = getCardColor(status, theme);

    return card.show ? (
      <Grid key={card.label}>
        <Card
          key={card.label}
          status={status}
          background={card.options.background as CardBackgrounds}
          label={card.label}
          showIcon={card.options.showIcon}
          step={{
            show: status === CardStatus.PENDING,
            label: card?.stepNumber,
            color: theme.palette.white.light,
            background: theme.palette.grey[600],
          }}
          size={CardSize.fixed}
          labelColor={
            status === CardStatus.IN_PROGRESS
              ? theme.palette.success.main
              : undefined
          }
          backgroundIconColor={
            [CardStatus.WARNING, CardStatus.FAIL].includes(status as CardStatus)
              ? cardColor.main
              : getBorderColor(status)
          }
          sx={{
            borderColor: getBorderColor(status),
            border: [
              CardStatus.IN_PROGRESS,
              CardStatus.WARNING,
              CardStatus.FAIL,
            ].includes(status as CardStatus)
              ? "1px solid"
              : "none",
          }}
        ></Card>
      </Grid>
    ) : null;
  });
};
