import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { AuthService } from "services/auth.service";

export const baseURL = process.env.REACT_APP_API_ENDPOINT;
const REQUEST_TIMEOUT = 60000;

const privateClient = axios.create({
  baseURL,
  timeout: REQUEST_TIMEOUT,
  headers: {
    "Content-Type": "application/json",
  },
});

privateClient.interceptors.request.use(async (config) => {
  config.headers["Authorization"] = `Bearer ${AuthService.getToken()}`;
  return config;
});

privateClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status !== 401)
      return Promise.reject(error);
    if (error.response && error.response.status === 401)
      await AuthService.logout();
    try {
      const refreshedToken = await AuthService.updateToken();
      if (!refreshedToken) return Promise.reject(error);

      privateClient.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${refreshedToken}`;
      error.config.headers["Authorization"] = `Bearer ${refreshedToken}`;
      const result = await privateClient(error.config);
      if (result.status === 401) {
        throw Error("Still unauthorized after token refresh");
      }
      return Promise.resolve(result);
    } catch (refreshError) {
      console.error("Refresh token error", refreshError);
      window.location.reload();
    }
  }
);

export class CustomError extends Error {
  code: string;
  status: number;
  errorLocalId: string;
  customData: any;

  constructor(
    message: string,
    code: string,
    status?: number,
    errorLocalId?: string,
    customData?: any
  ) {
    super(message);
    this.name = this.constructor.name;
    this.code = code;
    this.status = status || 500;
    this.errorLocalId = errorLocalId || "";
    this.customData = customData;
    Error.captureStackTrace(this, this.constructor);
  }
}

export const handleResponse = async (response: AxiosResponse) => {
  if (response && Number(response.status) < 300) return response.data;

  const errorResponse = await response.data;
  const errorMessage = errorResponse.errorLocalId
    ? errorResponse.errorLocalId
    : errorResponse.message
    ? errorResponse.message
    : errorResponse.data.message
    ? errorResponse.data.message
    : errorResponse.data.data.message
    ? "An error occurred while fetching the configuration data"
    : "An unknown error occurred";

  const error = new CustomError(
    errorMessage,
    errorResponse.code,
    errorResponse.status,
    errorResponse.errorLocalId,
    {
      raw: response,
      ...errorResponse.data,
    }
  );
  throw error;
};

export const get = async (
  url: string,
  headers?: Record<string, string>
): Promise<any> => {
  try {
    const options: AxiosRequestConfig = {
      headers,
    };
    const response = await privateClient.get(url, options);
    return handleResponse(response);
  } catch (error: any) {
    const errorMessage = await handleResponse(error.response);
    throw new Error(errorMessage);
  }
};

export const post = async (
  url: string,
  payload: any,
  headers?: Record<string, string>
): Promise<any> => {
  try {
    const options: AxiosRequestConfig = {
      headers,
    };

    const response = await privateClient.post(url, payload, options);
    return handleResponse(response);
  } catch (error: any) {
    const errorMessage = await handleResponse(error.response);
    throw new Error(errorMessage);
  }
};

export const put = async (
  url: string,
  payload: any,
  headers?: Record<string, string>
): Promise<any> => {
  try {
    const options: AxiosRequestConfig = {
      headers,
    };

    const response = await privateClient.put(url, payload, options);
    return handleResponse(response);
  } catch (error: any) {
    const errorMessage = await handleResponse(error.response);
    throw new Error(errorMessage);
  }
};

export const patch = async (
  url: string,
  payload: any,
  headers?: Record<string, string>
): Promise<any> => {
  try {
    const options: AxiosRequestConfig = {
      headers,
    };

    const response = await privateClient.patch(url, payload, options);
    return handleResponse(response);
  } catch (error: any) {
    const errorMessage = await handleResponse(error.response);
    throw new Error(errorMessage);
  }
};

export const del = async (
  url: string,
  headers?: Record<string, string>
): Promise<any> => {
  try {
    const options: AxiosRequestConfig = {
      headers,
    };

    const response = await privateClient.delete(url, options);
    return handleResponse(response);
  } catch (error: any) {
    const errorMessage = await handleResponse(error.response);
    throw new Error(errorMessage);
  }
};
