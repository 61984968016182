import { Grid2 as Grid, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Formik, Form } from "formik";
import FormikControl from "components/form/FormikControl";
import { useTranslation } from "react-i18next";
import Button from "components/Button";

interface SearchProps {
  onSearch: (values: any) => void;
}

const Search: React.FC<SearchProps> = ({ onSearch }) => {
  const { t } = useTranslation();

  const onSubmit = (values: any) => {
    onSearch(values);
  };

  const initialValues = {
    status: [],
    name: "",
  };

  return (
    <Grid>
      <Formik key="search" initialValues={initialValues} onSubmit={onSubmit}>
        {(formik) => (
          <Form>
            <Grid container flexDirection="row" spacing={2}>
              <FormikControl control="input" name="status" type="hidden" />
              <Grid flexGrow={1}>
                <FormikControl
                  control="input"
                  fullWidth
                  name="name"
                  placeholder={t(
                    "trialsManagement.searchForm.field.input.placeholder"
                  )}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid>
                <Button type="submit">
                  {t("trialsManagement.searchForm.button.submit.label")}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Grid>
  );
};

export default Search;
