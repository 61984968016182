import { Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  StyledParticipantDetailTrialWrapper,
  StyledTrialDevices,
  StyledTrialDeviceStatus,
} from "features/trials/components.styles";
import { DeviceStatusEnum } from "constants/index";

interface TrialDeviceProps {
  serialNumber: string;
  status: DeviceStatusEnum;
}

const TrialDevice: React.FC<TrialDeviceProps> = ({ serialNumber, status }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <StyledParticipantDetailTrialWrapper
      container
      sx={{
        margin: "1rem 0",
        fontFamily: theme.typography.fontFamily,
        height: {
          md: "6rem",
          sx: "8rem",
          sm: "6rem",
        },
      }}
    >
      <Grid container display="flex" alignItems={"center"}>
        <Grid item md={9} sm={9} xs={8}>
          <Typography sx={{ marginLeft: "3rem" }}>
            {t("Serial Number")}
          </Typography>
          <Typography sx={{ marginLeft: "3rem" }}>{serialNumber}</Typography>
        </Grid>
        <Grid item md={3} sm={3} xs={4}>
          <StyledTrialDevices component={"div"}>
            <StyledTrialDeviceStatus component={"div"}>
              <Typography
                component={"div"}
                sx={{
                  fontSize: "inherit",
                  color:
                    DeviceStatusEnum.ERROR === status
                      ? theme?.devicePalette?.errorText
                      : theme.palette.text.primary,
                }}
              >
                {status}
              </Typography>
            </StyledTrialDeviceStatus>
          </StyledTrialDevices>
        </Grid>
      </Grid>
    </StyledParticipantDetailTrialWrapper>
  );
};

export default TrialDevice;
