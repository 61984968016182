import React from "react";

import { Grid2 as Grid } from "@mui/material";

import {
  AnnotationEvent,
  CardConfiguration,
} from "services/annotation.service";

import { Card, CardStatus, CardSize, CardBackgrounds } from "components/Card";

import DownloadRawDataCard from "./DownloadRawDataCard.component";
import ChartChemicalSensorResponse from "./ChartChemicalSensorResponse.component";
import FullWidthCardContainer from "components/FullWidthCardContainer.component";

interface EventCardsProps {
  event: AnnotationEvent;
  options?: any;
}

const getCardComponent = (card: CardConfiguration, options: any) => {
  switch (card.type) {
    case "downloadRawData": {
      const { ingest } = options;
      if (!ingest) return null;
      const storageId =
        ingest?.result?.CSV_FILE_PATH || ingest?.result?.JSON_FILE_PATH;
      return (
        <Grid key={card.label} flexGrow={0.25} flexBasis={0}>
          <DownloadRawDataCard
            storageId={storageId}
            status={card.status as CardStatus}
            label={card.label}
            actionLabel={card.options.actionLabel}
            background={card.background as CardBackgrounds}
            size={card.size ? card.size : CardSize.medium}
          />
        </Grid>
      );
    }
    case "chemicalResponse": {
      const { ingest } = options;
      if (!ingest) return null;
      const data = ingest?.result?.TELEMETRY_PLOT;
      if (!data) return null;

      const downloadButtonComponent =
        card.button && options.ingest ? (
          <Grid key={card.label} flexGrow={0.25} flexBasis={0}>
            <DownloadRawDataCard
              storageId={
                options.ingest.result?.CSV_FILE_PATH ||
                options.ingest.result?.JSON_FILE_PATH
              }
              status={card.status as CardStatus}
              label={card.button.label}
              actionLabel={card.button.options.actionLabel}
              background={card.button.background as CardBackgrounds}
              size={card.button.size ? card.button.size : CardSize.medium}
            />
          </Grid>
        ) : null;

      return (
        <Grid key={card.label} flexGrow={0.75} flexBasis={"100%"}>
          <ChartChemicalSensorResponse
            status={card.status as CardStatus}
            size={card.size ? card.size : CardSize.medium}
            label={card.label}
            options={card}
            data={data}
            buttonComponent={downloadButtonComponent}
          />
        </Grid>
      );
    }
    default: {
      return (
        <FullWidthCardContainer key="default">
          <Grid key={card.label} flexGrow={0.25} flexBasis={0}>
            <Card
              status={card.status as CardStatus}
              background={card.background as CardBackgrounds}
              size={card.size ? card.size : CardSize.medium}
              label={card.label}
              showIcon={card.showIcon}
            ></Card>
          </Grid>
        </FullWidthCardContainer>
      );
    }
  }
};

export const EventCards: React.FC<EventCardsProps> = ({ event, options }) => {
  if (!event) return null;
  if (!event.progress.cards) return null;
  return (
    <Grid flexGrow={1}>
      <Grid
        container
        flexDirection="row"
        justifyContent="center"
        flexWrap="wrap"
      >
        {event.progress.cards.map((card) => getCardComponent(card, options))}
      </Grid>
    </Grid>
  );
};
