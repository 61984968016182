import { useEffect, useState } from "react";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import moment from "moment";

import { Grid2 as Grid } from "@mui/material";

import {
  useHeaderStore,
  InitHeaderOptions,
  InitProgressBarOptions,
} from "stores/header.store";
import useTrialApi from "hooks/useTrialApi.hook";
import { ROUTES } from "constants/routes.constants";
import { AnnotationStatus } from "services/annotation.service";

import { ProgressBarStatus } from "stores/header.store";
import { CardStatus } from "components/Card";
import { containerStyle, TroubleshootGuideTypes } from "constants/index";

import { TimerController } from "./Components/Timer.controller";

import { usePurgeStore, getNextEvent } from "./purge.store";
import { useLeftNavigationStore } from "stores/navigation.store";
import {
  rpcType,
  CreateRpcEventParams,
  rpcEventCommandType,
} from "services/fleet.service";
import useDevicesApi from "hooks/useDevicesApi.hook";
import { Metrics } from "./Components/MetricsContainer.controller";
import TimerButtonContainer from "./Components/TimerButtonContainer.controller";
import InformationBlock from "components/InformationBlock";
import useGuideStore from "components/TroubleShootGuide/guide.store";
import useDeviceApi from "hooks/useDeviceApi.hook";
import useTrialStore from "stores/trial.store";

const PurgeDevice = () => {
  const navigate = useNavigate();
  const { serialNumber, trialId, trialMemberId } = useParams();
  const [searchParams] = useSearchParams();
  const referrerUrl = searchParams.get("referrerUrl");
  const setTrialData = useTrialStore((state) => state.setTrialData);
  const getTrialConfiguration = useTrialStore((state) =>
    state.getTrialConfiguration()
  );
  const trialQueries = useTrialApi({ trialId, participants: false });
  const devicesQueries = useDevicesApi({
    serialNumbers: [],
  });
  const deviceQueries = useDeviceApi({
    serialNumber: serialNumber ?? "",
  });
  const { initialize: initHeader } = useHeaderStore((state) => state);
  const purgeConfiguration = usePurgeStore((state) => state.purgeConfiguration);
  const setPurgeConfiguration = usePurgeStore(
    (state) => state.setPurgeConfiguration
  );
  const purgeRules = usePurgeStore((state) => state.rules);
  const setDevice = usePurgeStore((state) => state.setDevice);
  const currentEvent = usePurgeStore((state) => state.currentEvent);
  const setCurrentEvent = usePurgeStore((state) => state.setCurrentEvent);
  const setNextEvent = usePurgeStore((state) => state.setNextEvent);
  const purgeEvent = usePurgeStore((state) => state.purgeEvent);
  const setPurgeEvent = usePurgeStore((state) => state.setPurgeEvent);
  const nextEvent = usePurgeStore((state) => state.nextEvent);
  const setTrial = usePurgeStore((state) => state.setTrial);
  const headingValidation = usePurgeStore((state) => state.headingValidation);
  const validate = usePurgeStore((state) => state.validate);
  const validateHeading = usePurgeStore((state) => state.validateHeading);
  const setInformationBlockContent = usePurgeStore(
    (state) => state.setInformationBlockContent
  );
  const informationBlockContent = usePurgeStore(
    (state) => state.informationBlockContent
  );
  const [loading, setLoading] = useState<boolean>(false);
  const resetPurgeStore = usePurgeStore((state) => state.reset);
  const setStatus = usePurgeStore((state) => state.setStatus);
  const status = usePurgeStore((state) => state.status);
  const setCurrentGuideId = useGuideStore((state) => state.setCurrentGuideId);
  const setPurgeEventStatus = usePurgeStore(
    (state) => state.setPurgeEventStatus
  );
  const setAllowLogoAction = useLeftNavigationStore(
    (state) => state.setAllowLogoAction
  );
  const setAllowAction = useLeftNavigationStore(
    (state) => state.setAllowAction
  );

  useEffect(() => {
    setTrialData(trialQueries.trial.data || {});
  }, [trialQueries.trial.isFetched]);

  useEffect(() => {
    return () => {
      setAllowLogoAction(true);
      setAllowAction(true);
      resetPurgeStore();
    };
  }, []);

  useEffect(() => {
    setAllowAction(false);
    setAllowLogoAction(false);
    initHeader(
      {
        mainTitle: {
          label: "header.title.main.label.purge",
          text: "header.title.main.label.purge",
        },
        subTitleItems: [
          {
            label: "header.title.subItem.label.device",
            text: serialNumber || "",
          },
        ],
      } as InitHeaderOptions,
      {
        activeStep: 2,
        allowAction: purgeEvent?.status !== CardStatus.IN_PROGRESS,
        onClick: undefined,
        steps: [
          {
            label: "progressBar.step.trialsManagement",
            status: ProgressBarStatus.SUCCESS,
            link: ROUTES.TRIALS_MANAGEMENT,
            enableLink: true,
          },
          {
            label: "progressBar.step.trialParticipants",
            status: ProgressBarStatus.SUCCESS,
            link: ROUTES.TRIAL_PARTICIPANTS,
            enableLink: true,
          },
          {
            label: "progressBar.step.trialParticipantDevices",
            status: ProgressBarStatus.IN_PROGRESS,
            link: ROUTES.TRIAL_PARTICIPANT_DEVICES,
            enableLink: false,
          },
          {
            label: "progressBar.step.trialParticipantExperiment",
            status: ProgressBarStatus.PENDING,
            link: ROUTES.TRIAL_PARTICIPANT_EXPERIMENT,
            enableLink: false,
          },
          {
            label: "Results",
            status: ProgressBarStatus.PENDING,
            link: ROUTES.TRIAL_PARTICIPANT_EXPERIMENT_RESULTS,
            enableLink: false,
          },
        ],
        signalIndicator: {
          heading: currentEvent?.heading,
          validation: headingValidation,
        },
      } as InitProgressBarOptions,
      [
        {
          key: "trialId",
          value: trialId || "",
        },
        {
          key: "trialMemberId",
          value: trialMemberId || "",
        },
      ],
      false
    );
    setCurrentGuideId(TroubleshootGuideTypes.PURGE);
  }, [trialId, status, headingValidation]);

  useEffect(() => {
    if (!trialQueries.trial.data) return;
    setTrial(trialQueries.trial.data);
  }, [trialQueries.trial.isFetched]);

  useEffect(() => {
    if (!getTrialConfiguration) return;
    if (!trialQueries.trial.data) return;
    if (purgeConfiguration) return;

    const _annotationConfiguration = getTrialConfiguration;

    if (!_annotationConfiguration) return;

    const { device } = _annotationConfiguration.data;
    if (device) {
      setDevice(device);
      setPurgeConfiguration(device.purge);
      setTrial(getTrialConfiguration.data);
      const eventsConfiguration = device.purge.events;
      const currentEvent = getNextEvent(eventsConfiguration);
      const nextEvent = getNextEvent(eventsConfiguration, currentEvent);
      setNextEvent(nextEvent);
      setCurrentEvent(currentEvent);
    }
  }, [
    trialQueries.trial.isFetched,
    trialQueries.trial.data,
    currentEvent,
    getTrialConfiguration,
  ]);

  useEffect(() => {
    if (!currentEvent) return;
    if (!purgeConfiguration) return;
    const eventIndex = purgeConfiguration.events.findIndex(
      (event) => event.name.toLowerCase() === currentEvent.name.toLowerCase()
    );
    if (eventIndex === 0) {
      resetPurgeStore();
    }
  }, []);

  useEffect(() => {
    if (!currentEvent) return;
    setInformationBlockContent(currentEvent?.content);
  }, [currentEvent]);

  useEffect(() => {
    if (!currentEvent) return;
    if (!deviceQueries) return;
    if (!currentEvent?.heading) return;
    if (!deviceQueries.device.data?.state.metrics) return;
    const metrics = deviceQueries.device.data.state.metrics;
    validateHeading(metrics);
  }, [deviceQueries.device.data, validate, currentEvent]);

  const handleFailedEvent = () => {
    navigate(referrerUrl || ROUTES.ROOT);
    setTimeout(() => resetPurgeStore(), 1000);
  };

  const handleCreateEvent = async (status: CardStatus, params?: any) => {
    if (!purgeRules?.length) {
      setStatus(ProgressBarStatus.FAIL);
      setPurgeEvent({
        name: currentEvent?.name || "",
        createdAt: moment(),
        status: CardStatus.FAIL,
      });
      setPurgeEventStatus(CardStatus.FAIL);
      setLoading(false);
      return;
    }

    if (currentEvent?.status === AnnotationStatus.COMPLETE) {
      navigate(referrerUrl || ROUTES.ROOT);
      setTimeout(() => resetPurgeStore(), 1000);
    }

    setLoading(true);
    if (currentEvent?.stopOnFail) {
      if (
        purgeEvent?.status === CardStatus.FAIL ||
        status === CardStatus.FAIL
      ) {
        setStatus(ProgressBarStatus.FAIL);
        setLoading(false);
        return;
      }
    }

    setPurgeEventStatus(CardStatus.SUCCESS);
    setPurgeEvent({
      name: nextEvent?.name || "",
      createdAt: moment(),
      status,
    });

    setCurrentEvent(nextEvent);
    const _nextEvent = getNextEvent(purgeConfiguration?.events, nextEvent);
    setNextEvent(_nextEvent);
    setStatus(nextEvent?.status as unknown as ProgressBarStatus);

    setLoading(false);
  };

  const createRpcEvent = async (payload: CreateRpcEventParams) => {
    if (rpcEventCommandType.COMMANDS in payload) {
      const { serialNumber, wait, commands } = payload;
      const data = commands?.map((command) => ({
        ...command,
        rpcType: rpcType.TWO,
      }));
      await devicesQueries.createRpcEvents.mutateAsync({
        wait: wait || 0,
        data,
        serialNumber,
      });
    } else {
      const { method, params, serialNumber } = payload;
      await devicesQueries.createRpcEvents.mutateAsync([
        {
          method,
          params,
          rpcType: rpcType.TWO,
          serialNumber,
        },
      ]);
    }
  };

  const handleGenerateRPC = async (
    status: CardStatus,
    payload:
      | {
          method: string;
          params: { [key: string]: string };
        }
      | {
          wait: number;
          commands: Array<{
            method: string;
            params: { [key: string]: string };
          }>;
        }
  ) => {
    if (!serialNumber) return;
    setLoading(true);

    try {
      if (rpcEventCommandType.COMMANDS in payload) {
        const { wait, commands } = payload;
        await createRpcEvent({
          wait,
          commands,
          serialNumber: serialNumber,
        });
      } else {
        const { method, params } = payload;
        await createRpcEvent({
          method,
          params,
          serialNumber: serialNumber,
        });
      }
    } catch (err) {
      console.error("Failed to generate RPC:", err);
    } finally {
      setLoading(false);
    }
  };

  const actions = {
    createEvent: handleCreateEvent,
    failEvent: handleFailedEvent,
    generateRPC: handleGenerateRPC,
  };

  enum validateComponent {
    MetricsContainer = "MetricsContainer",
    MeasurementCards = "MeasurementCards",
  }

  return (
    <>
      <InformationBlock
        status={purgeEvent?.status || (CardStatus.INFO as any)}
        configurations={informationBlockContent}
      />
      <TimerController actions={actions} loading={loading} />
      <Grid
        container
        alignItems="center"
        justifyContent="space-around"
        flexDirection="column"
        gap={3}
        sx={containerStyle}
      >
        <Grid width={"100%"}>
          <Grid
            container
            alignItems={"center"}
            justifyContent={"space-between"}
            flexWrap={"nowrap"}
          >
            <Grid size={"grow"}>
              {serialNumber &&
                currentEvent?.validate?.component ===
                  validateComponent.MetricsContainer && (
                  <Metrics
                    onEvent={handleCreateEvent}
                    deviceQueries={deviceQueries}
                  />
                )}
            </Grid>
            <Grid size={{ sm: 4 }} display={"flex"} justifyContent={"right"}>
              <TimerButtonContainer actions={actions} loading={loading} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default PurgeDevice;
