import { Grid2 as Grid, SvgIcon, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { StyledLabelContainer } from "../components.styles";
import { trialStatusConfig } from "constants/index";
import { Theme } from "@mui/system";
import { TrialStatus } from "services/cde.service";
import { CSSProperties } from "react";
import { AnnotationStatus } from "services/annotation.service";

interface LabelProps {
  status: TrialStatus | AnnotationStatus;
  showIcon?: boolean;
  text?: string;
  icon?: React.ElementType;
  color: Theme["palette"];
  sx?: CSSProperties;
  showIconInFront?: boolean;
}

const Label: React.FC<LabelProps> = ({
  status,
  showIcon = false,
  text,
  color,
  icon,
  sx,
  showIconInFront = false,
}) => {
  const { t } = useTranslation();
  const trialStatusText = trialStatusConfig[status]?.text || "";

  const IconDisplay: React.FC<{ icon?: React.ElementType; margin: string }> = ({
    icon,
    margin,
  }) => (
    <Grid display="flex" sx={{ margin }}>
      {icon ? <SvgIcon component={icon} /> : null}
    </Grid>
  );

  return (
    <StyledLabelContainer
      container
      alignItems="center"
      justifyContent="space-between"
      background={color.lighter}
      color={color.dark}
      sx={sx}
    >
      {showIconInFront && showIcon && icon && (
        <IconDisplay icon={icon} margin="0 0.5rem 0 0" />
      )}
      <Grid>
        <Typography
          sx={{
            fontSize: "inherit",
            color: color.dark,
          }}
        >
          {t(text || trialStatusText)}
        </Typography>
      </Grid>
      {!showIconInFront && showIcon && icon && (
        <IconDisplay icon={icon} margin="0 0 0 0.5rem" />
      )}
    </StyledLabelContainer>
  );
};

export default Label;
