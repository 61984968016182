import { Grid2 as Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomDrawer from "components/CustomDrawer";
import useGuideStore from "./guide.store";
import TroubleShootGuideItem from "./TroubleShootGuideItem.component";
import Search from "components/Search.component";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import {
  TroubleshootGuideActions,
  TroubleshootGuideTypes,
} from "constants/index";
import { ROUTES } from "constants/routes.constants";
import { buildLocalUrl } from "utils/url.utils";
import { TRIAL_ANALYTICS_EXPAND } from "services/cde.service";
import useTrialApi from "hooks/useTrialApi.hook";
import NewParticipant from "features/trials/components/createTrial/NewParticipant";
import { useHelpers } from "pages/Participants/usehelpers";
import useTrialStore from "stores/trial.store";

interface GuideProps {
  isOpen: boolean;
  onClose: () => void;
}

const TroubleShootGuide: React.FC<GuideProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const { trialId, trialMemberId } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const serialNumbers = searchParams.get("serial-numbers")?.split(",");
  const filteredGuides = useGuideStore((state) => state.filteredGuides);
  const setGuideSearchInput = useGuideStore((state) => state.setSearchInput);
  const setActiveGuideState = useGuideStore(
    (state) => state.setActiveGuideState
  );
  const [isParticipantModalOpen, setIsParticipantModalOpen] = useState(false);
  const setTrialData = useTrialStore((state) => state.setTrialData);

  const queries = useTrialApi({
    trialId: trialId || "",
    participants: false,
    expand: `${TRIAL_ANALYTICS_EXPAND.ANNOTATIONS}|`,
  });

  useEffect(() => {
    const options = {
      ...queries.options,
      trialId,
    };
    queries.setOptions(options);
  }, [trialId]);

  useEffect(() => {
    setTrialData(queries.trial.data || {});
  }, [queries.trial.isFetched]);

  const handleSearch = (params: any) => {
    setGuideSearchInput(params.search);
  };

  const handleParticipantModalClose = async () => {
    setIsParticipantModalOpen(false);
  };

  const { createNewParticipant } = useHelpers(
    queries,
    trialId || "",
    handleParticipantModalClose
  );

  const handleNavigateToDevicesScreen = async () => {
    if (!trialMemberId || !trialId) return;
    const redirectUrl = buildLocalUrl({
      path: ROUTES.TRIAL_PARTICIPANT_DEVICES,
      params: {
        trialId,
        trialMemberId,
      },
    });
    navigate(redirectUrl);
  };

  const handleNavigateToPurgeScreen = async () => {
    if (!serialNumbers || !trialId) return;
    const redirectUrl = buildLocalUrl({
      path: ROUTES.DEVICES_PURGE,
      params: {
        trialId,
        serialNumber: serialNumbers ? serialNumbers[0] : "",
      },
    });
    navigate(redirectUrl);
  };

  const handleNavigateToHomeScreen = async () => {
    const redirectUrl = buildLocalUrl({
      path: ROUTES.TRIALS_MANAGEMENT,
      params: {},
    });
    navigate(redirectUrl);
  };

  const handleNewParticipant = async () => {
    setIsParticipantModalOpen(true);
  };

  useEffect(() => {
    setActiveGuideState(TroubleshootGuideTypes.APPLICATION);
    if (trialId) {
      setActiveGuideState(TroubleshootGuideTypes.STUDY);
    }
  }, [trialId]);

  const actions = {
    [TroubleshootGuideActions.REDIRECT_TO_PURGE]: handleNavigateToPurgeScreen,
    [TroubleshootGuideActions.REDIRECT_TO_DEVICES]:
      handleNavigateToDevicesScreen,
    [TroubleshootGuideActions.REDIRECT_TO_HOME]: handleNavigateToHomeScreen,
    closeDrawer: onClose,
    [TroubleshootGuideActions.CREATE_NEW_PARTICIPANT]: handleNewParticipant,
  };

  return (
    <>
      <CustomDrawer
        open={isOpen}
        onClose={onClose}
        additionalProps={{
          titleText: t("troubleShootGuide.header.title.label"),
          id: "troubleShootGuide-guide",
          headerWidth: 12,
        }}
      >
        <Grid
          container
          sx={{ padding: "1.5rem" }}
          flexDirection="column"
          gap={"2rem"}
        >
          <Grid>
            <Search
              onSearch={handleSearch}
              placeholder={t("troubleShootGuide.search.placeholder")}
              triggerOnFieldChange={true}
              width="100%"
            />
          </Grid>
          <Grid>
            {filteredGuides.length ? (
              filteredGuides.map((content, index) => (
                <React.Fragment key={content.id + index}>
                  {content.guide.map((guide, guideIndex) => (
                    <TroubleShootGuideItem
                      key={`key_${guide.title}_${guideIndex}`}
                      guide={guide}
                      guideIndex={guideIndex}
                      actions={actions}
                    />
                  ))}
                </React.Fragment>
              ))
            ) : (
              <Typography display={"flex"} justifyContent={"center"}>
                {t("troubleShootGuide.noRecordsFound.label")}
              </Typography>
            )}
          </Grid>
        </Grid>
      </CustomDrawer>
      <NewParticipant
        isOpen={isParticipantModalOpen}
        onClose={handleParticipantModalClose}
        onSubmit={createNewParticipant}
      />
    </>
  );
};

export default TroubleShootGuide;
