import { useTranslation } from "react-i18next";
import { DrawerStyleGrid } from "../ParticipantDashboard.styles";
import { Grid2 as Grid } from "@mui/material";
import { AnnotationStatus } from "constants/index";
import moment from "moment";
import { statusIcon } from "utils/participant.utills";
import Label from "features/trials/components/Label";
import { useTheme } from "@mui/system";

interface CardProps {
  annotation: Record<string, any>;
}
const ResultDetailsCard: React.FC<CardProps> = ({ annotation }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const status = annotation?.status as AnnotationStatus;
  return (
    <DrawerStyleGrid
      container
      display={"flex"}
      sx={{ height: { xs: "8rem", md: "6rem" } }}
    >
      <Grid flex={"1 1 60%"}>
        <Grid
          container
          sx={{ padding: "1rem" }}
          display={"flex"}
          flexDirection={"row"}
        >
          <Grid
            flex={"1 1 50%"}
            sx={{
              display: "flex",
              justifyContent: "left",
            }}
          >
            {t("participantDashboard.ResultDetails.started.label")}
          </Grid>
          <Grid flex={"1 1 50%"}>
            {moment(annotation.createdAt).format("DD MMM YYYY hh:mm A")}
          </Grid>
          <Grid
            flex={"1 1 50%"}
            sx={{
              display: "flex",
              justifyContent: "left",
            }}
          >
            {t(
              `participantDashboard.ResultDetails.status.${AnnotationStatus.COMPLETE}.label`
            )}
          </Grid>
          <Grid flex={"1 1 50%"}>
            {moment(annotation?.completedAt).format("DD MMM YYYY hh:mm A")}
          </Grid>
        </Grid>
      </Grid>
      <Grid flex={"1 1 40%"} display={"flex"} justifyContent={"end"}>
        <Label
          showIcon={true}
          status={status}
          text={t(`participantDashboard.resultCard.status.${status}.label`)}
          color={
            status === AnnotationStatus.COMPLETE
              ? {
                  light: theme.palette.success.main,
                  dark: theme.palette.common.white,
                }
              : theme.palette.error
          }
          icon={statusIcon[status] as React.ElementType}
          showIconInFront={true}
          sx={{ width: "10rem" }}
        />
      </Grid>
    </DrawerStyleGrid>
  );
};

export default ResultDetailsCard;
