import { Grid2 as Grid } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { FieldConfig } from "utils/form.utils";
import FormContainer from "components/FormContainer";
import CustomDrawer from "components/CustomDrawer";
import { FormikFormProps } from "formik";
import useTrialStore from "stores/trial.store";

interface NewParticipantType {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (values: FormikFormProps) => void;
}
const NewParticipant: React.FC<NewParticipantType> = ({
  isOpen,
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const participantRegistrationFormFields = useTrialStore((state) =>
    state.getFormFields("participantRegistration")
  );
  const schemaData: FieldConfig[] = participantRegistrationFormFields;
  const initialValues = schemaData.reduce(
    (acc, { name }) => ({ ...acc, [name]: "" }),
    {}
  );

  return (
    <CustomDrawer
      open={isOpen}
      onClose={onClose}
      additionalProps={{
        titleText: t("register_new_participant"),
        id: "new-participant",
        headerWidth: 6,
      }}
    >
      <Grid container sx={{ padding: "1.5rem" }} flexDirection="column">
        <Grid flexGrow={1}>
          <FormContainer
            schemaData={schemaData}
            initialValues={initialValues}
            onSubmit={onSubmit}
            onClose={onClose}
            labels={{ preSubmit: "submit", postSubmit: "submitting" }}
          />
        </Grid>
      </Grid>
    </CustomDrawer>
  );
};

export default NewParticipant;
