import { useTheme } from "@mui/system";
import { Typography, Grid2 as Grid, IconButton } from "@mui/material";
import Label from "features/trials/components/Label";
import EditIcon from "components/icons/EditIcon";
import {
  StyledCard,
  StyledCardContent,
  StyledBox,
} from "../ParticipantDashboard.styles";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { AnnotationStatus } from "services/annotation.service";
import { statusIcon } from "utils/participant.utills";
import Menu from "pages/Devices/Components/Menu.component";
import { useState } from "react";
import ResultDetails from "./ResultDetails.component";

interface ResultCardProps {
  annotation: Record<string, any>;
  configurations: Record<string, any>;
}

const ResultCard: React.FC<ResultCardProps> = ({
  annotation,
  configurations,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [menuAnchor, setMenuAnchor] = useState(undefined);
  const [openResultDetails, setOpenResultDetails] = useState(false);
  const status = annotation?.status as AnnotationStatus;
  let formattedDuration = "";
  if (status === AnnotationStatus.COMPLETE) {
    const duration = moment.duration(
      moment(annotation?.completedAt).diff(moment(annotation?.createdAt))
    );
    const minutes = Math.floor(duration.asMinutes());
    const seconds = Math.floor(duration.asSeconds()) % 60;
    formattedDuration = `${minutes < 10 ? "0" : ""}${minutes}:${
      seconds < 10 ? "0" : ""
    }${seconds}`;
  }

  const handleOpenMenu = (event: any) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuAnchor(undefined);
  };

  const handleResultDetailsClose = () => {
    setOpenResultDetails(false);
  };

  const handleResultDetailsOpen = () => {
    setOpenResultDetails(true);
  };

  const color =
    status === AnnotationStatus.COMPLETE
      ? theme.palette.success
      : status === AnnotationStatus.TERMINATED
      ? theme.palette.warning
      : theme.palette.error;

  return (
    <>
      <Grid size={{ xs: 6, sm: 4, md: 4, xl: 3 }}>
        <StyledCard>
          <StyledCardContent>
            <Grid container alignItems="center" justifyContent={"right"}>
              <Grid>
                <Label
                  showIcon={true}
                  status={status}
                  text={t(
                    `participantDashboard.resultCard.status.${status}.label`
                  )}
                  color={color}
                  icon={statusIcon[status] as React.ElementType}
                  sx={{ padding: "0.5rem" }}
                  showIconInFront={true}
                />
              </Grid>
            </Grid>
            <StyledBox mt={1}>
              <Typography variant="body2" sx={{ fontWeight: 300 }}>
                {t("participantDashboard.resultCard.started.label")}:
              </Typography>
              <Typography
                variant="body2"
                component="div"
                sx={{ fontWeight: 600 }}
              >
                {moment.utc(annotation?.createdAt).format("YYYY-MM-DD")}
              </Typography>
            </StyledBox>
            <StyledBox mb={1}>
              <Grid container display={"flex"} alignItems={"center"}>
                <Grid flexGrow={1}>
                  {annotation?.status === AnnotationStatus.COMPLETE ? (
                    <>
                      <Typography variant="body2" sx={{ fontWeight: 300 }}>
                        {t("participantDashboard.resultCard.length.label")}:
                      </Typography>
                      <Typography
                        variant="body2"
                        component="div"
                        sx={{ fontWeight: 600 }}
                      >
                        {formattedDuration}
                      </Typography>
                    </>
                  ) : null}
                </Grid>
                <Grid flexGrow={1} display={"flex"} justifyContent={"end"}>
                  {status === AnnotationStatus.COMPLETE ? (
                    <>
                      <IconButton
                        onClick={handleOpenMenu}
                        sx={{ cursor: "pointer" }}
                      >
                        <EditIcon sx={{ width: "2.8rem" }} />
                      </IconButton>
                      <Menu
                        anchorEl={menuAnchor || null}
                        onClose={handleCloseMenu}
                        menuItems={[
                          {
                            label: t(
                              "participantDashboard.resultCard.detail.popover.label"
                            ),
                            icon: "ic_analytics.svg",
                            action: handleResultDetailsOpen,
                          },
                        ]}
                      />
                    </>
                  ) : null}
                </Grid>
              </Grid>
            </StyledBox>
          </StyledCardContent>
        </StyledCard>
      </Grid>
      {openResultDetails ? (
        <ResultDetails
          isOpen={openResultDetails}
          onClose={handleResultDetailsClose}
          annotation={annotation}
          configurations={configurations}
        />
      ) : null}
    </>
  );
};

export default ResultCard;
