import { Grid2 as Grid } from "@mui/material";
import { useTheme } from "@mui/system";
import Card, { CardBackgrounds, CardSize, CardStatus } from "components/Card";
import { useResultStore } from "pages/Result/result.store";
import { useTranslation } from "react-i18next";
import { deviceRulesCases } from "utils/device.utils";
import { get } from "utils/lodashHelpers";
import { MetricsRule, resultType } from "services/annotation.service";
import { GridStyle, StyleTypography } from "./MetricResults.styles";

interface Result {
  name: String;
  status: Boolean;
  details: Details[];
  failure?: JSON;
}

interface Details {
  checked_item: String;
  eval?: Number | String;
  status: Boolean;
  lowerBound?: Number;
  upperBound?: Number;
  exactBound?: String | Number;
}

interface Validations {
  [key: string]: Result;
}

interface MetricResultsProps {
  result: { validations: Validations };
}

export const MetricResults: React.FC<MetricResultsProps> = ({ result }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const currentEvent = useResultStore((state) => state.currentEvent);
  const events = useResultStore((state) => state.events);
  if (!currentEvent) return null;
  if (!currentEvent.resultCards) return null;

  const currentStatus =
    events.find((event) => event.name === currentEvent.name).status ||
    CardStatus.IN_PROGRESS;

  const resultCards = currentEvent.resultCards.filter(
    (resultCard) => resultCard.status === currentStatus
  );

  const getColor = (status: CardStatus) => {
    switch (status) {
      case CardStatus.IN_PROGRESS:
        return theme.palette.grey[600];
      case CardStatus.FAIL:
        return theme.palette.error.main;
      case CardStatus.WARNING:
        return theme.palette.warning.main;
      case CardStatus.SUCCESS:
        return theme.palette.success.main;
      default:
        return theme.palette.grey[400];
    }
  };

  return (
    <Grid flexGrow={1}>
      <Grid
        container
        justifyContent={resultCards.length > 1 ? "center" : "flex-start"}
      >
        {resultCards.map((card, index) => {
          const validation = get(result, card.name);
          if (!validation) return null;
          return validation.details.map(
            (detail: Details, detailIndex: number) => {
              const rule: any = card.rules.find(
                (cardRule: MetricsRule) =>
                  cardRule.type.toLowerCase() ===
                    detail.checked_item.toLowerCase() &&
                  deviceRulesCases(cardRule, detail.eval)
              );
              if (!rule) return null;
              const name = card.label;
              const cardColor = getColor(rule.status as CardStatus);
              return (
                <Grid key={detailIndex}>
                  <Card
                    status={rule.status as CardStatus}
                    background={card.background as CardBackgrounds}
                    size={CardSize.fixed}
                    label={t(name)}
                    labelColor={cardColor}
                    showIcon={card.showIcon}
                    backgroundIconColor={cardColor}
                    iconStyle={{
                      top: "3rem",
                    }}
                    sx={{
                      backgroundColor: theme.palette.white.light,
                      color: cardColor,
                      border: `1px solid ${cardColor}`,
                    }}
                  >
                    <GridStyle
                      container
                      display={"flex"}
                      justifyContent={"center"}
                      sx={{
                        ...(card.showResult === resultType.STRING && {
                          right: "0.5rem",
                          width: "75%",
                          justifyContent: "flex-end",
                        }),
                      }}
                    >
                      <Grid>
                        <StyleTypography
                          sx={{
                            ...(card.showResult === resultType.PERCENTAGE && {
                              fontSize: "3rem",
                              lineHeight: "initial",
                              "&::after": {
                                content: '"%"',
                                fontSize: "1rem",
                              },
                            }),
                          }}
                        >
                          {card.showResult === resultType.STRING
                            ? t(rule?.label)
                            : card.showResult === resultType.PERCENTAGE
                            ? (Number(detail.eval) * 100).toFixed(0)
                            : Number(detail.eval).toFixed(2)}
                        </StyleTypography>
                      </Grid>
                    </GridStyle>
                  </Card>
                </Grid>
              );
            }
          );
        })}
      </Grid>
    </Grid>
  );
};
