import React from "react";
import { Grid2 as Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { buildLocalUrl } from "utils/url.utils";
import { ROUTES } from "constants/routes.constants";
import Button, { ButtonColor } from "components/Button";
import { useTranslation } from "react-i18next";

interface HeaderProps {
  name: string;
  trialId: string | undefined;
}

const StudyHeader: React.FC<HeaderProps> = ({ name, trialId }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleOpenParticipantClick = async () => {
    const url = buildLocalUrl({
      path: ROUTES.TRIAL_PARTICIPANTS,
      params: { trialId },
    });
    navigate(url);
  };
  return (
    <Grid
      container
      display={"flex"}
      alignItems="center"
      justifyContent={"space-between"}
      sx={{ flexDirection: { sm: "row", xs: "column" } }}
      columns={12}
    >
      <Grid size={{ md: 9 }}>
        <Typography>
          {t("studyOverview.header.text").replace("{{trialName}}", name)}
        </Typography>
      </Grid>
      <Grid size={{ md: 3 }} justifyContent={"end"} display={"flex"}>
        <Button
          onClick={handleOpenParticipantClick}
          color={ButtonColor.primary}
        >
          {t("studyOverview.header.button.text")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default StudyHeader;
