import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid2";
import {
  useHeaderStore,
  ProgressBarStatus,
  InitProgressBarOptions,
  InitHeaderOptions,
} from "stores/header.store";
import {
  containerStyle,
  iconsLink,
  DeviceStateEnum,
} from "../../constants";
import { ROUTES } from "constants/routes.constants";
import { useParams } from "react-router-dom";
import useTrialApi from "hooks/useTrialApi.hook";
import StudyTabs from "./Components/studyTabs.component";
import StudyHeader from "./Components/studyHeader.component";
import { useLeftNavigationStore } from "stores/navigation.store";
import { useNavigate } from "react-router-dom";
import { buildLocalUrl } from "utils/url.utils";
import { useTheme } from "@mui/material/styles";
import StudyTabContent from "./Components/studyTabContent.component";
import { StyledTitle } from "pages/Participants/Participants.styles";

import { useTranslation } from "react-i18next";
import { useIsMobile } from "hooks/media.hook";
import Search from "components/Search.component";
import { Typography } from "@mui/material";
import QuickNavigation from "components/QuickNavigation/QuickNavigation.component";
import { CardStatus, getFiltersColor } from "components/Card";
import { getDeviceStatus, statusIcon } from "utils/device.utils";
import { useDeviceStore } from "pages/Devices/device.store";
import Loading from "components/Loading";
import DevicesList from "pages/Devices/Components/DevicesList.component";
import useDevicesApi from "hooks/useDevicesApi.hook";
import { rpcType } from "services/fleet.service";
import { ExperimentActions } from "pages/Experiment/experiment.dto";
import { CustomError } from "utils/privateHttp.utils";
import useTrialStore from "stores/trial.store";
import { useStudiesStore } from "./studies.store";

const deviceStates = [
  DeviceStateEnum.Ready,
  DeviceStateEnum.Preparing,
  DeviceStateEnum.Unavailable,
  DeviceStateEnum.InUse,
].join(",");

const StudyDevices = () => {
  const { t } = useTranslation();
  const { trialId } = useParams();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const theme = useTheme();
  const initHeader = useHeaderStore((state) => state.initialize);
  const { addNavigationState, switchNavigationState } = useLeftNavigationStore(
    (state) => state
  );
  const dashboardCdes = useLeftNavigationStore((state) => state.dashboardCdes);
  const canViewDashboard = useStudiesStore((state) => state.canViewDashboard);
  const [input, setInput] = useState<string>("");
  const [navigationOptions, setNavigationOptions] = useState<any[]>();
  const [statusCount, setStatusCount] = useState<string>();
  const [status, setStatus] = useState<string>(deviceStates);
  const [searchSerialNumbers, setSearchSerialNumbers] = useState<string[]>([]);
  const deviceStatuses = useDeviceStore((state) => state.deviceStatuses);
  const setDeviceStatus = useDeviceStore((state) => state.setDeviceStatus);
  const setPrevStatus = useDeviceStore((state) => state.setPrevStatus);
  const prevStatus = useDeviceStore((state) => state.prevStatus);
  const getSerialNumbers = useTrialStore((state) => state.getSerialNumbers());
  const setTrialData = useTrialStore((state) => state.setTrialData);
  const getTrialName = useTrialStore((state) => state.getTrialName());
  const getTrialConfiguration = useTrialStore((state) =>
    state.getTrialConfiguration()
  );
  const trialFields = useTrialStore((state) => state.otherFields);

  const setIsModalOpen = useDeviceStore(
    (state) => state.setIsExperimentModalOpen
  );
  const [deviceData, setDeviceData] = useState<any>([]);

  const queries = useTrialApi({
    trialId: trialId || "",
    participants: false,
    take: 0,
    skip: 0,
  });

  useEffect(() => {
    setTrialData(queries.trial.data || {});
  }, [queries.trial.isFetched]);

  const devicesQueries = useDevicesApi({
    serialNumbers: [],
  });

  const handleNavigation = (path: string) => {
    navigate(
      buildLocalUrl({
        path,
        params: { trialId },
      })
    );
  };

  const onSearch = (input: any) => {
    setStatus(deviceStates);
    setInput(input.search);
  };

  const handleClick = async (key: string) => {
    key = DeviceStateEnum[key as keyof typeof DeviceStateEnum];
    const statusArray = status.split(",");
    const isKeySelected = statusArray.includes(key);
    setStatus(
      isKeySelected
        ? statusArray.filter((status: any) => status !== key).join(",")
        : [...statusArray, key].join(",")
    );
    const updatedOptions = navigationOptions?.map((option) => ({
      ...option,
      defaultSelected:
        option.key === key ? !isKeySelected : option.defaultSelected,
    }));
    setNavigationOptions(updatedOptions);
  };

  const handleOpenExperimentFormModal = () => {
    setIsModalOpen(true);
  };

  const deviceStatusCheck = (device: any, serialNumber: string) => {
    if (!device) return;

    if (!getTrialConfiguration) return;

    const rules = getTrialConfiguration.data;

    const inUse = device?.annotation?.annotation?.status;

    let deviceStatus = getDeviceStatus(inUse, rules, device);

    setDeviceStatus({ sn: serialNumber, state: deviceStatus.label });

    return {
      serialNumber,
      isDeviceRulePassed: deviceStatus.isDeviceRulePassed,
      deviceStatusObj: { sn: serialNumber, state: deviceStatus.label },
      isDeviceReadyObj: {
        label: deviceStatus.label,
        status: deviceStatus.status,
      },
      validationResults: deviceStatus.validationResults,
    };
  };

  const createRPCEvent = async (payload: any) => {
    const { params, status: validationStatus, sn, deviceData } = payload;

    if (
      validationStatus.includes(
        deviceData?.isDeviceReadyObj?.label?.toUpperCase()
      )
    ) {
      try {
        await devicesQueries.createRpcEvents.mutateAsync([
          {
            ...params,
            rpcType: rpcType.TWO,
            serialNumber: sn,
          },
        ]);
      } catch (err) {}
    }
  };

  const actions: ExperimentActions = {
    createRPCEvent: createRPCEvent,
  };

  useEffect(() => {
    if (!getSerialNumbers.length) return;
    const serialNumbers = getSerialNumbers;
    devicesQueries.setOptions({ serialNumbers: serialNumbers });
  }, [getSerialNumbers]);

  useEffect(() => {
    const cdeId = trialFields.cdeId;
    if (!cdeId) return;
    const cdeExists = canViewDashboard(cdeId);
    if (!cdeExists) {
      throw new CustomError(
        t("studiesDashboard.forbidden.error.message"),
        "403"
      );
    }
  }, [queries.trial.isFetched, dashboardCdes, trialFields]);

  useEffect(() => {
    addNavigationState("studiesDashboard", [
      {
        icon: iconsLink.back,
        label: "studiesDashboard.leftnavigation.backtostudies.label",
        onClick: () => handleNavigation(ROUTES.STUDIES_DASHBOARD),
        backgroundColor: theme.palette.common.white,
        color: theme.palette.primary.dark,
      },
      {
        icon: iconsLink.studyOverview,
        label: "studiesDashboard.study.overview.tab.label",
        onClick: () =>
          handleNavigation(ROUTES.STUDIES_DASHBOARD_STUDY_OVERVIEW),
        color: theme.palette.grey[600],
      },
      {
        icon: iconsLink.studyParticipants,
        label: "studiesDashboard.study.participants.tab.label",
        onClick: () =>
          handleNavigation(ROUTES.STUDIES_DASHBOARD_STUDY_PARTICIPANTS),
        color: theme.palette.grey[600],
      },
      {
        icon: iconsLink.studyDevices,
        label: "studiesDashboard.study.devices.tab.label",
        onClick: () => handleNavigation(ROUTES.STUDIES_DASHBOARD_STUDY_DEVICES),
        backgroundColor: theme.palette.grey[400],
        color: theme.palette.common.white,
      },
    ]);
    switchNavigationState("studiesDashboard");
    initHeader(
      {
        mainTitle: {
          label: "header.title.main.label.study",
          text: getTrialName,
        },
        subTitleItems: [],
        showMenu: true,
      } as InitHeaderOptions,
      {
        activeStep: 1,
        allowAction: true,
        steps: [
          {
            label: "progressBar.step.trialsManagement",
            status: ProgressBarStatus.SUCCESS,
            link: ROUTES.STUDIES_DASHBOARD,
            enableLink: true,
          },
          {
            label: "progressBar.step.studyDevices",
            status: ProgressBarStatus.IN_PROGRESS,
            link: ROUTES.STUDIES_DASHBOARD_STUDY_OVERVIEW,
            enableLink: false,
          },
        ],
      } as InitProgressBarOptions,
      [
        {
          key: "trialId",
          value: trialId || "",
        },
      ]
    );
  }, [getTrialName]);

  useEffect(() => {
    const navigationOptionsObject = statusCount
      ? Object.entries(DeviceStateEnum)
          .filter(([key]) => key !== DeviceStateEnum.All)
          .map(([key, value]) => {
            const adjustedValue =
              value === DeviceStateEnum.Ready
                ? CardStatus.SUCCESS
                : value === DeviceStateEnum.Unavailable
                ? CardStatus.FAIL
                : value === DeviceStateEnum.InUse
                ? CardStatus.PENDING
                : value === DeviceStateEnum.Preparing
                ? CardStatus.WARNING
                : value;
            return {
              key: key,
              icon: statusIcon[value as DeviceStateEnum] as React.ElementType,
              color: getFiltersColor(adjustedValue as CardStatus, theme),
              value: value,
              totalCount: statusCount[value as any] || 0,
              defaultSelected: statusCount[value as any]
                ? status.includes(value)
                : false,
            };
          })
      : [];
    setNavigationOptions(navigationOptionsObject);
  }, [statusCount]);

  useEffect(() => {
    const handleSearch = async () => {
      const serialNumbers = getSerialNumbers;
      const results = serialNumbers.filter((sn: string) =>
        sn.toLowerCase().includes(input.toLowerCase())
      );
      results.length
        ? setSearchSerialNumbers(results)
        : setSearchSerialNumbers([]);

      const states = serialNumbers.reduce((counts: any, serialNumber: any) => {
        const state = deviceStatuses[serialNumber]?.state;
        if (state) {
          counts[state] = (counts[state] || 0) + 1;
        }
        return counts;
      }, {});

      setStatusCount(states);
    };

    handleSearch();
  }, [input, status, deviceStatuses]);

  useEffect(() => {
    if (devicesQueries.devices.data?.length) {
      if (prevStatus !== devicesQueries.devices.data[0].status) {
        setPrevStatus(devicesQueries.devices.data[0].status);
      }
      setDeviceData(
        devicesQueries.devices.data.map((device) =>
          deviceStatusCheck(device, device.serialNumber)
        )
      );
    }
  }, [devicesQueries.devices.data, prevStatus]);

  return (
    <>
      {!queries.trial.isFetched ? (
        <Loading />
      ) : (
        <Grid
          display="flex"
          flexDirection={"column"}
          gap={2}
          sx={containerStyle}
        >
          <Grid flexGrow={1}>
            <StudyHeader name={getTrialName} trialId={trialId} />
          </Grid>
          <Grid flexGrow={1}>
            <StudyTabs tabValue={2} trialId={trialId} />
            <StudyTabContent value={2} index={2}>
              <Grid display="flex" flexDirection={"column"} gap={2}>
                <Grid flexGrow={1} pt={2}>
                  <Grid container>
                    <Grid flexGrow={1}>
                      <StyledTitle>
                        {t("studyDevices.devices.listing.heading")}
                      </StyledTitle>
                    </Grid>
                    <Grid sx={{ flex: "1" }}>
                      {!isMobile ? (
                        <Search
                          onSearch={onSearch}
                          placeholder={
                            "studyDevices.devices.search.placeholder.label"
                          }
                        />
                      ) : null}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid flexGrow={1}>
                  <Grid container display={"flex"} sx={{ flexWrap: "nowrap" }}>
                    {!isMobile ? (
                      <Grid sx={{ flex: "1" }}>
                        <Typography>
                          {t("studyDevices.devices.listing.subheading.label")}
                        </Typography>
                      </Grid>
                    ) : null}
                    <Grid sx={{ flex: "1" }}>
                      {isMobile ? (
                        <Search
                          onSearch={onSearch}
                          placeholder={
                            "studyDevices.devices.search.placeholder.label"
                          }
                        />
                      ) : null}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid flexGrow={1}>
                  <Grid container display="flex" gap={2}>
                    {navigationOptions?.length &&
                      navigationOptions.map((option) => (
                        <Grid key={option.key}>
                          <QuickNavigation
                            text={option.value}
                            icon={option.icon}
                            color={option.color}
                            count={option.totalCount}
                            defaultSelected={option.defaultSelected}
                            onClick={() =>
                              option.totalCount ? handleClick(option.key) : {}
                            }
                          />
                        </Grid>
                      ))}
                  </Grid>
                </Grid>
                <Grid flexGrow={1}>
                  <Grid
                    flexGrow={1}
                    sx={{ fontFamily: theme.typography.fontFamily }}
                  >
                    {devicesQueries.devices.data?.length ? (
                      <DevicesList
                        devices={devicesQueries.devices.data || []}
                        deviceData={deviceData}
                        status={status ? status : deviceStates}
                        actions={actions}
                        showPrechecks={false}
                        hideActionButton={true}
                        input={input}
                        searchSerialNumbers={searchSerialNumbers}
                        handleOpenExperimentFormModal={
                          handleOpenExperimentFormModal
                        }
                      />
                    ) : (
                      <Loading height="50vh" />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </StudyTabContent>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default StudyDevices;
