import React, { useEffect } from "react";

import { Timer as ShowTime } from "components/Timer.component";

import { useExperimentStore } from "../../../stores/experiment.store";
import { TimerDirection } from "constants/index";

interface TimerProps {
  sx?: any;
  time?: number;
  timeout?: number | undefined;
  direction?: TimerDirection;
  actions?: () => void;
}

export const Timer: React.FC<TimerProps> = ({
  direction,
  time,
  timeout,
  actions,
  sx = {},
}) => {
  const [actionUpdated, setActionUpdated] = React.useState(false);
  const timeSinceEvent = useExperimentStore((state) => state.timeSinceEvent);
  const loading = useExperimentStore((state) => state.loading);

  useEffect(() => {
    if (timeout === undefined || actionUpdated || !time || !actions) return;
    if (timeSinceEvent > time && timeout !== time) {
      setActionUpdated(true);
      actions();
    }
  }, [timeSinceEvent]);

  return (
    <ShowTime
      loading={loading}
      seconds={timeSinceEvent}
      direction={direction}
      timeout={time}
      sx={sx}
    />
  );
};
