import React, { useEffect } from "react";
import useSystemCheckApi from "hooks/useSystemCheckApi.hook";
import useSystemStatusStore, { SystemStatus } from "stores/systemCheck.store";
import { ConfigurationService } from "services/configuration.service";
import { getTimeDifference } from "services/time.services";

interface SystemCheckProviderProps {
  children: React.ReactNode;
}

const SystemCheckProvider: React.FC<SystemCheckProviderProps> = ({
  children,
}) => {
  const configurationData = ConfigurationService.configuration;
  const { systemStatusQuery } = useSystemCheckApi({
    refetchInterval: configurationData.config?.uptimeRobot?.statusCheck,
  });
  const setStatus = useSystemStatusStore((state) => state.setStatus);
  const setElapsedTime = useSystemStatusStore((state) => state.setElapsedTime);

  useEffect(() => {
    if (systemStatusQuery.isSuccess && systemStatusQuery.data) {
      setStatus(systemStatusQuery.data);
    } else if (systemStatusQuery.isError) {
      setStatus(SystemStatus.FAIL);
    }
  }, [systemStatusQuery.data, systemStatusQuery.isSuccess, systemStatusQuery.isError]);


   useEffect(() => {
     const fetchInitialTime = async () => {
       try {
         const differenceInMilliseconds = await getTimeDifference();
         setElapsedTime(differenceInMilliseconds);
       } catch (error) {
         console.error("Error fetching UTC time:", error);
       }
     };
     fetchInitialTime();
   }, []);

  return <>{children}</>;
};

export default SystemCheckProvider;
