import { get, post, patch, del, baseURL } from "utils/privateHttp.utils";
import { getDeviceStatus } from "services/fleet.service";
import {
  getAnnotationAnalyticsByIdentity,
  AnnotationConfiguration,
} from "services/annotation.service";
import { SortOrder } from "constants/index";

export enum TrialStatusActions {
  COMPLETE = "COMPLETE",
  TERMINATED = "TERMINATED",
  ACTIVATE = "ACTIVATE",
}

export enum TrialStatus {
  IN_PROGRESS = "IN_PROGRESS",
  TERMINATED = "TERMINATED",
  PENDING = "PENDING",
  COMPLETE = "COMPLETE",
}

export enum TrialAnnotationStatus {
  terminated = "terminated",
  complete = "complete",
  timeout = "timeout",
  total = "total",
  pending = "pending",
  inProgress = "inProgress",
}

export enum TRIAL_ANALYTICS_EXPAND {
  ANNOTATIONS = "ANNOTATIONS",
}

export enum ParticipantStatus {
  READY = "ready",
  TAKINGSAMPLES = "takingSamples",
}

export enum ParticipantSampleCapturedStatus {
  COMPLETED = "completed",
  NOTCOMPLETED = "notCompleted",
}

enum ParticipantType {
  PUI = "PUI",
}
interface Trial {
  uuid: string;
  cdeId: string;
  configurationId: string;
  configuration: AnnotationConfiguration;
  status: TrialStatus;
  name: string;
  description: string;
  preferences: Record<string, any>;
  serialNumbers: string[];
  createdAt: string;
  createdBy: string;
  modifiedAt: string;
  modifiedBy: string;
}

interface Search {
  status?: string[] | null;
  name?: string;
  description?: string;
  pui?: string;
}

interface Order {
  // Define the properties for order object if applicable
}

interface TrialData {
  cid: string;
  skip: number;
  take: number;
  total: number;
  search: Search;
  order: Order;
  data: Trial[];
}

interface Trial {
  uuid: string;
}

export interface TrialDetail {
  cid: string;
  configuration: Record<string, any>;
  cdeId: string;
  status: TrialStatus;
  id: string;
  uuid: string;
  configurationId: string;
  serialNumbers: string[];
  name: string;
  description: string;
  preferences: object;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
}

export interface Participant {
  cid: string;
  uuid: string;
  type: ParticipantType;
  pui: string;
  memberId: string;
  preferences: Record<string, any>;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  analytics?: Record<string, number>;
}

interface ParticipantData {
  cid: string;
  skip: number;
  take: number;
  total: number;
  search: Search;
  order: Order;
  data: Participant[];
}

export type SortingOrder = SortOrder.ASC | SortOrder.DESC;

export interface ParticipantsSortingState {
  pui: SortingOrder;
  createdAt: SortingOrder;
}

export interface TrialsSortingState {
  name: SortingOrder;
}

interface Identity {
  type: string;
  uuid: string;
  identity: string;
}

export interface Permissions {
  cdeId: string;
  permission: string;
}

export interface Member {
  cid?: string;
  uuid: string;
  userId?: string | null;
  language?: string;
  metadata: Record<string, any>;
  pui: string;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string | null;
  CDEpermissions?: Permissions[];
  identities?: Identity[];
  cdeId: string;
}

export interface UpdateParticipant {
  trialId: string | undefined;
  participantId: string;
  metadata: object;
}

export interface UpdateTrialData {
  trialId?: string;
  name?: string;
  description?: string;
  title?: string;
}

export enum TrialStatisticsStatus {
  pending = "PENDING",
  inProgress = "IN_PROGRESS",
  complete = "COMPLETE",
  terminated = "TERMINATED",
  timeout = "TIMEOUT",
}

type Statistics = {
  pending: number;
  inProgress: number;
  complete: number;
  terminated: number;
  timeout: number;
  total: number;
  trialAnalytics?: any;
};
export interface CdeAnalyticsResponse {
  cid: string;
  createdAt: string;
  trialAnalytics: CdeTrialAnalytics[];
  statistics: Statistics;
}

export interface UpdateTrialStatus {
  uuid: string;
  status: string;
  name?: string;
  description?: string;
  preferences?: Record<string, any>;
}

export const fetchTrialData = async (
  searchParams: Search,
  take: number = 30,
  skip: number = 0,
  order?: string,
  cdeIds?: string[]
): Promise<TrialData> => {
  const apiUrl = new URL(
    `/cde-service/cde-service/rest/v1/client/trial`,
    baseURL
  );
  apiUrl.searchParams.set("take", take.toString());
  apiUrl.searchParams.set("skip", skip.toString());
  if (cdeIds) apiUrl.searchParams.set("cdeIds", cdeIds.join(","));
  if (order) apiUrl.searchParams.set("order", order.toString());
  if (Object.keys(searchParams).length) {
    apiUrl.searchParams.set("search", formatSearchParams(searchParams));
  }

  const response = await get(apiUrl.toString());
  return response;
};

export const fetchTrialDetail = async (
  uuid: string,
  deviceStatus?: boolean
): Promise<TrialDetail> => {
  const response = await get(
    `/cde-service/cde-service/rest/v1/client/trial/${uuid}?expand=CONFIGURATION`
  );
  if (!deviceStatus) return response;

  if (response && deviceStatus) {
    const serialNumbersArray: string[] = response.serialNumbers;
    try {
      const getStatusPromises = serialNumbersArray.map(async (serialNumber) => {
        const deviceState = await getDeviceStatus(serialNumber);
        return { serialNumber, status: deviceState.status };
      });

      const deviceStatuses = await Promise.all(getStatusPromises);
      response.devices = deviceStatuses;
      return response;
    } catch (error) {
      return response;
    }
  }
  return response;
};

export const createParticipant = async (payload: any) => {
  const { pui, trialId, ...metadata } = payload;
  return await post(
    `/cde-service/cde-service/rest/v1/client/trial/${trialId}/members`,
    {
      metadata,
      pui,
    }
  );
};

export const updateParticipant = async (payload: UpdateParticipant) => {
  const { participantId, trialId, ...rest } = payload;
  return await patch(
    `/cde-service/cde-service/rest/v1/client/trial/${trialId}/members/${participantId}`,
    {
      ...rest,
    }
  );
};

export const getParticipant = async (
  trialId: string,
  participantId: string
) => {
  return await get(
    `/cde-service/cde-service/rest/v1/client/trial/${trialId}/members/${participantId}`
  );
};

export const updateTrial = async (payload: UpdateTrialData) => {
  const { name, trialId, description } = payload;
  return await patch(
    `/cde-service/cde-service/rest/v1/client/trial/${trialId}`,
    {
      name,
      description,
    }
  );
};

export const deleteParticipant = async (
  trialId: string,
  participantId: string
) => {
  return await del(
    `/cde-service/cde-service/rest/v1/client/trial/${trialId}/members/${participantId}`
  );
};

export const fetchParticipantsData = async (
  trialId: string,
  searchParams?: Search,
  take: number = 5,
  skip: number = 0
): Promise<Participant[]> => {
  try {
    const apiUrl = new URL(
      `/cde-service/cde-service/rest/v1/client/trial/${trialId}/members`,
      baseURL
    );
    apiUrl.searchParams.set("take", take.toString());
    apiUrl.searchParams.set("skip", skip.toString());
    if (searchParams && Object.keys(searchParams).length) {
      apiUrl.searchParams.set("search", formatSearchParams(searchParams));
    }

    const response = await get(apiUrl.toString());

    const records = response.data.map(async (item: Member) => {
      return {
        cid: item.cid,
        pui: item.pui,
        uuid: item.uuid,
        metadata: item.metadata,
        createdAt: item.createdAt,
        createdBy: item.createdBy,
        updatedAt: item.updatedAt,
        updatedBy: item.updatedBy,
      };
    });
    const participantsData = await Promise.all(records);
    return participantsData;
  } catch (error) {
    throw error;
  }
};

export const fetchParticipantsList = async (
  trialId: string,
  searchParams?: Search,
  take: number = 5,
  skip: number = 0,
  expand?: string,
  order?: string
): Promise<ParticipantData> => {
  try {
    const apiUrl = new URL(
      `/cde-service/cde-service/rest/v1/client/trial/${trialId}/members`,
      baseURL
    );
    if (expand) apiUrl.searchParams.set("expand", expand.toString());
    apiUrl.searchParams.set("take", take.toString());
    apiUrl.searchParams.set("skip", skip.toString());
    if (order) apiUrl.searchParams.set("order", order.toString());
    if (searchParams && Object.keys(searchParams).length) {
      apiUrl.searchParams.set("search", formatSearchParams(searchParams));
    }
    const response = await get(apiUrl.toString());
    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchParticipantById = async (
  trialId: string,
  trialMemberId: string
): Promise<Participant> => {
  try {
    const apiUrl = new URL(
      `/cde-service/cde-service/rest/v1/client/trial/${trialId}/members/${trialMemberId}`,
      baseURL
    );
    const response = await get(apiUrl.toString());
    return response;
  } catch (error) {
    throw error;
  }
};

const formatSearchParams = (searchParams: Search): string => {
  const validSearchparams = Object.entries(searchParams)
    .filter(([key, value]) => {
      if (Array.isArray(value)) {
        return value.length > 0;
      } else {
        return value !== undefined && value.trim() !== "";
      }
    })
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return `${key}|${value.join("-")}`;
      } else {
        return `${key}|${value}`;
      }
    });

  return validSearchparams.join(",");
};

export const updateTrialStatus = async (payload: UpdateTrialStatus) => {
  const { uuid, name, description, preferences } = payload;
  let { status } = payload;

  if (status === TrialStatusActions.ACTIVATE) {
    const response = await getAnnotationAnalyticsByIdentity(uuid);
    status = response.annotations.total
      ? TrialStatus.IN_PROGRESS
      : TrialStatus.PENDING;
  }

  return await post(
    `/cde-service/cde-service/rest/v1/client/trial/${uuid}/status`,
    {
      status,
      name,
      description,
      preferences: preferences === null ? {} : preferences,
    }
  );
};

export interface TrialAnalytics {
  members: {
    total: number;
    members: number;
  };
  devices: {
    total: number;
  };
  annotations: {
    total: number;
    pending: number;
    inProgress: number;
    complete: number;
    terminated: number;
    timeout: number;
  };
}
export interface TrialAnalyticsResponse {
  cid: string;
  uuid: string;
  createdAt: string;
  trial: TrialAnalytics;
}

interface CdeTrialAnalytics {
  trialId: string;
  participants: { total: number };
  devices: { total: number };
}

export const getAnalyticsById = async (
  id: string,
  expandAnnotation: boolean = false
): Promise<TrialAnalyticsResponse> => {
  try {
    const url = expandAnnotation
      ? `/cde-service/cde-service/rest/v1/client/analytics/trials/${id}?expand=ANNOTATIONS`
      : `/cde-service/cde-service/rest/v1/client/analytics/trials/${id}`;
    return get(url);
  } catch (error) {
    throw error;
  }
};

export const getCdeAnalyticsById = async (
  id: string,
  payload: { trialId?: string; from?: string; to?: string }
): Promise<CdeAnalyticsResponse> => {
  try {
    const postParams = {
      cid: id,
      ...(payload.trialId ? { trials: payload.trialId.split(",") } : null),
      ...(payload.from ? { from: payload.from } : null),
      ...(payload.to ? { to: payload.to } : null),
    };
    const url = `/cde-service/cde-service/rest/v1/client/analytics/cdes/${id}`;
    return post(url, postParams);
  } catch (error) {
    throw error;
  }
};

export const userTrailsAnalytics = async (options: {
  trialId?: string;
  cdeId?: string;
}): Promise<Statistics | null> => {
  try {
    if (options?.trialId && !options.cdeId) return null;

    const cdeList = !options?.cdeId
      ? Array.from(
          new Set(
            (await fetchTrialData({ status: [] }, 0, 0)).data.map(
              (trial) => trial.cdeId
            )
          )
        )
      : [options?.cdeId];
    const statsPromises = cdeList.map((cdeId) =>
      getCdeAnalyticsById(cdeId, options)
    );
    const results = await Promise.all(statsPromises);
    const trialAnalytics = results.map(({ trialAnalytics }) => trialAnalytics);
    const accumulatedStatistics = results.reduce(
      (acc: Statistics, { statistics }: CdeAnalyticsResponse, index) => {
        if (!index) {
          Object.keys(statistics).forEach((key) => {
            acc[key as keyof Statistics] = 0;
          });
        }
        Object.keys(statistics).forEach((key) => {
          const statKey = key as keyof Statistics;
          acc[statKey] = (acc[statKey] || 0) + statistics[statKey];
        });
        return acc;
      },
      {} as Statistics
    );

    if (options?.trialId) {
      const CdeTrialAnalytics = trialAnalytics[0].find(
        (trialAnalytic) => trialAnalytic.trialId === options?.trialId
      );
      return {
        ...accumulatedStatistics,
        trialAnalytics: { ...CdeTrialAnalytics },
      };
    }
    return accumulatedStatistics;
  } catch (error) {
    throw error;
  }
};

export const userTrailsMonthlyAnalytics = async (options: {
  trialId?: string;
  cdeId: string;
  from?: string;
  to?: string;
}): Promise<any> => {
  try {
    if (!options.cdeId) return null;
    const { cdeId, ...params } = options;
    return await getCdeAnalyticsById(cdeId, params);
  } catch (error) {
    throw error;
  }
};

export const getMember = async (userId: string): Promise<Member> => {
  try {
    const url = `/cde-service/cde-service/rest/v1/client/members/${userId}`;
    return get(url);
  } catch (error) {
    throw error;
  }
};
