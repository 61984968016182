import { useEffect } from "react";
import Grid from "@mui/material/Grid2";
import {
  useHeaderStore,
  ProgressBarStatus,
  InitProgressBarOptions,
  InitHeaderOptions,
} from "stores/header.store";
import {
  SortOrder,
  containerStyle,
  studiesDashboardRecordPerPage,
  trialStatusConfig,
  graphType,
  Pages,
  PermissionTypes,
} from "constants/index";
import { ROUTES } from "constants/routes.constants";
import { StyledGrid } from "./StudiesDashboard.styles";
import useStudiesDashboardApi from "hooks/useStudiesDashboardApi.hook";
import Loading from "components/Loading";
import Chart from "components/Chart/Chart.component";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import { TrialStatus } from "services/cde.service";
import { CardStatus, getFiltersColor } from "components/Card";
import { useStudiesStore } from "./studies.store";
import { Paginator } from "components/Paginator.component";
import useHomeApi from "pages/TrialsManagement/useHomeApi.hook";
import StudiesList from "./Components/studiesList.component";
import { Typography } from "@mui/material";
import NotFound from "features/trials/components/NotFound";
import QuickNavigation from "components/QuickNavigation/QuickNavigation.component";
import useNavigationOptionsStore from "components/QuickNavigation/quicknavigation.store";
import { statusIcon } from "utils/trial.utils";
import Search from "components/Search.component";
import { useLeftNavigationStore } from "stores/navigation.store";
import { replaceCenterSpace } from "utils/participant.utills";
import { getTitle } from "utils/chart.utils";
import InformationBlock from "components/InformationBlock";
import useSystemStatusStore, { SystemStatus } from "stores/systemCheck.store";
import { ConfigurationService } from "services/configuration.service";
import { CustomError } from "utils/privateHttp.utils";

export function StudiesDashboard() {
  const { t } = useTranslation();
  const initHeader = useHeaderStore((state) => state.initialize);

  const { switchNavigationState } = useLeftNavigationStore((state) => state);

  const setStudiesBarChartData = useStudiesStore(
    (state) => state.setStudiesBarChartData
  );
  const dashboardCdes = useLeftNavigationStore((state) => state.dashboardCdes);
  const cdePermissions = useLeftNavigationStore(
    (state) => state.cdePermissions
  );
  const reset = useStudiesStore((state) => state.reset);
  const barChartData = useStudiesStore((state) => state.studiesBarChartData);
  const systemStatus = useSystemStatusStore((state) => state.status);
  const configurationData = ConfigurationService.configuration;
  const studiesBarChartLayout = useStudiesStore(
    (state) => state.studiesBarChartLayout
  );
  const barChartconfig = useStudiesStore(
    (state) => state.studiesBarChartConfig
  );
  const defaultSelectedFilters = [
    TrialStatus.IN_PROGRESS,
    TrialStatus.PENDING,
    TrialStatus.COMPLETE,
    TrialStatus.TERMINATED,
  ];
  const { navigationOptions, setNavigationOptions } =
    useNavigationOptionsStore();
  const queries = useHomeApi({
    take: studiesDashboardRecordPerPage,
    skip: 0,
    order: `createdAt|${SortOrder.ASC}`,
    status: defaultSelectedFilters,
  });

  useEffect(() => {
    const acceptablePermissions = [
      PermissionTypes.ADMIN,
      PermissionTypes.OWNER,
    ];
    const isAdminOrOwner = dashboardCdes.some((dashboard) =>
      cdePermissions.some(
        (cde) =>
          cde.cdeId === dashboard.cdeId &&
          acceptablePermissions.includes(cde.permission as PermissionTypes)
      )
    );
    if (!isAdminOrOwner) {
      throw new CustomError(
        t("studiesDashboard.forbidden.error.message"),
        "403"
      );
    }
  }, [dashboardCdes]);

  useEffect(() => {
    if (!dashboardCdes.length) return;
    queries.updateOptions({
      ...queries.options,
      cdeIds: dashboardCdes.map((cde) => cde.cdeId),
    });
  }, [dashboardCdes]);

  const theme = useTheme();

  studiesBarChartLayout.shapes.forEach((_: any, index: number) => {
    studiesBarChartLayout.shapes[index].line.color = theme.palette.grey[300];
  });
  const { shapes, ...rest } = studiesBarChartLayout;
  const barChartLayout = {
    ...rest,
    yaxis: {
      showgrid: false,
      fixedrange: true,
      zeroline: false,
      automargin: true,
    },
    xaxis: {
      griddash: "dash",
      zeroline: false,
      fixedrange: true,
      rangemode: "tozero",
    },
    margin: "auto",
    title: {
      text: getTitle(t("studiesDashboard.barchart.heading.label")),
      x: 0.02,
      font: { color: theme.palette.text.primary, size: 24 },
    },
  };

  useEffect(() => {
    reset();
    switchNavigationState("default");
    initHeader(
      {
        mainTitle: {
          text: "progressBar.step.studiesDashboard",
        },
        subTitleItems: [],
        showMenu: false,
        showBackButton: false,
      } as InitHeaderOptions,
      {
        activeStep: 1,
        allowAction: true,
        steps: [
          {
            label: "progressBar.step.trialsManagement",
            status: ProgressBarStatus.SUCCESS,
            link: ROUTES.TRIALS_MANAGEMENT,
            enableLink: true,
          },
          {
            label: "progressBar.step.studiesDashboard",
            status: ProgressBarStatus.IN_PROGRESS,
            link: ROUTES.STUDIES_DASHBOARD,
            enableLink: false,
          },
        ],
      } as InitProgressBarOptions,
      [],
      systemStatus !== SystemStatus.SUCCESS ? false : true
    );
  }, [systemStatus]);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number,
    recordPerPage?: number
  ) => {
    const perPageRecords = recordPerPage || studiesDashboardRecordPerPage;
    queries.updateOptions({
      ...queries.options,
      take: perPageRecords,
      skip: (page - 1) * perPageRecords,
    });
  };

  const handleSorting = (order: string) => {
    queries.updateOptions({
      ...queries.options,
      order,
    });
  };

  const trialsDataLength = queries.query.data?.data?.length || 0;
  const currentPage = queries.options.skip / queries.options.take + 1;
  const totalPages =
    Math.ceil((queries.query.data?.total || 0) / queries.options.take) || 0;

  const trialsCount = queries.dashboardTrialsCount.data?.data || [];

  const statusCount = queries.dashboardTrialsCount.isFetched
    ? trialsCount.reduce((acc: any, trial: any) => {
        const { status } = trial;
        acc[status] = (acc[status] || 0) + 1;
        return acc;
      }, {})
    : {};
  const statusCountList = queries.dashboardTrialsCount.isFetched
    ? Object.entries(statusCount).map(([status, count]) => ({
        status,
        count,
      }))
    : [];

  const CdeIds = dashboardCdes.map((cde) => cde.cdeId);

  const devicesQueries = useStudiesDashboardApi({
    cdeIds: CdeIds,
  });

  const notificationRibbon =
    configurationData.pages[Pages.STUDIES_DASHBOARD].content.notificationRibbon;
  const notificationRibbonKey = notificationRibbon?.split(".").pop();
  const notificationRibbonContent =
    configurationData?.content?.[notificationRibbonKey!];

  useEffect(() => {
    if (!devicesQueries.TrialAnalytics.data) return;
    const participantsMap = new Map(
      devicesQueries.TrialAnalytics.data.map((trial: any) => [
        trial.trialId,
        trial.participants.total,
      ])
    );
    if (!participantsMap.size) return;
    const trialsParticipantsCount = trialsCount.map((trial) => ({
      ...trial,
      participants: participantsMap.get(trial.uuid) || 0,
    }));

    setStudiesBarChartData([
      {
        y: trialsParticipantsCount
          .filter(({ participants }) => participants !== 0)
          .map(({ name }) =>
            name.length > 20 ? replaceCenterSpace(name, "<br>") : name
          ),
        x: trialsParticipantsCount
          .filter((participantCount) => participantCount.participants !== 0)
          .map((trial) => trial.participants),
        type: graphType.BAR,
        orientation: "h",
        marker: {
          color: theme.palette.primary.main,
        },
      },
    ]);
  }, [devicesQueries.TrialAnalytics.isFetched]);

  const handleClick = async (key: TrialStatus) => {
    queries.options.name = "";
    queries.options.status ??= [];
    const isKeySelected = queries.options.status.includes(key);
    queries.options.status = isKeySelected
      ? queries.options.status.filter((status: any) => status !== key)
      : [...queries.options.status, key];
    const updatedOptions = navigationOptions.map((option) => ({
      ...option,
      defaultSelected:
        option.key === key ? !isKeySelected : option.defaultSelected,
    }));
    setNavigationOptions(updatedOptions);
    const options = {
      ...queries.options,
      skip: 0,
    };
    queries.updateOptions(options);
  };

  const handleSearch = async (params: any) => {
    const options = {
      ...{
        take: queries.options.take,
        skip: queries.options.skip,
        cdeIds: queries.options.cdeIds,
      },
      ...{ name: params.search, status: defaultSelectedFilters },
      skip: 0,
    };
    queries.refreshQuery([options]);
    const updatedOptions = navigationOptions.map((option) => ({
      ...option,
      defaultSelected: option.totalCount ? true : false,
    }));
    setNavigationOptions(updatedOptions);
    queries.updateOptions(options);
  };

  useEffect(() => {
    if (!dashboardCdes.length) return;
    const navigationOptionsObject = Object.entries(trialStatusConfig).map(
      ([value, data]) => {
        const { status, count } = statusCountList.find(
          (trial) => trial.status === value
        ) || { status: value, count: 0 };
        trialStatusConfig[status].count = count as number;

        const adjustedValue =
          value === TrialStatus.COMPLETE
            ? CardStatus.SUCCESS
            : value === TrialStatus.TERMINATED
            ? CardStatus.FAIL
            : value === TrialStatus.IN_PROGRESS
            ? CardStatus.WARNING
            : value;

        return {
          key: value as TrialStatus,
          icon: statusIcon[value as TrialStatus] as React.ElementType,
          value: (data as { text: string }).text,
          color: getFiltersColor(adjustedValue as CardStatus, theme),
          totalCount: count as number,
          defaultSelected: count
            ? queries.options.status?.length
              ? queries.options.status.includes(value as TrialStatus)
              : data.defaultSelected
            : false,
          disabled: false,
        };
      }
    );
    setNavigationOptions(navigationOptionsObject);
  }, [queries.dashboardTrialsCount.isFetched]);
  if (!dashboardCdes.length) return;
  return (
    <>
      <Grid container sx={containerStyle} gap={"1rem"} flexDirection={"column"}>
        <Grid flexGrow={1}>
          {notificationRibbonContent &&
            systemStatus !== SystemStatus.SUCCESS && (
              <InformationBlock
                status={systemStatus as any}
                configurations={notificationRibbonContent}
                sx={{
                  padding: "3rem",
                }}
              />
            )}
        </Grid>
        <Grid flexGrow={1}>
          <Grid container gap={3}>
            <StyledGrid flexGrow={1} p={1}>
              {barChartData.length ? (
                <Typography component="div" sx={{ width: "100%" }}>
                  <Chart
                    data={barChartData}
                    layout={{
                      ...barChartLayout,
                      bargap: barChartData[0].y
                        ? 0.85 / Math.sqrt(barChartData[0].y.length)
                        : 0.3,
                      font: { family: theme.typography.fontFamily },
                      hoverlabel: {
                        font: { family: theme.typography.fontFamily },
                      },
                    }}
                    config={barChartconfig}
                  />
                </Typography>
              ) : (
                <Loading sx={{ height: "inherit" }} />
              )}
            </StyledGrid>
          </Grid>
        </Grid>
        <Grid flexGrow={1}>
          <Grid
            container
            display="flex"
            gap={2}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {navigationOptions.map((option) => (
              <Grid key={option.key}>
                <QuickNavigation
                  disabled={option.disabled}
                  text={option.value}
                  icon={option.icon}
                  color={option.color}
                  count={option.totalCount}
                  defaultSelected={option.defaultSelected}
                  onClick={() =>
                    option.totalCount ? handleClick(option.key) : null
                  }
                />
              </Grid>
            ))}
            <Grid flexGrow={1}>
              <Search
                placeholder={t("studiesDashboard.search.placeholder.text")}
                onSearch={handleSearch}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          flexGrow={1}
          sx={{ width: "100%", fontFamily: theme.typography.fontFamily }}
        >
          {queries.query.isFetched ? (
            trialsDataLength ? (
              <>
                <StudiesList queries={queries} handleSorting={handleSorting} />
                <Paginator
                  showRecordsPerPage={true}
                  recordPerPage={queries.options.take}
                  total={totalPages}
                  page={currentPage}
                  show={true}
                  onChange={handlePageChange}
                />
              </>
            ) : (
              <NotFound
                message={t("studiesDashboard.trialsSection.notFound.message")}
                height="10rem"
              />
            )
          ) : (
            <Loading height="40vh" />
          )}
        </Grid>
      </Grid>
    </>
  );
}
