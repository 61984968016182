import { CardStatus } from "components/Card";
import { useEffect, useState } from "react";

import { usePurgeStore } from "../purge.store";
import { TimerDirection } from "constants/index";

interface TimerControllerProps {
  actions: any;
  loading: boolean;
}

export const TimerController: React.FC<TimerControllerProps> = ({
  actions,
  loading,
}) => {
  const setTimeSinceEvent = usePurgeStore((state) => state.setTimeSinceEvent);
  const timeSinceEvent = usePurgeStore((state) => state.timeSinceEvent);
  const currentEvent = usePurgeStore((state) => state.currentEvent);
  const validation = usePurgeStore((state) => state.validation);
  const purgeEvent = usePurgeStore((state) => state.purgeEvent);
  const [asyncFunctionInProgress, setAsyncFunctionInProgress] =
    useState<boolean>(false);

  const setPurgeEventStatus = usePurgeStore(
    (state) => state.setPurgeEventStatus
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeSinceEvent();
    }, 500);
    if (purgeEvent?.status === CardStatus.FAIL) {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, []);

  const getTimer = (status: CardStatus) => {
    if (!currentEvent) return {};
    if (!currentEvent.timer) return {};
    const timerConfig = currentEvent.timer.actions.reduce(
      (acc: any, action: any) => {
        if (action?.statuses?.includes(status)) {
          acc = {
            actions: action.actions,
            time: action.time,
            direction: currentEvent.timer.direction,
          };
        }
        return acc;
      },
      {}
    );
    return timerConfig;
  };

  useEffect(() => {
    if (!currentEvent) return;
    if (timeSinceEvent > currentEvent.timer.timeout) return;
    const checkRules = validation.verdict;
    const status = checkRules ? CardStatus.SUCCESS : CardStatus.FAIL;

    const timer = getTimer(status);
    if (!timer) return;

    const executeAsyncFunction = async () => {
      setAsyncFunctionInProgress(true);
      checkRules
        ? setPurgeEventStatus(CardStatus.SUCCESS)
        : setPurgeEventStatus(CardStatus.WARNING);

      if (currentEvent && timer?.actions) {
        for (const action of timer.actions) {
          typeof action === "object"
            ? await actions[action.name](status, action.params)
            : await actions[action](status);
        }
      }
      setAsyncFunctionInProgress(false);
    };

    if (!currentEvent) return;
    if (!validation.verdict) {
      if (!asyncFunctionInProgress) {
        executeAsyncFunction();
      }
      return;
    }

    if (!currentEvent.validate) return;
    if (timeSinceEvent > currentEvent.validate.settlingTime) {
      if (!asyncFunctionInProgress) {
        executeAsyncFunction();
      }
      return;
    }

    if (
      timeSinceEvent >= Number(timer.time) &&
      validation.verdict &&
      timer.direction === TimerDirection.DOWN
    ) {
      if (!asyncFunctionInProgress) {
        executeAsyncFunction();
      }
      return;
    }
  }, [timeSinceEvent]);

  return <></>;
};
