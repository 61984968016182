import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

const FALL_BACK_LANGUAGE = "en";

interface Translations {
  [key: string]: {
    [key: string]: string;
  };
}

class Service {
  private _language: string =
    process.env.REACT_APP_FALLBACK_LANGUAGE || FALL_BACK_LANGUAGE;

  async init(translations: Translations, language: string = this.language) {
    const resources = Object.keys(translations).reduce(
      (acc: any, lang: string) => {
        acc[lang] = { translation: translations[lang] };
        return acc;
      },
      {}
    );

    await i18n
      .use(Backend)
      .use(initReactI18next)
      .init({
        resources,
        fallbackLng:
          process.env.REACT_APP_FALLBACK_LANGUAGE || FALL_BACK_LANGUAGE,
        lng: language,
        debug: false,
        interpolation: {
          escapeValue: false,
        },
      });
  }

  get language() {
    return this._language;
  }

  async changeLanguage(language: string) {
    this._language = language;
    return i18n.changeLanguage(language);
  }

  async updateTranslationFromAnnotationConfig(locales: Translations) {
    if (locales) {
      await this.updateTranslations(locales);
    }
  }

  async updateTranslations(newBundle: Translations) {
    for (const language of Object.keys(newBundle)) {
      i18n.addResourceBundle(language, "translation", {
        ...newBundle[language],
      });
    }
  }
}

export const TranslationService = new Service();
