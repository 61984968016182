import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

import moment, { Moment } from "moment";

import {
  AnnotationConfigurationData,
  AnnotationEvent,
  ContentConfiguration,
  EventValidate,
  IndicatorValidation,
} from "services/annotation.service";

import { Participant } from "services/cde.service";
import {
  validateRules,
  checkRules,
  checkSignalRules,
} from "utils/device.utils";
import { CardStatus } from "components/Card";
import { timeFormat } from "constants/index";

export interface ResultsCheck {
  [key: string]: boolean;
}

export interface ResultTimerActions {
  time: number;
  actions: string[];
  statuses: CardStatus[];
}

export interface ResultTimer {
  direction: string;
  actions: ResultTimerActions[];
}

export interface ValidationResult {
  name: string;
  title: string;
  background: string;
  settlingTime: number;
  timeout: number;
  timer: ResultTimer;
  verdict: boolean;
  results?: any;
}
export interface Validation {
  verdict: boolean;
  results?: ValidationResult[];
}

interface ResultStore {
  loading: boolean;
  setLoading: (loading: boolean) => void;
  serialNumbers: string[];
  setSerialNumbers: (serialNumbers: string[]) => void;
  eventsConfiguration: AnnotationEvent[];
  currentEvent: AnnotationEvent | undefined;
  nextEvent: AnnotationEvent | undefined;
  alignEvents: () => void;
  events: any[];
  device: any;
  setEvent: (event: any) => void;
  updateEvent: (name: string, status: CardStatus, validation?: any) => void;
  annotationConfiguration?: AnnotationConfigurationData | undefined;
  resultsCheck: ResultsCheck;
  validation: Validation;
  validate: () => void;
  participant: Participant | undefined;
  setParticipant: (participant: Participant) => void;
  annotation: any;
  setAnnotation: (annotation: any) => void;
  ingest: any;
  setIngest: (ingest: any) => void;
  initializedAt: Moment;
  timeSinceEvent: number; // in seconds
  timerStatus: boolean;
  setTimeSinceEvent: () => void;
  startTimer: () => void;
  stopTimer: () => void;
  reset: () => void;
  informationBlockContent: ContentConfiguration | undefined;
  setInformationBlockContent: (
    informationBlockContent: ContentConfiguration
  ) => void;
  validateHeading: (metric: any) => void;
  headingValidation: any;
}

const init = {
  loading: true,
  serialNumbers: [],
  eventsConfiguration: [],
  currentEvent: undefined,
  nextEvent: undefined,
  events: [],
  device: undefined,
  annotationConfiguration: undefined,
  resultsCheck: {},
  validation: { verdict: false },
  experimentFrom: undefined,
  participant: undefined,
  annotation: undefined,
  ingest: undefined,
  timeSinceEvent: 0,
  initializedAt: moment(),
  timerStatus: true,
  headingValidation: {},
};

const getNextEvent = (events: any, currentEvent: any = undefined) => {
  if (!events) return undefined;
  if (!currentEvent) {
    const _currentEvent = events.find((event: any) => event.order === 0);
    if (!_currentEvent) return currentEvent;
    return {
      ..._currentEvent,
      timeout: Number(_currentEvent.timer?.timeout) || 0,
    };
  }
  if (currentEvent.allowedEvents.length === 0) return undefined;
  const nextEvent = events.find(
    (item: any) =>
      currentEvent.allowedEvents[0].toLowerCase() === item.name.toLowerCase()
  );
  return {
    ...nextEvent,
    timeout: Number(nextEvent.timer?.timeout) || 0,
  };
};

export const useResultStore = create<ResultStore>()(
  persist(
    (set) => ({
      ...init,
      setLoading: (loading: boolean) => set({ loading }),
      setSerialNumbers: (serialNumbers: string[]) => set({ serialNumbers }),
      alignEvents: () =>
        set(({ eventsConfiguration, currentEvent, nextEvent }) => {
          if (currentEvent?.allowedEvents.length === 0)
            return {
              currentEvent,
              nextEvent: undefined,
            };
          const newCurrentEvent = getNextEvent(
            eventsConfiguration,
            currentEvent
          );
          const newNextEvent = getNextEvent(
            eventsConfiguration,
            newCurrentEvent
          );
          return { currentEvent: newCurrentEvent, nextEvent: newNextEvent };
        }),
      setEvent: (event: any) =>
        set(({ events }) => {
          const utcTime = moment
            .utc()
            .format(timeFormat.UTC) as unknown as Moment;
          const newEvents = [...events, { ...event, createdAt: utcTime }];
          return { events: newEvents };
        }),
      updateEvent: (name: string, status: CardStatus) =>
        set(({ events }) => {
          const newEvents = events.map((event: any) => {
            if (event.name === name) {
              return {
                ...event,
                status,
              };
            }
            return event;
          });
          return { events: newEvents };
        }),
      validate: () =>
        set(({ currentEvent, ingest, events, annotationConfiguration }) => {
          if (!currentEvent) return { events };
          if (!currentEvent.validate) return { events };
          if (!annotationConfiguration) return { events };
          const { result } = annotationConfiguration;
          const resultsCheck = checkRules(result.rules, ingest.result);
          const validation = validateRules(currentEvent.validate, resultsCheck);
          const _events = events.map((event: any) => {
            if (event.name === currentEvent.name) {
              return {
                ...event,
                resultsCheck: ingest.result,
                validation,
              };
            }
            return event;
          });
          return { events: _events };
        }),
      validateHeading: (metric: any) =>
        set(({ currentEvent, annotationConfiguration }) => {
          if (!currentEvent?.heading?.validate?.rules) return {};

          const {
            validate: { rules: headingRules },
          } = currentEvent.heading;
          const rules = headingRules.flatMap((item) => item.rules || []);

          if (!rules.length) return {};
          const signalStrength = checkSignalRules(rules, metric);
          const metricRules = headingRules.filter((item) => !item.rules);

          const headingValidation: IndicatorValidation = {
            signalStrength,
          };
          if (metricRules.length && annotationConfiguration?.device?.rules) {
            const { rules: deviceRules } = annotationConfiguration.device;
            const resultsCheck = checkRules(deviceRules, metric);
            const validation = validateRules(
              {
                ...currentEvent.heading.validate,
                rules: metricRules,
              } as EventValidate,
              resultsCheck
            );
            headingValidation.deviceValidation = validation;
          }

          return { headingValidation };
        }),
      setParticipant: (participant: Participant) => set({ participant }),
      setAnnotation: (annotation: any) =>
        set(() => {
          const { configuration } = annotation;
          const eventsConfiguration = configuration
            ? configuration.data.result?.events
            : [];
          const currentEvent = getNextEvent(eventsConfiguration);
          const nextEvent = getNextEvent(eventsConfiguration, currentEvent);
          return {
            annotation,
            annotationConfiguration: configuration.data,
            eventsConfiguration,
            currentEvent,
            nextEvent,
          };
        }),
      setIngest: (ingest: any) => set({ ingest }),
      setTimeSinceEvent: () =>
        set(({ events, initializedAt }) => {
          let createdAt = initializedAt;
          if (events.length) {
            createdAt = events[events.length - 1].createdAt;
          }
          const now = moment();
          const then = moment(createdAt);
          const diff = moment.duration(now.diff(then));
          const seconds = diff.asSeconds();
          return { timeSinceEvent: seconds };
        }),
      startTimer: () => set({ timerStatus: true }),
      stopTimer: () => set({ timerStatus: false }),
      reset: () => set({ ...init }),
      informationBlockContent: undefined,
      setInformationBlockContent: (informationBlockContent) =>
        set(() => ({ informationBlockContent })),
    }),
    {
      name: "result-store",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);
