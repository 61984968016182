import { Grid, Typography } from "@mui/material";
import { getAssetsPath } from "utils/assets.utils";

interface DrawerHeaderProps {
  title: string;
  headerWidth?: number;
  onClose: () => void;
}

const DrawerHeader: React.FC<DrawerHeaderProps> = ({
  onClose,
  headerWidth,
  title,
}) => {
  return (
    <Grid container display={"flex"} sx={{ margin: "1rem" }}>
      <Grid item xs={11.2} md={12} sm={11.3}>
        <Grid
          container
          display={"flex"}
          justifyContent={"left"}
          alignItems={"left"}
          flexDirection={"column"}
        >
          <Grid item>
            <img
              style={{ width: "3rem" }}
              onClick={onClose}
              alt=""
              src={getAssetsPath("icons/back.svg")}
            />
          </Grid>
          <Grid item sx={{ padding: "0.5rem" }}>
            <Typography
              sx={{
                fontSize: "2rem",
                fontWeight: "600",
              }}
            >
              {title}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DrawerHeader;
