import React, { ReactNode } from "react";
import { useEffect, useState } from "react";
import Loading from "components/Loading";
import { AuthService } from "services/auth.service";
import ErrorFallback from "components/errors/ErrorFallback";
import { useLeftNavigationStore } from "stores/navigation.store";
import { getMember } from "services/cde.service";
import { PermissionTypes } from "constants/index";

interface AuthenticationProviderProps {
  children: ReactNode;
}

export const AuthenticationProvider: React.FC<AuthenticationProviderProps> = ({
  children,
}) => {
  const [error, setError] = useState<any>();
  const [initialized, setInitialized] = useState(false);
  const setDashboardCdes = useLeftNavigationStore(
    (state) => state.setDashboardCdes
  );
  const dashboardPermissions = useLeftNavigationStore(
    (state) => state.dashboardPermissions
  );

  const setCdePermissions = useLeftNavigationStore(
    (state) => state.setCdePermissions
  );

  useEffect(() => {
    const initializeKeycloak = async () => {
      try {
        if (initialized) return;
        const authenticated = await AuthService.init();
        const getUserProfile = await AuthService.getUserProfile();

        if (!authenticated) {
          await AuthService.login();
        }

        if (getUserProfile) {
          const member = await getMember(getUserProfile.id);
          setCdePermissions(member.CDEpermissions ?? []);
          const cdesList = await Promise.all(
            member.CDEpermissions
              ? member?.CDEpermissions.filter((permission) =>
                  dashboardPermissions.includes(
                    permission.permission as PermissionTypes
                  )
                )
              : []
          );
          setDashboardCdes(cdesList);
        }
        setInitialized(true);
        
      } catch (err) {
        const error = new Error("error.authentication", { cause: err });
        setError(error);
      }
    };
    initializeKeycloak();
  }, [initialized]);

  if (error) {
    <ErrorFallback resetErrorBoundary={(): void => {}} error={error} />;
  }

  if (!initialized) {
    return <Loading />;
  }

  return <>{children}</>;
};
