import React from "react";
import Typography from "@mui/material/Typography";
import { useIsBetween768And1400 } from "hooks/media.hook";

interface StudyGraphHeadingProps {
  heading: string;
}

const StudyGraphHeading: React.FC<StudyGraphHeadingProps> = ({ heading }) => {
  const isBetween768And1400 = useIsBetween768And1400();

  return (
    <Typography
      component="div"
      sx={{
        fontSize: isBetween768And1400 ? "1.5rem" : "2rem",
        padding: "1rem 0 0 1rem",
      }}
    >
      {heading}
    </Typography>
  );
};

export default StudyGraphHeading;
