import React, { useEffect } from "react";
import Grid from "@mui/material/Grid2";
import { useTranslation } from "react-i18next";

import { TrialStatus } from "services/cde.service";

import Search from "features/trials/components/Search";
import TrialCard from "features/trials/TrialCards/TrialCard";
import { statusIcon } from "utils/trial.utils";
import { CardStatus, getFiltersColor } from "components/Card/Card.component";
import SkeletonTrialCardContainer from "components/SkeletonTrialCardContainer";
import NotFound from "features/trials/components/NotFound";
import { Paginator } from "components/Paginator.component";
import {
  useHeaderStore,
  ProgressBarStatus,
  InitProgressBarOptions,
  InitHeaderOptions,
} from "stores/header.store";
import {
  containerStyle,
  itemsPerPage,
  trialStatusConfig,
  TroubleshootGuideTypes,
  Pages,
} from "constants/index";
import useHomeApi from "./useHomeApi.hook";
import { ROUTES } from "constants/routes.constants";
import QuickNavigation from "components/QuickNavigation/QuickNavigation.component";
import useNavigationOptionsStore from "components/QuickNavigation/quicknavigation.store";
import { useTheme } from "@mui/system";
import { useResultStore } from "pages/Result/result.store";
import useGuideStore from "components/TroubleShootGuide/guide.store";
import { useLeftNavigationStore } from "stores/navigation.store";
import InformationBlock from "components/InformationBlock";
import useSystemStatusStore, { SystemStatus } from "stores/systemCheck.store";
import { ConfigurationService } from "services/configuration.service";

export function TrialsManagement() {
  const { t } = useTranslation();
  const initHeader = useHeaderStore((state) => state.initialize);
  const reset = useResultStore((state) => state.reset);
  const { switchNavigationState } = useLeftNavigationStore((state) => state);
  const setCurrentGuideId = useGuideStore((state) => state.setCurrentGuideId);
  const systemStatus = useSystemStatusStore((state) => state.status);
  const theme = useTheme();
  const defaultSelectedFilters = [TrialStatus.IN_PROGRESS, TrialStatus.PENDING];
  const configurationData = ConfigurationService.configuration;
  const queries = useHomeApi({
    take: itemsPerPage,
    skip: 0,
    status: defaultSelectedFilters,
  });
  const { navigationOptions, setNavigationOptions } =
    useNavigationOptionsStore();
  useEffect(() => {
    switchNavigationState("default");
    setCurrentGuideId(TroubleshootGuideTypes.TRIALMANAGEMENT);
    initHeader(
      {
        mainTitle: {
          text: "header.title.text.trialsManagement",
        },
        subTitleItems: [],
        showMenu: false,
        showBackButton: false,
      } as InitHeaderOptions,
      {
        activeStep: 0,
        allowAction: false,
        steps: [
          {
            label: "progressBar.step.trialsManagement",
            status: ProgressBarStatus.IN_PROGRESS,
            link: ROUTES.TRIALS_MANAGEMENT,
            enableLink: false,
          },
          {
            label: "progressBar.step.trialParticipants",
            status: ProgressBarStatus.PENDING,
            link: ROUTES.TRIAL_PARTICIPANTS,
            enableLink: false,
          },
          {
            label: "progressBar.step.trialParticipantDevices",
            status: ProgressBarStatus.PENDING,
            link: ROUTES.TRIAL_PARTICIPANT_DEVICES,
            enableLink: false,
          },
          {
            label: "progressBar.step.trialParticipantExperiment",
            status: ProgressBarStatus.PENDING,
            link: ROUTES.TRIAL_PARTICIPANT_EXPERIMENT,
            enableLink: false,
          },
          {
            label: "progressBar.step.trialParticipantExperimentResults",
            status: ProgressBarStatus.PENDING,
            link: ROUTES.TRIAL_PARTICIPANT_EXPERIMENT_RESULTS,
            enableLink: false,
          },
        ],
      } as InitProgressBarOptions,
      [],
      systemStatus !== SystemStatus.SUCCESS ? false : true
    );
    reset();
  }, [systemStatus]);

  const handleSearch = async (params: any) => {
    const status = params.name
      ? [
          TrialStatus.IN_PROGRESS,
          TrialStatus.PENDING,
          TrialStatus.TERMINATED,
          TrialStatus.COMPLETE,
        ]
      : defaultSelectedFilters;

    const options = {
      ...queries.options,
      ...{
        ...params,
        status,
      },
      skip: 0,
    };
    const updatedOptions = navigationOptions.map((option) => ({
      ...option,
      defaultSelected: option.totalCount
        ? defaultSelectedFilters.includes(option.key)
          ? true
          : false
        : false,
    }));

    setNavigationOptions(updatedOptions);
    queries.refreshQuery([options]);
    queries.updateOptions(options);
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    queries.updateOptions({
      ...queries.options,
      skip: (page - 1) * itemsPerPage,
    });
  };

  const trialsCount = queries.trialsCount.data?.data || [];

  const statusCount = queries.trialsCount.isFetched
    ? trialsCount.reduce((acc: any, trial: any) => {
        const { status } = trial;
        acc[status] = (acc[status] || 0) + 1;
        return acc;
      }, {})
    : {};

  const statusCountList = queries.trialsCount.isFetched
    ? Object.entries(statusCount).map(([status, count]) => ({ status, count }))
    : [];

  const trialsDataLength = queries.query.data?.data?.length || 0;
  const currentPage = queries.options.skip / itemsPerPage + 1;
  const totalPages =
    Math.ceil((queries.query.data?.total || 0) / itemsPerPage) || 0;

  const handleClick = async (key: TrialStatus) => {
    if (queries.options.status) {
      queries.options.name = "";
      const isKeySelected = queries.options.status.includes(key);
      queries.options.status = isKeySelected
        ? queries.options.status.filter((status: any) => status !== key)
        : [...queries.options.status, key];
      const updatedOptions = navigationOptions.map((option) => ({
        ...option,
        defaultSelected:
          option.key === key ? !isKeySelected : option.defaultSelected,
      }));

      setNavigationOptions(updatedOptions);
      const options = {
        ...queries.options,
        skip: 0,
      };
      queries.updateOptions(options);
    }
  };
  useEffect(() => {
    const navigationOptionsObject = Object.entries(trialStatusConfig).map(
      ([value, data]) => {
        const { status, count } = statusCountList.find(
          (trial) => trial.status === value
        ) || { status: value, count: 0 };
        trialStatusConfig[status].count = count as number;

        const adjustedValue =
          value === TrialStatus.COMPLETE
            ? CardStatus.SUCCESS
            : value === TrialStatus.TERMINATED
            ? CardStatus.FAIL
            : value === TrialStatus.IN_PROGRESS
            ? CardStatus.WARNING
            : value;

        return {
          key: value as TrialStatus,
          icon: statusIcon[value as TrialStatus] as React.ElementType,
          value: (data as { text: string }).text,
          color: getFiltersColor(adjustedValue as CardStatus, theme),
          totalCount: count as number,
          defaultSelected: count
            ? queries.options.status?.length
              ? queries.options.status.includes(value as TrialStatus)
              : data.defaultSelected
            : false,
        };
      }
    );
    setNavigationOptions(navigationOptionsObject);
  }, [queries.trialsCount.isFetched]);

  const notificationRibbon =
    configurationData.pages[Pages.STUDIES].content.notificationRibbon;
  const notificationRibbonKey = notificationRibbon?.split(".").pop();
  const notificationRibbonContent =
    configurationData?.content?.[notificationRibbonKey!];

  return (
    <>
      <Grid container sx={containerStyle} flexDirection={"column"} columns={12}>
        <Grid flexGrow={1}>
          {notificationRibbonContent &&
            systemStatus !== SystemStatus.SUCCESS && (
              <InformationBlock
                status={systemStatus as any}
                configurations={notificationRibbonContent}
                sx={{
                  padding: "3rem"
                }}
              />
            )}
        </Grid>
        <Grid flexGrow={1}>
          <Search onSearch={handleSearch} />
          <Grid container display="flex" gap={2} columns={12} spacing={0}>
            {navigationOptions.map((option) => (
              <Grid key={option.key}>
                <QuickNavigation
                  text={option.value}
                  icon={option.icon}
                  color={option.color}
                  count={option.totalCount}
                  defaultSelected={option.defaultSelected}
                  onClick={() =>
                    option.totalCount ? handleClick(option.key) : null
                  }
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid flexGrow={1}>
          <Paginator
            total={totalPages}
            page={currentPage}
            show={totalPages > 1}
            onChange={handlePageChange}
          />
        </Grid>
        <Grid flexGrow={1}>
          <>
            <Grid container spacing={2}>
              {!queries.query.isFetched ? (
                <SkeletonTrialCardContainer />
              ) : trialsDataLength ? (
                queries.query.data?.data?.map((trial: any) => (
                  <TrialCard key={trial.uuid} trial={trial} />
                ))
              ) : (
                <NotFound
                  message={t("home.trialsSection.notFound.message")}
                  height="14rem"
                />
              )}
            </Grid>
          </>
        </Grid>
        <Grid flexGrow={1}>
          <Paginator
            total={totalPages}
            page={currentPage}
            show={totalPages > 1}
            onChange={handlePageChange}
          />
        </Grid>

        {queries.query.isFetched && queries.query.error && <NotFound />}
      </Grid>
    </>
  );
}
