import React, { useEffect, useState } from "react";
import {
  useParams,
  useSearchParams,
  useNavigate,
  BlockerFunction,
} from "react-router-dom";

import Grid from "@mui/material/Grid2";
import { useTheme } from "@mui/system";

import { Participant, TrialStatus } from "services/cde.service";

import useTrialApi from "hooks/useTrialApi.hook";
import useAnnotationApi from "hooks/useAnnotationApi.hook";

import { useLeftNavigationStore } from "stores/navigation.store";
import {
  ProgressBarStatus,
  useHeaderStore,
  InitProgressBarOptions,
  InitHeaderOptions,
} from "stores/header.store";
import { ROUTES } from "constants/routes.constants";
import {
  iconsLink,
  containerStyle,
  AnnotationEventType,
  TroubleshootGuideTypes,
  ExperimentDeviceStatusQueryTimeout,
  timeFormat,
} from "constants/index";
import { buildLocalUrl } from "utils/url.utils";

import { CardStatus } from "components/Card";

import {
  AnnotationStatus,
  CreateAnnotationEvent,
} from "services/annotation.service";
import { ExperimentTimerController } from "./Components/ExperimentTimer.controller";

import { useExperimentStore } from "../../stores/experiment.store";
import Loading from "components/Loading";
import { MetricsContainer } from "./Components/MetricsContainer.component";
import TimerButtonContainer from "./Components/TimerButtonContainer.controller";
import { Progress } from "./Components/Progress.component";
import DialogBox, {
  ButtonTypes,
} from "components/DialogBox/DialogBox.component";
import { useDialogBoxStore } from "components/DialogBox/useDialogBoxStore";
import useCustomBlocker from "hooks/useCustomBlocker.hook";
import useParticipantDashboardApi from "pages/ParticipantDashboard/participant.store";
import useDevicesApi from "hooks/useDevicesApi.hook";
import { CreateRpcEventParams, rpcType } from "services/fleet.service";
import { getAdjustedTime } from "services/time.services";
import InformationBlock from "components/InformationBlock";
import useGuideStore from "components/TroubleShootGuide/guide.store";
import useDeviceApi from "hooks/useDeviceApi.hook";
import useTrialStore from "stores/trial.store";
import useSystemStatusStore from "stores/systemCheck.store";

const Experiment: React.FC = () => {
  const { trialId, trialMemberId } = useParams();
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const serialNumbers = searchParams.get("serial-numbers")?.split(",");
  const annotationId = searchParams.get("annotationId");
  const setTrialData = useTrialStore((state) => state.setTrialData);
  const getTrialName = useTrialStore((state) => state.getTrialName());
  const getTrialConfiguration = useTrialStore((state) =>
    state.getTrialConfiguration()
  );
  const navigate = useNavigate();
  const trialQueries = useTrialApi({
    trialId: trialId || "",
    participants: false,
  });
  const participantQueries = useParticipantDashboardApi({
    participantId: trialMemberId || "",
    trialId: trialId || "",
    fetchAnnotations: false,
  });
  const deviceQueries = useDeviceApi({
    serialNumber: serialNumbers?.[0] || "",
    refetchInterval: ExperimentDeviceStatusQueryTimeout,
  });
  const { initialize: initHeader } = useHeaderStore((state) => state);
  const [isBlocking, setIsBlocking] = useState(false);
  const setSerialNumbers = useExperimentStore(
    (state) => state.setSerialNumbers
  );
  const annotationConfiguration = useExperimentStore(
    (state) => state.annotationConfiguration
  );
  const setAnnotationConfiguration = useExperimentStore(
    (state) => state.setAnnotationConfiguration
  );
  const setConfigurationEvents = useExperimentStore(
    (state) => state.setConfigurationEvents
  );
  const setDevice = useExperimentStore((state) => state.setDevice);
  const resetExperimentStore = useExperimentStore((state) => state.reset);
  const currentEvent = useExperimentStore((state) => state.currentEvent);
  const nextEvent = useExperimentStore((state) => state.nextEvent);
  const addAnnotationEvent = useExperimentStore(
    (state) => state.addAnnotationEvent
  );
  const setParticipant = useExperimentStore((state) => state.setParticipant);
  const alignEvents = useExperimentStore((state) => state.alignEvents);
  const setTrial = useExperimentStore((state) => state.setTrial);
  const participant = useExperimentStore((state) => state.participant);
  const experimentFrom = useExperimentStore((state) => state.experimentFrom);
  const annotation = useExperimentStore((state) => state.annotation);
  const setAnnotation = useExperimentStore((state) => state.setAnnotation);
  const startTimer = useExperimentStore((state) => state.startTimer);
  const setLoading = useExperimentStore((state) => state.setLoading);
  const elapsedTime = useSystemStatusStore((state) => state.elapsedTime);
  const { validate, validateHeading, headingValidation } = useExperimentStore(
    (state) => ({
      validate: state.validate,
      validateHeading: state.validateHeading,
      headingValidation: state.headingValidation,
    })
  );

  const setInformationBlockContent = useExperimentStore(
    (state) => state.setInformationBlockContent
  );
  const informationBlockContent = useExperimentStore(
    (state) => state.informationBlockContent
  );
  const failedStep = useExperimentStore((state) => state.failedStep);
  const validation = useExperimentStore((state) => state.validation);
  const updateCurrentEventAction = useExperimentStore(
    (state) => state.updateCurrentEventAction
  );
  const setCurrentGuideId = useGuideStore((state) => state.setCurrentGuideId);
  const annotationEvents = useExperimentStore(
    (state) => state.annotationEvents
  );
  const { addNavigationState, switchNavigationState } = useLeftNavigationStore(
    (state) => state
  );
  const setAllowLogoAction = useLeftNavigationStore(
    (state) => state.setAllowLogoAction
  );
  const openDialogBox = useDialogBoxStore((state) => state.open);
  const closeDialogBox = useDialogBoxStore((state) => state.close);

  const annotationQuery = useAnnotationApi({
    annotationId: annotationId || "",
  });

  const devicesQueries = useDevicesApi({
    serialNumbers: [],
  });

  enum validateComponent {
    MetricsContainer = "MetricsContainer",
    MeasurementCards = "MeasurementCards",
  }

  enum RedirectTo {
    DEVICES = "devices",
    EXPERIMENT = "experiments",
    STUDIES_MANAGEMENT = "studies-management",
  }

  useEffect(() => {
    return () => {
      switchNavigationState("default");
      setAllowLogoAction(true);
    };
  }, []);

  useEffect(() => {
    setTrialData(trialQueries.trial.data || {});
  }, [trialQueries.trial.isFetched]);

  useEffect(() => {
    if (!experimentFrom) {
      const redirectUrl = buildLocalUrl({
        path: ROUTES.TRIALS_MANAGEMENT,
      });
      handleRedirect(redirectUrl);
    }
  }, []);

  useEffect(() => {
    if (!currentEvent) return;
    setCurrentGuideId(
      currentEvent?.name.toLowerCase() === TroubleshootGuideTypes.RECOVERY
        ? TroubleshootGuideTypes.RECOVERY
        : TroubleshootGuideTypes.EXPERIMENT
    );
    setInformationBlockContent(currentEvent?.content);
  }, [currentEvent]);

  useEffect(() => {
    if (!currentEvent) return;
    if (!deviceQueries) return;
    if (!currentEvent?.heading) return;
    if (!deviceQueries.device.data?.state.metrics) return;
    const metrics = deviceQueries.device.data.state.metrics;
    validateHeading(metrics);
  }, [deviceQueries.device.data, validate, currentEvent]);

  useEffect(() => {
    setAllowLogoAction(false);
    if (!trialQueries.trial.isFetched) return;
    if (annotation) {
      const defaultTerminateButton = {
        label: "experiments.experimentController.dialog.terminate.action.label",
        action: "terminateAnnotationAndRedirectToPurge",
        type: ButtonTypes.PRIMARY,
      };

      const terminateButton = currentEvent?.terminateAction
        ? {
            label:
              currentEvent.terminateAction.label ||
              defaultTerminateButton.label,
            action:
              currentEvent?.terminateAction?.actions ||
              defaultTerminateButton.action,
            type: defaultTerminateButton.type,
          }
        : defaultTerminateButton;

      addNavigationState("experiment", [
        {
          icon: iconsLink.exit_sample,
          label: "experiment.exit.button.label",
          onClick: () => {
            openDialogBox("terminate", {
              title: "experiments.experimentController.dialog.terminate.title",
              description:
                "experiments.experimentController.dialog.terminate.message",
              showCloseButton: false,
              buttons: [
                {
                  label:
                    "experiments.experimentController.dialog.cancel.button.label",
                  action: "closeDialogBox",
                  type: ButtonTypes.SECONDARY,
                },
                terminateButton,
              ],
            });
          },
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.white.light,
        },
      ]);
      switchNavigationState("experiment");
    }
    initHeader(
      {
        mainTitle: {
          label: "header.title.main.label.study",
          text: getTrialName,
        },
        subTitleItems: [
          {
            label: "header.title.subItem.label.participant",
            text: participant?.pui || "",
          },
          {
            label: "header.title.subItem.label.device",
            text: serialNumbers?.join(", ") || "",
          },
          {
            label: "header.title.subItem.label.sampleCount",
            text: (participant?.analytics?.complete ?? 0) + 1 || "",
          },
        ],
        signalIndicator: {
          heading: currentEvent?.heading,
          validation: headingValidation,
        },
      } as InitHeaderOptions,

      {
        activeStep: 3,
        allowAction:
          currentEvent?.annotationStatus !== AnnotationStatus.IN_PROGRESS,
        onClick: handleRedirect,
        steps: [
          {
            label: "progressBar.step.trialsManagement",
            status: ProgressBarStatus.SUCCESS,
            link: ROUTES.TRIALS_MANAGEMENT,
            enableLink: true,
          },
          {
            label: "progressBar.step.trialParticipants",
            status: ProgressBarStatus.SUCCESS,
            link: ROUTES.TRIAL_PARTICIPANTS,
            enableLink: true,
          },
          {
            label: "progressBar.step.trialParticipantDevices",
            status: ProgressBarStatus.SUCCESS,
            link: ROUTES.TRIAL_PARTICIPANT_DEVICES,
            enableLink: true,
          },
          {
            label: "progressBar.step.trialParticipantExperiment",
            status: ProgressBarStatus.IN_PROGRESS,
            enableLink: false,
          },
          {
            label: "Results",
            status: ProgressBarStatus.PENDING,
            enableLink: false,
          },
        ],
      } as InitProgressBarOptions,
      [
        {
          key: "trialId",
          value: trialId || "",
        },
        {
          key: "trialMemberId",
          value: trialMemberId || "",
        },
        {
          key: "annotationId",
          value: "",
        },
      ],
      false
    );
    setSerialNumbers(serialNumbers || []);
    startTimer();
  }, [
    trialQueries.trial.isFetched,
    annotationId,
    currentEvent,
    participant,
    headingValidation,
  ]);

  useEffect(() => {
    setTrial(trialQueries.trial.data);
  }, [trialQueries.trial.isFetched]);

  useEffect(() => {
    if (!participantQueries.participant.data) return;
    if (!participantQueries.annotationAnalyticsQuery.data) return;

    const participant = participantQueries.participant.data;
    const { annotations } = participantQueries.annotationAnalyticsQuery.data;
    setParticipant({ ...participant, analytics: annotations } as Participant);
  }, [
    participantQueries.participant.isFetched,
    participantQueries.annotationAnalyticsQuery.isFetched,
  ]);

  useEffect(() => {
    if (!getTrialConfiguration) return;
    if (annotationConfiguration) return;

    const _annotationConfiguration = getTrialConfiguration;
    if (!_annotationConfiguration) return;
    setAnnotationConfiguration(_annotationConfiguration);
    const { events, device } = _annotationConfiguration.data;
    if (events) {
      setConfigurationEvents(events);
    }
    if (device) {
      setDevice(device);
    }
  }, [getTrialConfiguration]);

  const handleIngest = async () => {
    setIsBlocking(false);
    const response = await annotationQuery.createIngest.mutateAsync(
      annotation.uuid
    );
    const annotationId = annotation.uuid;
    const ingestId = response.uuid;
    const url = buildLocalUrl({
      path: ROUTES.TRIAL_PARTICIPANT_EXPERIMENT_RESULTS,
      params: {
        trialId,
        trialMemberId,
        annotationId,
        ingestId,
      },
      query: { ingestId, "serial-numbers": serialNumbers?.join(",") },
    });
    handleRedirect(url);
  };

  const handleRedirect = (url?: string) => {
    if (url) {
      navigate(url);
    }
    setTimeout(() => {
      resetExperimentStore();
    }, 200);
  };

  const getAfterPurgeRedirectUrl = (redirect: string) => {
    let path: string;
    let params = { trialId, trialMemberId };
    let query = {};

    switch (redirect) {
      case RedirectTo.EXPERIMENT:
        path = ROUTES.TRIAL_PARTICIPANT_EXPERIMENT;
        if (serialNumbers) {
          query = {
            "serial-numbers": serialNumbers?.join(","),
          };
        }
        break;
      case RedirectTo.STUDIES_MANAGEMENT:
        path = ROUTES.TRIALS_MANAGEMENT;
        break;
      default:
      case RedirectTo.DEVICES:
        path = ROUTES.TRIAL_PARTICIPANT_DEVICES;
        break;
    }
    return buildLocalUrl({ path, params, query });
  };

  const handleNavigatePurge = (redirect: string) => {
    const url = getAfterPurgeRedirectUrl(redirect);
    const redirectUrl = buildLocalUrl({
      path: ROUTES.DEVICES_PURGE,
      params: {
        trialId,
        serialNumber: serialNumbers ? serialNumbers[0] : "",
      },
      query: {
        referrerUrl: url,
      },
    });
    handleRedirect(redirectUrl);
  };

  const handleTerminateAnnotation = async () => {
    if (!annotation) return;
    if (!currentEvent) return;
    if (
      annotationEvents.find(
        (event) => event.name === AnnotationEventType.TERMINATE
      )
    )
      return;
    setLoading(true);
    setIsBlocking(false);
    const createEventPayload = {
      annotationId: annotation.uuid,
      name: AnnotationEventType.TERMINATE,
      description: AnnotationEventType.TERMINATE,
    };
    try {
      const result = await annotationQuery.createEvent.mutateAsync(
        createEventPayload
      );
      addAnnotationEvent(result, CardStatus.FAIL);
    } catch (error) {
      console.error("Failed to terminate annotation:", error);
    } finally {
      setLoading(false);
    }
  };

  const createRpcEvent = async (payload: CreateRpcEventParams) => {
    if ("commands" in payload) {
      const { serialNumber, wait, commands } = payload;
      const data = commands?.map((command) => ({
        ...command,
        rpcType: rpcType.TWO,
      }));
      await devicesQueries.createRpcEvents.mutateAsync({
        wait: wait || 0,
        data,
        serialNumber,
      });
    } else {
      const { method, params, serialNumber } = payload;
      await devicesQueries.createRpcEvents.mutateAsync([
        {
          method,
          params,
          rpcType: rpcType.TWO,
          serialNumber,
        },
      ]);
    }
  };

  const handleGenerateRPC = async (
    status: CardStatus,
    payload:
      | {
          method: string;
          params: { [key: string]: string };
        }
      | {
          wait: number;
          commands: Array<{
            method: string;
            params: { [key: string]: string };
          }>;
        }
  ) => {
    if (!serialNumbers || !serialNumbers.length) return;
    setLoading(true);

    try {
      if ("commands" in payload) {
        const { wait, commands } = payload;
        await createRpcEvent({
          wait,
          commands,
          serialNumber: serialNumbers[0],
        });
      } else {
        const { method, params } = payload;
        await createRpcEvent({
          method,
          params,
          serialNumber: serialNumbers[0],
        });
      }
    } catch (err) {
      console.error("Failed to generate RPC:", err);
    } finally {
      setLoading(false);
    }
  };

  const createAnnotationEvent = async (status: CardStatus) => {
    if (!annotation.uuid) return;
    if (!nextEvent) return;
    if (!currentEvent) return;
    setLoading(true);
    const createEventPayload = {
      annotationId: annotation.uuid,
      name: nextEvent?.name || "",
      description: nextEvent?.name,
    };
    const result = await annotationQuery.createEvent.mutateAsync(
      createEventPayload
    );
    addAnnotationEvent(result, status);
    alignEvents();
    setLoading(false);
  };

  const handleshowHideAction = async (
    status: CardStatus,
    params?: { id: string; show: boolean }
  ) => {
    params && updateCurrentEventAction(params?.id, params?.show);
  };

  const createAnnotation = async (
    status: CardStatus,
    params?: { events: CreateAnnotationEvent[] }
  ) => {
    if (!annotationConfiguration) return;
    if (!trialId) return;
    if (!participant) return;
    if (!nextEvent) return;
    if (!serialNumbers) return;
    setLoading(true);

    const payload = {
      trialStatus: TrialStatus.PENDING,
      trialId: trialId,
      configurationId: annotationConfiguration.uuid,
      participantUuid: participant.uuid,
      serialNumbers: serialNumbers,
      metadata: experimentFrom,
      event: !params?.events
        ? [{ name: nextEvent.name, description: nextEvent.name }]
        : params.events,
      elapsedTime  
    };
    const result = await annotationQuery.createAnnotation.mutateAsync(payload);
    setIsBlocking(true);
    setAnnotation(result, status);
    alignEvents();
    setLoading(false);
  };

  const handleNextStep = async () => {
    setLoading(true);
    const adjustedTime = getAdjustedTime(elapsedTime);
    const now = adjustedTime.format(timeFormat.UTC);
    addAnnotationEvent(
      { name: nextEvent?.name, createdAt: now },
      CardStatus.SUCCESS
    );
    alignEvents();
    setLoading(false);
  };

  const handleNavigateToDevicesScreen = async () => {
    handleCloseDialogBox();
    const redirectUrl = buildLocalUrl({
      path: ROUTES.TRIAL_PARTICIPANT_DEVICES,
      params: {
        trialId,
        trialMemberId,
      },
    });

    handleRedirect(redirectUrl);
  };

  const handleTerminateAction = async () => {
    await handleTerminateAnnotation();
  };

  const handleNavigateToPurgeScreen = async (
    redirect: string = RedirectTo.DEVICES
  ) => {
    handleNavigatePurge(redirect);
  };

  const handleTerminateAndRedirectToPurge = async (
    redirect: string = RedirectTo.DEVICES
  ) => {
    await handleTerminateAnnotation();
    handleCloseDialogBox();
    handleNavigateToPurgeScreen(redirect);
  };

  const handleTerminateAndRedirectToDevices = async () => {
    await handleTerminateAnnotation();
    handleNavigateToDevicesScreen();
  };

  const handleCloseDialogBox = async () => {
    closeDialogBox("terminate");
  };

  const evaluateCurrentStatus = () => {
    let status = CardStatus.PENDING;
    if (!currentEvent) return status;
    if (failedStep?.toLowerCase() === currentEvent.name.toLowerCase()) {
      status = CardStatus.FAIL;
    } else if (!validation) {
      status = CardStatus.SUCCESS;
    } else if (validation.verdict) {
      status = CardStatus.SUCCESS;
    } else {
      status = CardStatus.WARNING;
    }
    return status;
  };

  const consolidateLoadingStatuses = () => {
    if (!currentEvent) return true;
    if (!annotationConfiguration) return true;
    if (!participantQueries.participant.isFetched) return true;
    if (!trialQueries.trial.isFetched) return true;
    if (!serialNumbers?.length) return true;
    if (!participant) return true;
    return false;
  };

  const actions = {
    createAnnotation: createAnnotation,
    createEvent: createAnnotationEvent,
    terminateAnnotation: handleTerminateAction,
    redirectToPurge: handleNavigateToPurgeScreen,
    redirectToDevices: handleNavigateToDevicesScreen,
    terminateAnnotationAndRedirectToPurge: handleTerminateAndRedirectToPurge,
    terminateAnnotationAndRedirectToDevices:
      handleTerminateAndRedirectToDevices,
    createIngest: handleIngest,
    nextStep: handleNextStep,
    closeDialogBox: handleCloseDialogBox,
    generateRPC: handleGenerateRPC,
    showHideAction: handleshowHideAction,
  };

  let shouldBlock = React.useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) =>
      currentLocation.pathname !== nextLocation.pathname,
    []
  );
  useCustomBlocker(shouldBlock, isBlocking, handleTerminateAnnotation);

  return (
    <>
      <ExperimentTimerController actions={actions} />
      {consolidateLoadingStatuses() ? (
        <Loading />
      ) : (
        <>
          <InformationBlock
            status={evaluateCurrentStatus()}
            configurations={informationBlockContent}
          />
          <Grid
            container
            alignItems="center"
            justifyContent="space-around"
            flexDirection="column"
            sx={containerStyle}
          >
            <Grid width={"100%"} flexGrow={1} mb={8} mt={5}>
              <Grid
                container
                alignItems={"center"}
                justifyContent={"space-between"}
                flexWrap={"nowrap"}
              >
                <Grid size={"grow"}>
                  {currentEvent?.validate?.component ===
                    validateComponent.MetricsContainer && (
                    <MetricsContainer deviceQueries={deviceQueries} />
                  )}
                </Grid>
                <Grid
                  size={{ sm: 4 }}
                  display={"flex"}
                  justifyContent={"right"}
                >
                  <TimerButtonContainer actions={actions} />
                </Grid>
              </Grid>
            </Grid>
            <Grid width={"100%"} mb={8}>
              <Grid container flexDirection="row" justifyContent="flex-end">
                {currentEvent && (
                  <Grid flexGrow={12} flexBasis={0}>
                    <Grid
                      container
                      flexDirection="row"
                      justifyContent={"center"}
                      gap={4}
                    >
                      <Progress event={currentEvent} />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      <DialogBox dialogKey="terminate" actions={actions} />
    </>
  );
};

export default Experiment;
