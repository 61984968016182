import { create } from "zustand";

export enum SystemStatus {
  SUCCESS = "SUCCESS",
  WARNING = "WARNING",
  FAIL = "FAIL",
}

interface SystemStatusState {
  status: SystemStatus;
  setStatus: (status: SystemStatus) => void;
  elapsedTime: number;
  setElapsedTime: (elapsedTime: number) => void;
}

const useSystemStatusStore = create<SystemStatusState>((set) => ({
  status: SystemStatus.SUCCESS,
  setStatus: (status: SystemStatus) => set({ status }),
  elapsedTime: 0,
  setElapsedTime: (elapsedTime: number) => set({ elapsedTime }),
}));

export default useSystemStatusStore;
