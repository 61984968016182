import { Grid2 as Grid } from "@mui/material";
import { Theme } from "@mui/system";
import { useTranslation } from "react-i18next";

interface StatusProps {
  text: string;
  Icon: React.ElementType;
  color: Theme["palette"];
}

const DeviceStatus: React.FC<StatusProps> = ({ text, Icon, color }) => {
  const { t } = useTranslation();
  return (
    <Grid
      display={"flex"}
      justifyContent={"center"}
      sx={{ color: color.dark }}
      flexWrap={"wrap"}
    >
      <Grid sx={{ margin: "0 0.3rem" }}>
        <Icon sx={{ baseColor: color.main }} />
      </Grid>
      <Grid sx={{ margin: "0 0.3rem" }}>{t(text)}</Grid>
    </Grid>
  );
};

export default DeviceStatus;
