import { SxProps, Theme, styled } from "@mui/system";
import Typography, { TypographyProps } from "@mui/material/Typography";
import { Grid2 as Grid, Grid2Props as GridProps } from "@mui/material";

export const iconStyle = (theme: Theme, backgroundColor: string): SxProps => ({
  fontSize: "2rem",
  borderRadius: "0.5rem",
  fill: theme.palette.white.light,
  backgroundColor,
});

export const StyledTypography = styled(Typography)<TypographyProps>(
  ({ theme }) => ({
    borderRadius: "0.3rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  })
);

export const StatusAnimation = styled(Typography)<TypographyProps>(
  ({ theme }) => ({
    top: "0.1rem",
    position: "relative",
    borderRadius: "0.5rem",
    "&::before": {
      content: '""',
      position: "absolute",
      top: "0",
      left: "0.15rem",
      width: "0%",
      height: "0.2rem",
      borderRadius: "10%",
      background: `linear-gradient(to right, ${theme.palette.grey[600]}, ${theme.palette.white.main})`,
      animation: "progress 2s linear infinite",
    },
    "@keyframes progress": {
      "0%": {
        width: "0%",
        opacity: 1,
      },
      "90%": {
        width: "85%",
        opacity: 1,
      },
      "100%": {
        width: "85%",
        opacity: 0,
      },
    },
  })
);

export const StyledGrid = styled(Grid)<GridProps>(({ theme }) => ({
  fontFamily: "Chivo Mono",
  textWrap: "nowrap",
}));
