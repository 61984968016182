import React from "react";
import { Grid2 as Grid } from "@mui/material";
import { CardStatus, getCardColor } from "components/Card";
import { useTheme } from "@mui/system";
import { Indicator } from "./Indicator.component";
import {
  HeadingConfiguration,
  IndicatorValidation,
} from "services/annotation.service";
import { useTranslation } from "react-i18next";

interface SignalIndicatorProps {
  indicators: {
    heading: HeadingConfiguration | undefined;
    validation: IndicatorValidation;
  };
}
interface RuleStatus {
  true: CardStatus;
  false: CardStatus;
  other: CardStatus;
}

interface Detail {
  checked_item: string;
  lower_bound: number;
  eval: number; 
  upper_bound: number;
  status: boolean;
}

export const SignalIndicator: React.FC<SignalIndicatorProps> = ({
  indicators,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  if (!indicators?.heading) return;
  const getStatus = (
    icon: string,
    ruleStatus: RuleStatus = {
      true: CardStatus.SUCCESS,
      false: CardStatus.WARNING,
      other: CardStatus.IN_PROGRESS,
    }
  ): CardStatus => {
    const result = indicators.validation?.deviceValidation?.results?.find(
      (item: any) => item.name === icon
    );
    return result
      ? result.verdict
        ? ruleStatus.true
        : ruleStatus.false
      : ruleStatus.other;
  };
  const resultArray = indicators.heading?.validate?.rules
    .map((rule: any) => {
      const {
        icon,
        status,
        tooltip,
        showValueOnHoover,
        showValue,
        params,
        rules,
      } = rule;

      if (params) {
        const result = indicators.validation?.deviceValidation?.results?.find(
          ({ name }: any) => name === icon
        );

        const metricsValues = result?.results as Record<string, any>;

        const metricsResultsTooltip = metricsValues && Object
          .entries(metricsValues)
          .map(([key, { value }]) => {
            if (Array.isArray(value?.details)) {
              const detailsString = value.details
                .map((detail: Detail) => {
                  const {
                    checked_item,
                    lower_bound,
                    eval: evaluation,
                    upper_bound,
                    status,
                  } = detail;
                  return `${t(
                    "Item"
                  )} ${checked_item}: ${lower_bound} <= ${evaluation.toFixed(
                    2
                  )} <= ${upper_bound}, ${status}`;
                })
                .join("<br />");

              return `${t(
                `signalIndicator.${key}.label`
              )}: <br />${detailsString}`;
            } else {
              return `${t(tooltip)}: ${value}`;
            }
          })
          .join("<br />");
       
        const value = result?.results?.[result?.name]?.value;
        const metricStatus = getStatus(icon, status);
        const cardColor = getCardColor(metricStatus, theme);

        return {
          icon,
          tooltip: metricsResultsTooltip,
          showValueOnHoover,
          showValue,
          value,
          status: metricStatus,
          cardColor,
        };
      } else {
        const exist = rules?.find(
          (r: any) => indicators.validation.signalStrength?.[r.telemetryType]
        );
        if (exist) {
          const { icon, status } =
            indicators.validation.signalStrength?.[exist.telemetryType];
          const value =
            indicators.validation.signalStrength?.[exist.telemetryType]?.value;
          const cardColor = getCardColor(status, theme);

          return {
            icon,
            status,
            tooltip,
            showValue,
            showValueOnHoover,
            value,
            cardColor,
          };
        }
      }

      return null;
    })
    .filter(Boolean);

  if (!resultArray) return null;
  return (
    <Grid container flexDirection={"row"} spacing={1}>
      {resultArray.map((rule: any, index: number) => (
        <Indicator key={index} indicator={rule} />
      ))}
    </Grid>
  );
};
