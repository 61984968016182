import React from "react";
import { Typography } from "@mui/material";
import useGuideStore from "./TroubleShootGuide/guide.store";
import { useTheme } from "@mui/material/styles";
import { AlertIcon } from "./icons/AlertIcon";

interface HelpProps {
  contextTag?: string[];
  preCheck?: boolean;
}

const Help: React.FC<HelpProps> = ({ contextTag, preCheck = false }) => {
  const { setContextTag, setOpen: setGuideOpen } = useGuideStore();

  const theme = useTheme();
  const filteredGuides = useGuideStore((state) => state.filteredGuides);

  if (preCheck) {
    if (!filteredGuides.length) return;
    if (!contextTag) return;
    const matchingGuides = filteredGuides
      .flatMap((g) => g.guide)
      .filter((g) => g.contextTag?.some((tag) => contextTag.includes(tag)));
    if (!matchingGuides.length) return;
  }

  if (!contextTag?.length) return null;

  const openHelp = () => {
    if (contextTag) setContextTag(contextTag);
    setGuideOpen(true);
  };

  return (
    <>
      <Typography
        component="div"
        display="flex"
        alignItems={"center"}
        sx={{ cursor: "pointer" }}
        onClick={openHelp}
      >
        {contextTag ? (
          <AlertIcon
            sx={{ fill: theme.palette.common.white, fontSize: "2rem" }}
          />
        ) : null}
      </Typography>
    </>
  );
};

export default Help;
