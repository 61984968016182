import SearchIcon from "@mui/icons-material/Search";
import { Form, Formik } from "formik";
import { Grid, InputAdornment, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { StyledInputAdornment } from "pages/Participants/Participants.styles";
import Input from "components/form/Input";
import {
  ClearIcon,
  DatePicker,
  DateValidationError,
  LocalizationProvider,
  PickerChangeHandlerContext,
} from "@mui/x-date-pickers";
import React, { useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { SearchType } from "constants/index";

interface ParticipantsSearchProps {
  onSearch: (values: string) => void;
  placeholder?: string;
  searchType?: string;
  width?: string;
  triggerOnFieldChange?: boolean;
}

const Search: React.FC<ParticipantsSearchProps> = ({
  onSearch,
  placeholder,
  searchType = SearchType.TEXT,
  width = "80%",
  triggerOnFieldChange = false,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [showClearIcon, setShowClearIcon] = useState("none");
  const initialValues = {
    search: "",
  };
  const onSubmit = (values: any) => {
    onSearch(values);
  };

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Formik
      key="participants-search"
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {(formik) => (
        <Form>
          <Grid
            sx={{ display: isMobile ? "block" : "flex" }}
            justifyContent={"end"}
          >
            <Grid item flexBasis={width}>
              {searchType === SearchType.DATE ? (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label={placeholder ? t(placeholder) : null}
                    format={t("search.date.format")}
                    disableFuture={true}
                    autoFocus={false}
                    onChange={(
                      value: any,
                      context: PickerChangeHandlerContext<DateValidationError>
                    ) => {
                      if (value && !context.validationError) {
                        const dateStr = value.format("YYYY-MM-DD");
                        formik.setFieldValue("search", dateStr);
                        formik.submitForm();
                      }
                    }}
                    sx={{
                      width: "30rem",
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "10px",
                      },
                      "& .MuiOutlinedInput-input": {
                        backgroundColor: theme.palette.grey[100],
                        borderRadius: "10px",
                        border: `1px solid ${theme.palette.grey[300]}`,
                      },
                    }}
                    slotProps={{
                      field: {
                        clearable: true,
                        onClear: () => {
                          formik.setFieldValue("search", "");
                          formik.submitForm();
                        },
                      },
                    }}
                  />
                </LocalizationProvider>
              ) : (
                <Input
                  label=""
                  name="search"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const value = event.target.value;
                    setShowClearIcon(value === "" ? "none" : "flex");
                    formik.setFieldValue("search", value);
                    if (triggerOnFieldChange && value.length >= 2) {
                      formik.submitForm();
                    }
                  }}
                  placeholder={placeholder ? t(placeholder) : null}
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                      cursor: "pointer",
                    },
                    endAdornment: (
                      <>
                        <InputAdornment
                          position="end"
                          style={{
                            display: showClearIcon,
                            marginRight: "2rem",
                          }}
                          onClick={() => {
                            setShowClearIcon("none");
                            formik.setFieldValue("search", "");
                            formik.submitForm();
                          }}
                        >
                          <ClearIcon />
                        </InputAdornment>
                        <StyledInputAdornment position="start">
                          <SearchIcon
                            sx={{ zIndex: 1 }}
                            onClick={formik.submitForm}
                          />
                        </StyledInputAdornment>
                      </>
                    ),
                  }}
                />
              )}
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default Search;
