import React from "react";
import { Grid2 as Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getAssetsPath } from "utils/assets.utils";
import moment from "moment";
import {
  StyledGridStatsContainer,
  StyledGridStatsItem,
} from "../StudiesDashboard.styles";

interface TabsProps {
  cdeAnalytics: any;
  trial: any;
}

const StudyOverviewStats: React.FC<TabsProps> = ({ cdeAnalytics, trial }) => {
  const { t } = useTranslation();

  return (
    <StyledGridStatsContainer
      container
      display={"flex"}
      alignContent={"center"}
      justifyContent={"center"}
      sx={{
        height: { sm: "7rem", xs: "100%" },
      }}
    >
      <StyledGridStatsItem size={{ xs: 12, sm: 4 }} flexGrow={1}>
        <Typography display={"flex"} justifyContent={"center"}>
          <img
            src={getAssetsPath("icons/participants.svg")}
            alt="studyDevices"
          />
        </Typography>
        <Typography display={"flex"} justifyContent={"center"}>
          {t("studyOverview.trial.stats.creationdate.label")}
        </Typography>
        <Typography display={"flex"} justifyContent={"center"}>
          {moment(trial.data?.createdAt).format("DD-MM-YYYY")}
        </Typography>
      </StyledGridStatsItem>
      <StyledGridStatsItem size={{ xs: 12, sm: 4 }} flexGrow={1}>
        <Typography display={"flex"} justifyContent={"center"}>
          <img
            src={getAssetsPath("icons/samples_captured.svg")}
            alt="studyDevices"
          />
        </Typography>
        <Typography display={"flex"} justifyContent={"center"}>
          {t("studyOverview.trial.stats.samples.label")}
        </Typography>
        <Typography display={"flex"} justifyContent={"center"}>
          {cdeAnalytics.data?.total}
        </Typography>
      </StyledGridStatsItem>
      <Grid flexGrow={1} size={{ xs: 12, sm: 4 }}>
        <Typography display={"flex"} justifyContent={"center"}>
          <img
            src={getAssetsPath("icons/change_status.svg")}
            alt="studyDevices"
          />
        </Typography>
        <Typography display={"flex"} justifyContent={"center"}>
          {t("studyOverview.trial.stats.participants.label")}
        </Typography>
        <Typography display={"flex"} justifyContent={"center"}>
          {cdeAnalytics.data?.trialAnalytics?.participants?.total}
        </Typography>
      </Grid>
    </StyledGridStatsContainer>
  );
};

export default StudyOverviewStats;
