import { Grid2 as Grid } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import CustomDrawer from "components/CustomDrawer";
import useAnnotationApi from "hooks/useAnnotationApi.hook";
import { CardBackgrounds, CardSize, CardStatus } from "components/Card";
import DownloadRawDataCard from "pages/Result/Components/DownloadRawDataCard.component";
import ChartChemicalSensorResponse from "pages/Result/Components/ChartChemicalSensorResponse.component";
import { CardConfiguration } from "services/annotation.service";
import Loading from "components/Loading";
import ResultDetailsCard from "./ResultDetailsCard.component";

interface IsOpenProps {
  isOpen: boolean;
  onClose: () => void;
  annotation: Record<string, any>;
  configurations: Record<string, any>;
}
const ResultDetails: React.FC<IsOpenProps> = ({
  isOpen,
  onClose,
  annotation,
  configurations,
}) => {
  const { t } = useTranslation();

  const annotationQueries = useAnnotationApi({
    annotationId: annotation.id,
    ingestId: annotation?.ingest[0]?.uuid,
  });
  const cards: CardConfiguration[] =
    configurations?.result?.events.find(
      (event: any) => event.name.toLowerCase() === "results"
    )?.cards || [];

  const getCardComponent = (card: CardConfiguration, options: any) => {
    switch (card.type) {
      case "downloadRawData": {
        const { ingest } = options;
        if (!ingest) return null;
        const storageId =
          ingest.data?.result?.CSV_FILE_PATH ||
          ingest.data?.result?.JSON_FILE_PATH;
        return (
          <Grid key={card.label} flexGrow={0.25} flexBasis={0}>
            <DownloadRawDataCard
              storageId={storageId}
              status={card.status as CardStatus}
              label={card.label}
              actionLabel={card.options.actionLabel}
              background={card.background as CardBackgrounds}
              size={card.size ? card.size : CardSize.medium}
            />
          </Grid>
        );
      }
      case "chemicalResponse": {
        const { ingest } = options;
        if (!ingest) return null;
        const data = ingest.data?.result?.TELEMETRY_PLOT;
        if (!data) return null;

        const downloadButtonComponent =
          card.button && options.ingest.data ? (
            <Grid key={card.label} flexGrow={0.25} flexBasis={0}>
              <DownloadRawDataCard
                storageId={
                  options.ingest.data.result?.CSV_FILE_PATH ||
                  options.ingest.data.result?.JSON_FILE_PATH
                }
                status={card.status as CardStatus}
                label={card.button.label}
                actionLabel={card.button.options.actionLabel}
                background={card.button.background as CardBackgrounds}
                size={card.button.size ? card.button.size : CardSize.medium}
              />
            </Grid>
          ) : null;

        return (
          <Grid key={card.label} flexGrow={0.75} flexBasis={"100%"}>
            <ChartChemicalSensorResponse
              status={card.status as CardStatus}
              size={card.size ? card.size : CardSize.medium}
              label={card.label}
              options={card}
              data={data}
              buttonComponent={downloadButtonComponent}
              sx={{
                padding: "none",
                background: "#fff",
                border: "1px solid #F4F6F8",
                borderRadius: "10px",
              }}
            />
          </Grid>
        );
      }
    }
  };

  return (
    <CustomDrawer
      open={isOpen}
      onClose={onClose}
      additionalProps={{
        titleText: t("participantDashboard.ResultDetails.heading.label"),
        id: "rightDrawer.resultDetails",
        headerWidth: 12,
      }}
    >
      <Grid
        container
        flexDirection="column"
        sx={{ padding: "1.5rem" }}
        spacing={2}
      >
        <Grid>
          <ResultDetailsCard annotation={annotation} />
        </Grid>
        {annotationQueries.ingest.isFetched ? (
          cards.map((card) => getCardComponent(card, annotationQueries))
        ) : (
          <Loading />
        )}
      </Grid>
    </CustomDrawer>
  );
};

export default ResultDetails;
