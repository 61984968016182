import { List, Grid2 as Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

import LeftNavigationLinks from "./LeftNavigationLinks";
import {
  StyleLeftNavigationContainer,
  StyleLeftNavigationContainerItem,
  StyleLeftNavigationLogo,
} from "layouts/layouts.styles";
import { MenuItem } from "services/configuration.service";
import { useLeftNavigationStore } from "stores/navigation.store";
import { getAssetsPath } from "utils/assets.utils";
import { useState } from "react";
export interface LinksProps {
  open: boolean;
}

const LeftNavigationItems: React.FC<LinksProps> = ({ open }) => {
  const [imageHeight, setImageHeight] = useState("4rem");
  const logo = useLeftNavigationStore((state) => state.logo);
  const allowLogoAction = useLeftNavigationStore(
    (state) => state.allowLogoAction
  );
  const getNavigationStatesTop = useLeftNavigationStore(
    (state) => state.getNavigationStatesTop
  );
  const activeNavigationState = useLeftNavigationStore(
    (state) => state.activeNavigationState
  );
  const allowAction = useLeftNavigationStore((state) => state.allowAction);
  const navigate = useNavigate();

  const handleClickLogo = () => {
    if (!allowLogoAction) return;
    navigate("/");
  };

  const handleWidth = () => {
    !open ? setImageHeight("4rem") : setImageHeight("auto");
  };

  if (!getNavigationStatesTop) return;
  if (!activeNavigationState) return;
  if (!getNavigationStatesTop[activeNavigationState]) return;
  const activeStateMenuItems = getNavigationStatesTop[activeNavigationState];

  const renderedItems = activeStateMenuItems.map((item: MenuItem) => {
    if (!item.show) return null;
    return (
      <LeftNavigationLinks
        item={item}
        key={item.icon}
        open={open}
        allowAction={allowAction}
      />
    );
  });

  return (
    <>
      <StyleLeftNavigationContainer>
        <StyleLeftNavigationContainerItem>
          <StyleLeftNavigationLogo
            src={getAssetsPath(open ? logo.large : logo.small)}
            alt="Logo"
            onClick={handleClickLogo}
            sx={{ width: open ? "16rem" : "5rem", height: imageHeight }}
            onLoad={handleWidth}
          />
        </StyleLeftNavigationContainerItem>
        <Grid
          container
          style={{
            opacity: open ? 1 : 0,
            margin: 25,
          }}
        >
          <Grid size={{ xs: 12 }}>&nbsp;</Grid>
        </Grid>
        <List>{renderedItems}</List>
      </StyleLeftNavigationContainer>
    </>
  );
};

export default LeftNavigationItems;
