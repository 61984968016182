import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { QueryKeys } from "constants/queryKeys.constants";
import { useState } from "react";
import useSnackbarStore from "serviceComponents/Snackbar/snackbar.store";
import { TrialStatus, fetchTrialData } from "services/cde.service";
import {
  updateTrialStatus as _updateTrialStatus,
  UpdateTrialStatus,
} from "services/cde.service";
import { CustomError } from "utils/privateHttp.utils";

export interface Options {
  take: number;
  skip: number;
  name?: string;
  status?: TrialStatus[];
  order?: string;
  cdeIds?: string[];
}

function useHomeApi(initialOptions: Options) {
  const [options, setOptions] = useState<Options>(initialOptions);
  const successNotification = useSnackbarStore((state) => state.openSuccess);
  const httpErrorNotification = useSnackbarStore(
    (state) => state.httpErrorNotification
  );
  const queryClient = useQueryClient();
  const trialsQuery = useQuery({
    queryKey: [QueryKeys.TRIALS, options],
    queryFn: async () =>
      await fetchTrialData(
        {
          name: options.name,
          status: options.status?.length ? options.status : null,
        },
        options.take,
        options.skip,
        options.order,
        options.cdeIds
      ),
  });

  const updateTrialStatus = useMutation({
    mutationFn: async (data: UpdateTrialStatus) =>
      await _updateTrialStatus(data),
    onSuccess: () => {
      [QueryKeys.TRIALS, QueryKeys.STUDIES_DASHBOARD_TRIALS].forEach(
        (key: string) => {
          queryClient.resetQueries({ queryKey: [key] });
        }
      );
      successNotification("trial.updateStatus.success");
    },
    onError: (error: CustomError) => {
      httpErrorNotification(error);
    },
  });

  const refreshTrialsQuery = (keys: any[] = []) => {
    queryClient.invalidateQueries({ queryKey: [QueryKeys.TRIALS, ...keys] });
  };

  const trialsCount = useQuery({
    queryKey: [
      QueryKeys.TRIALS,
      {
        take: 0,
        skip: 0,
        status: [],
      },
    ],
    queryFn: async () =>
      await fetchTrialData(
        {
          status: [],
        },
        0,
        0
      ),
    enabled: options?.cdeIds?.length ? false : true,
  });

  const dashboardTrialsCount = useQuery({
    queryKey: [
      QueryKeys.STUDIES_DASHBOARD_TRIALS,
      {
        take: 0,
        skip: 0,
        status: [],
      },
    ],
    queryFn: async () =>
      await fetchTrialData(
        {
          status: [],
        },
        0,
        0,
        undefined,
        options.cdeIds
      ),
    enabled: options?.cdeIds?.length ? true : false,
  });

  return {
    query: trialsQuery,
    options,
    updateOptions: (options: Options) => setOptions(options),
    refreshQuery: refreshTrialsQuery,
    trialsCount,
    dashboardTrialsCount,
    updateTrialStatus,
  };
}

export default useHomeApi;
