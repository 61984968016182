import { create } from "zustand";
import { isEqual } from "utils/lodashHelpers";
import { DeviceStatusEnum, DeviceStateEnum } from "constants/index";
import { ContentConfiguration } from "services/annotation.service";
import { CardStatus } from "components/Card";

export interface DeviceStatus {
  [key: string]: string | any;
}
export interface DeviceAction {
  action: string;
  params: Record<string, any>;
  status: string[];
}
interface State {
  trialDeviceRules: any;
  deviceStatuses: DeviceStatus;
  setDeviceStatus: (deviceStatuses: DeviceStatus) => void;
  selectDeviceOptions: { [key: string]: string };
  isExperimentFormModalOpen: boolean;
  setTrialDeviceRules: (trialDeviceRules: []) => void;
  setSelectDeviceOptions: (selectDeviceOptions: {
    [key: string]: string;
  }) => void;
  setIsExperimentModalOpen: (isExperimentFormModalOpen: boolean) => void;
  deviceActions: DeviceAction[];
  setDeviceActions: (deviceActions: DeviceAction[]) => void;
  prevStatus: DeviceStatusEnum;
  setPrevStatus: (prevStatus: DeviceStatusEnum) => void;
  informationBlockContent: ContentConfiguration | undefined;
  setInformationBlockContent: (
    informationBlockContent: ContentConfiguration
  ) => void;
  devicePageStatus: CardStatus | undefined;
  setDevicePageStatus: (devicePageStatus: CardStatus | undefined) => void;
  deviceContent: ContentConfiguration | undefined;
  setDeviceContent: (deviceContent: ContentConfiguration) => void;
  reset: () => void;
}

const evaluateDeviceStatus = (deviceStatuses: DeviceStatus): CardStatus => {
  if (!deviceStatuses || Object.keys(deviceStatuses).length === 0) {
    return CardStatus.FAIL;
  }
  const states = Object.values(deviceStatuses).map(
    (device: any) => device.state
  );
  if (states.includes(DeviceStateEnum.Ready)) {
    return CardStatus.SUCCESS;
  }
  if (states.every((state: any) => state === DeviceStateEnum.Unavailable)) {
    return CardStatus.WARNING;
  }
  return CardStatus.PENDING;
};

const init = {
  trialDeviceRules: [],
  deviceStatuses: {},
};

export const useDeviceStore = create<State>()((set) => ({
  ...init,
  prevStatus: DeviceStatusEnum.OFFLINE,
  setPrevStatus: (prevStatus: DeviceStatusEnum) => set(() => ({ prevStatus })),
  setTrialDeviceRules: (trialDeviceRules) => set(() => ({ trialDeviceRules })),
  selectDeviceOptions: {},
  setSelectDeviceOptions: (selectDeviceOptions) =>
    set(() => ({ selectDeviceOptions })),
  isExperimentFormModalOpen: false,
  setIsExperimentModalOpen: (isOpen: boolean) =>
    set({ isExperimentFormModalOpen: isOpen }),
  setDeviceStatus: (deviceStatus) =>
    set(({ deviceStatuses }) => {
      const { sn, ...rest } = deviceStatus;
      const newDeviceStatuses = {
        ...deviceStatuses,
        [sn]: { ...rest },
      };
      const devicePageStatus = evaluateDeviceStatus(newDeviceStatuses);
      if (isEqual(deviceStatuses, newDeviceStatuses))
        return { deviceStatuses, devicePageStatus };
      return { deviceStatuses: newDeviceStatuses, devicePageStatus };
    }),
  deviceActions: [],
  setDeviceActions: (deviceActions: DeviceAction[]) =>
    set(() => ({ deviceActions })),
  informationBlockContent: undefined,
  setInformationBlockContent: (informationBlockContent) =>
    set(() => ({ informationBlockContent })),
  devicePageStatus: undefined,
  setDevicePageStatus: (devicePageStatus) => set(() => ({ devicePageStatus })),
  deviceContent: undefined,
  setDeviceContent: (deviceContent) => set(() => ({ deviceContent })),
  reset: () => set(() => init),
}));
