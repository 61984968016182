import React, { CSSProperties, Suspense } from "react";
import { Grid2 as Grid, Typography } from "@mui/material";
import Card, { CardSize, CardStatus } from "components/Card";
import { useTheme } from "@mui/system";
import { useTranslation } from "react-i18next";
import FullWidthCardContainer from "components/FullWidthCardContainer.component";
import LoadingIcon from "components/Loading.component";

const Chart = React.lazy(() => import("components/Chart/Chart.component"));

interface Props {
  status: CardStatus;
  label: string;
  size?: CardSize;
  options: any;
  buttonComponent?: React.ReactNode;
  data: {
    data: object[];
    layout: {
      title?: {};
      xaxis?: {};
      yaxis?: {};
      legend?: {};
      shapes?: [];
      template?: {};
      plot_bgcolor?: string;
    };
  };
  sx?: CSSProperties;
}

const ChartChemicalSensorResponse: React.FC<Props> = ({
  status,
  label,
  size,
  data,
  options,
  buttonComponent,
  sx,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const layout = data.layout;

  layout.legend = {
    ...layout.legend,
    itemclick: false,
    itemdoubleclick: false,
    tracegroupgap: 0,
  };
  const { title, ...rest } = layout;
  const config = {
    displayModeBar: false,
    responsive: true,
    scrollZoom: false,
    editable: false,
  };
  return (
    <FullWidthCardContainer sx={sx}>
      <Grid flexGrow={1}>
        <Grid container>
          <Grid flexBasis={"50%"}>
            <Typography
              component="span"
              sx={{
                fontSize: "1.25rem",
                color: theme.palette.pending.dark,
                fontWeight: 600,
              }}
            >
              {t(label)}
            </Typography>
          </Grid>
          <Grid flexBasis={"50%"} display={"flex"} justifyContent={"end"}>
            {buttonComponent}
          </Grid>
        </Grid>
      </Grid>
      <Grid>
        <Card
          status={status}
          size={size}
          sx={{
            border: "none",
            backgroundColor: "#fff",
            width: "100%",
            margin: "0 auto",
            height: "auto",
          }}
        >
          <Grid
            container
            flexDirection="row"
            justifyContent={"center"}
            sx={{ marginTop: "0.5rem" }}
          >
            {data.data.length > 1 ? (
              <Suspense fallback={<LoadingIcon />}>
                <Chart
                  data={data.data}
                  layout={{
                    ...rest,
                    plot_bgcolor: "rgba(255, 255, 255, 0.0)",
                    paper_bgcolor: "rgba(255, 255, 255, 0.5)",
                    xaxis: {
                      zeroline: true,
                      zerolinecolor: theme.palette.grey[500],
                      zerolinewidth: 1,
                      gridcolor: theme.palette.grey[500],
                    },
                    yaxis: {
                      zeroline: true,
                      zerolinecolor: theme.palette.grey[500],
                      zerolinewidth: 1,
                      gridcolor: theme.palette.grey[500],
                    },
                  }}
                  config={config}
                  sx={{
                    borderRadius: "0.5rem",
                    overflow: "hidden",
                    width: "100%",
                  }}
                />
              </Suspense>
            ) : (
              <Typography variant="h3" sx={{ marginTop: "10rem" }}>
                {t(options.noData)}
              </Typography>
            )}
          </Grid>
        </Card>
      </Grid>
    </FullWidthCardContainer>
  );
};

export default ChartChemicalSensorResponse;
