import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "constants/queryKeys.constants";
import moment from "moment";
import { useState } from "react";
import { getEngagementAnalyticsByIdentity } from "services/annotation.service";
import {
  fetchParticipantsList,
  userTrailsAnalytics,
  userTrailsMonthlyAnalytics,
} from "services/cde.service";
import { dateCalculations } from "utils/chart.utils";

interface Options {
  trialId?: string;
  cdeId?: string;
  resultsGraph?: boolean;
  participantsGraph?: boolean;
  engagementGraph?: boolean;
  cdeIds?: string[];
  take?: number;
  skip?: number;
  expand?: string;
  period?: string;
}

export enum TIMEPERIOD {
  LASTFIFTEENDAYS = "LASTFIFTEENDAYS",
  CURRENTMONTH = "CURRENTMONTH",
  LASTTHREEMONTHS = "LASTTHREEMONTHS",
  ALLTIME = "ALLTIME",
}

export enum DURATIONUNIT {
  HOURS = "HOURS",
  DAYS = "DAYS",
  MONTHS = "MONTHS",
}

export enum TIMEPERIOD_DATEFORMAT {
  LASTFIFTEENDAYS = "MM/DD HH:00",
  CURRENTMONTH = "MM/DD",
  LASTTHREEMONTHS = "MM/DD",
  ALLTIME = "YYYY/MM",
}

function useStudiesDashboardApi(initialOptions: Options) {
  const [options, setOptions] = useState<Options>(initialOptions);
  const CdeAnalytics = useQuery({
    queryKey: [QueryKeys.STUDIES_DASHBOARD, options],
    queryFn: async () => await userTrailsAnalytics(options),
    enabled: options.cdeId ? true : false,
  });

  const CdeMonthyAnalytics = useQuery({
    queryKey: [QueryKeys.STUDY_OVERVIEW, options],
    queryFn: async () => {
      const currentMonth = moment().month();
      const currentYear = moment().year();

      const completedTrials = {
        x: [] as string[],
        y: [] as number[],
      };

      const failedTrials = {
        x: [] as string[],
        y: [] as number[],
      };

      for (let month = currentMonth + 1; month <= currentMonth + 12; month++) {
        const yearOffset = month > 11 ? currentYear : currentYear - 1;
        const monthOffset = month % 12;
        const startDate = moment({ year: yearOffset, month: monthOffset });
        const from = startDate.startOf("month").format("YYYY-MM-DD");
        const to = startDate.endOf("month").format("YYYY-MM-DD");

        const result = await userTrailsMonthlyAnalytics({
          ...options,
          from,
          to,
        } as any);
        const complete = result?.statistics?.complete || 0;
        const total = result?.statistics?.total || 0;
        if (total > 0) {
          completedTrials.x.push(startDate.format("MMM YYYY"));
          failedTrials.x.push(startDate.format("MMM YYYY"));
          completedTrials.y.push(complete);
          failedTrials.y.push(total - complete);
        }
      }
      return { completedTrials, failedTrials };
    },
    enabled: options.cdeId && options.resultsGraph ? true : false,
  });

  const ParticipantsEngagementAnalytics = useQuery({
    queryKey: [QueryKeys.STUDY_PARTICIPANTS, options],
    queryFn: async () => {
      const { trialId, period } = options;
      if (!trialId) return;
      const { from, to, durationUnit } = dateCalculations(period as TIMEPERIOD);

      const result: any = await getEngagementAnalyticsByIdentity(trialId, {
        from,
        to,
        durationUnit,
      });

      return result;
    },
    enabled: options.engagementGraph ?? false,
  });

  const TrialAnalytics = useQuery({
    queryKey: [QueryKeys.STUDY_PARTICIPANTS, options],
    queryFn: async () => {
      const aggregatedTrialAnalytics = [];
      if (options?.cdeIds?.length) {
        for (const cdeId of options.cdeIds) {
          const result = await userTrailsMonthlyAnalytics({
            cdeId,
          });
          if (result) {
            aggregatedTrialAnalytics.push(...result.trialAnalytics);
          }
        }
      }
      return aggregatedTrialAnalytics;
    },
    enabled: options.cdeIds?.length ? true : false,
  });

  const TrialAllParticipants = useQuery({
    queryKey: [QueryKeys.TRIAL_PARTICIPANTS, options.trialId, options],
    queryFn: async () => {
      const participants = await fetchParticipantsList(
        options.trialId || "",
        undefined,
        options.take || 0,
        options.skip || 0,
        options.expand
      );
      return participants;
    },
    enabled: !!options.trialId && options.participantsGraph,
  });

  return {
    CdeAnalytics,
    CdeMonthyAnalytics,
    ParticipantsEngagementAnalytics,
    TrialAnalytics,
    TrialAllParticipants,
    setOptions,
    options,
  };
}

export default useStudiesDashboardApi;
