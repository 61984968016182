import React, { ReactNode } from "react";
import Grid from "@mui/material/Grid2";

import LeftNavigation from "./LeftNavigation";
import { AuthService } from "services/auth.service";
import Header from "./Header";
import { containerStyle } from "constants/index";

type RouteParams = {
  trialId?: string;
  pui?: string;
  annotationId?: string;
};
interface LayoutProps {
  children: ReactNode;
  routeParams: RouteParams;
}
const PageContainer: React.FC<LayoutProps> = ({ children, routeParams }) => {
  const currentLanguage = AuthService.locale;

  return (
    <Grid
      container
      justifyContent="center"
      flexDirection="row"
      className="pageContainer"
    >
      <Grid>
        <LeftNavigation currentLanguage={currentLanguage} />
      </Grid>
      <Grid
        flexGrow={1}
        sx={{
          flex: 1,
          minWidth: 240,
        }}
        className="pageContent"
      >
        <Grid
          container
          justifyContent="flex-start"
          flexDirection="column"
          sx={containerStyle}
        >
          <Grid>
            <Header />
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-start" flexDirection="column">
          <Grid>{children}</Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PageContainer;
