import { PermissionTypes } from "constants/index";
import { TIMEPERIOD } from "hooks/useStudiesDashboardApi.hook";
import { useLeftNavigationStore } from "stores/navigation.store";
import { create } from "zustand";

interface studiesStore {
  studiesPieChartLayout: Record<string, any>;
  studiesPieChartData: Record<string, any>[];
  setStudiesPieChartData: (studiesPieChartData: Record<string, any>[]) => void;
  studiesPieChartConfig: Record<string, any>;
  studiesBarChartLayout: Record<string, any>;
  studiesBarChartData: Record<string, any>[];
  setStudiesBarChartData: (studiesBarChartData: Record<string, any>[]) => void;
  studiesBarChartConfig: Record<string, any>;
  engagementChartDurationUnit: TIMEPERIOD;
  setEngagementChartDurationUnit: (
    engagementChartDurationUnit: TIMEPERIOD
  ) => void;
  reset: () => void;
  canViewDashboard: (cdeId: string) => boolean;
}

const init = {
  studiesPieChartLayout: {
    showlegend: false,
    title: { x: 0.0 },
    margin: { l: 0, r: 0, b: 0, t: 0 },
    hovermode: true,
    hoverinfo: "label",
  },
  studiesPieChartConfig: {
    displayModeBar: false,
    responsive: true,
    scrollZoom: false,
    editable: false,
  },
  studiesPieChartData: [],
  studiesBarChartLayout: {
    barmode: "stack",
    showlegend: false,
    yaxis: {
      griddash: "dash",
      fixedrange: true,
      zeroline: false,
    },
    shapes: [
      {
        type: "line",
        x0: 0,
        x1: 1,
        y0: 0,
        y1: 0,
        xref: "paper",
        yref: "y",
        line: {
          color: "",
          width: 1,
          dash: "dash",
        },
      },
    ],
    xaxis: {
      showgrid: false,
      zeroline: false,
      fixedrange: true,
      rangemode: "tozero",
      rangeslider: { visible: false },
    },
    bargap: 0.7,
    margin: {
      r: 50,
      b: 60,
      pad: 20,
      t: 50,
    },
  },
  studiesBarChartConfig: {
    displayModeBar: false,
    responsive: true,
    scrollZoom: false,
    editable: false,
  },
  studiesBarChartData: [],
};

export const useStudiesStore = create<studiesStore>((set) => ({
  ...init,
  reset: () => {
    set(init);
  },
  engagementChartDurationUnit: TIMEPERIOD.LASTFIFTEENDAYS,
  setEngagementChartDurationUnit: (engagementChartDurationUnit: TIMEPERIOD) =>
    set({ engagementChartDurationUnit }),
  setStudiesPieChartData: (studiesPieChartData: object[]) =>
    set({ studiesPieChartData }),
  setStudiesBarChartData: (studiesBarChartData: object[]) =>
    set({ studiesBarChartData }),
  canViewDashboard: (cdeId: string) => {
    const acceptablePermissions = [
      PermissionTypes.ADMIN,
      PermissionTypes.OWNER,
    ];

    const { cdePermissions, dashboardCdes } = useLeftNavigationStore.getState();
    const hasDashboardAccess = dashboardCdes.some(
      (item) => item.cdeId === cdeId
    );
    const hasAdminOrOwnerPermission = cdePermissions.some(
      (cde) =>
        cde.cdeId === cdeId &&
        acceptablePermissions.includes(cde.permission as PermissionTypes)
    );

    return hasDashboardAccess && hasAdminOrOwnerPermission;
  },
}));
