import { Box, CSSObject, styled } from "@mui/system";
import { Grid2 as Grid, ListItem, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";

export const SubHeaderContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "flex-end",
  verticalAlign: "middle",
  marginBottom: "0.5rem",
  [theme.breakpoints.up("md")]: {
    padding: "0rem 1rem",
  },
  minWidth: "10vw",
  [theme.breakpoints.down("sm")]: {
    justifyContent: "center",
  },
}));

export const StyleIconButton = styled(IconButton)(({ theme }) => ({
  top: "7.5rem",
  position: "fixed",
  zIndex: 4,
  [theme.breakpoints.down("lg")]: {
    top: "7.5rem",
  },
  [theme.breakpoints.down("md")]: {
    top: "7.5rem",
  },
  [theme.breakpoints.down("sm")]: {
    top: 0,
  },
  "&:hover": {
    backgroundColor: "transparent",
  },
}));

export const ActiveListItem = styled(Grid)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  lineHeight: "2rem",
  color: theme.palette.grey[600],
}));

export const StyleLeftNavigationContainer = styled(Grid)(({ theme }) => ({
  width: "100%",
  height: "100%",
  backgroundColor: theme.palette.grey[200],
}));

export const StyleLeftNavigationContainerItem = styled(Grid)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

interface StyleLeftNavigationItemProps {
  open: boolean;
  color?: string | undefined;
}

export const StyleLeftNavigationItem = styled(ListItem, {
  shouldForwardProp: (prop) => prop !== "open",
})<StyleLeftNavigationItemProps>(({ theme, open, color }) => ({
  backgroundColor: color,
  borderRadius: "1rem",
  width: "auto",
  minWidth: "10rem",
  marginRight: "1rem",
  marginBottom: "0.2rem",
  marginLeft: open ? "2rem" : "0.3rem",
  cursor: "pointer",
  "&.Mui-selected, &:hover": {
    borderRadius: "1rem",
    backgroundColor: theme.palette.grey[400],
  },
}));

export const StyleLeftNavigationLogo = styled("img")({
  height: "auto",
  width: "16rem",
  marginRight: 1,
});

export const StyleIconWrapper = styled(Typography)(({ theme }) => ({
  marginRight: "1rem",
  fontSize: "inherit",
  color: theme.palette.grey[600],
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const drawerWidth = 300;

const commonMixin = (theme: any): CSSObject => ({
  overflowX: "hidden",
  zIndex: 3,
  borderRight: `1px dotted ${theme.palette.grey[400]}`,
  backgroundColor: theme.palette.grey[200],
});

export const openedMixin = (theme: any): CSSObject => ({
  ...commonMixin(theme),
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
});

export const closedMixin = (theme: any): CSSObject => ({
  ...commonMixin(theme),
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

export const DrawerHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  border: "none",
  "&.MuiBox-root": {
    minHeight: "3rem",
  },
}));
