import {
  Grid2 as Grid,
  MenuItem,
  Pagination,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import Spacer from "components/Spacer";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface PaginatorProps {
  total: number;
  page: number;
  show: boolean;
  showRecordsPerPage?: boolean;
  recordPerPage?: number;
  onChange: (
    event: React.ChangeEvent<unknown>,
    page: number,
    itemsPerPage?: number
  ) => void;
}

export const Paginator = ({
  total,
  page,
  show,
  onChange,
  showRecordsPerPage = false,
  recordPerPage = 10,
}: PaginatorProps): JSX.Element => {
  const { t } = useTranslation();
  const [itemsPerPage, setItemsPerPage] = useState(recordPerPage);
  const itemsPerPageOptions = [10, 15, 30];

  if (!show) {
    return <Spacer height="2rem" width="100%" />;
  }

  const handleItemsPerPageChange = (event: SelectChangeEvent) => {
    const newItemsPerPage = parseInt(event.target.value as string, 10);
    setItemsPerPage(newItemsPerPage);
    handlePageChange(event as React.ChangeEvent<unknown>, 1, newItemsPerPage);
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    newPage: number,
    itemsPerPageValue: number = itemsPerPage
  ) => {
    onChange(event, newPage, itemsPerPageValue);
  };

  return (
    <Grid
      container
      display={"flex"}
      justifyContent={"flex-end"}
      sx={{
        margin: "1.2rem 0",
      }}
      alignItems={"center"}
    >
      {showRecordsPerPage ? (
        <Grid>
          <Typography component={"div"}>
            {t("pagination.recordsPerPage.label")}:
            <Select
              sx={{ height: "2rem", marginLeft: "0.5rem" }}
              id="items-per-page"
              value={recordPerPage.toString()}
              onChange={handleItemsPerPageChange}
            >
              {itemsPerPageOptions.map((value) => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </Typography>
        </Grid>
      ) : null}
      <Grid>
        <Pagination count={total} page={page} onChange={handlePageChange} />
      </Grid>
    </Grid>
  );
};
