import { FormControl, Typography, Grid2 as Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getAssetsPath } from "utils/assets.utils";

interface Props {
  placeholder: string;
  onClose?: () => {};
  name: string;
  [key: string]: any;
}

const Title = (props: Props) => {
  const { t } = useTranslation();
  const { placeholder, onClose } = props;

  return (
    <FormControl sx={{ width: "100%", marginBottom: "1rem" }}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        flexWrap="nowrap"
      >
        <Typography sx={{ fontSize: "1.5rem", fontWeight: "600" }}>
          {t(placeholder)}
        </Typography>
        {onClose && (
          <Typography>
            <img
              onClick={() => {
                onClose();
              }}
              alt={t("experimentSummary.button.exit")}
              src={getAssetsPath("icons/exit.svg")}
            />
          </Typography>
        )}
      </Grid>
    </FormControl>
  );
};

export default Title;
