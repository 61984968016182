import { get } from "utils/publicHttp.utils";
import localConfig from "../configurations/default.configuration.json";
import { getBucketUrl } from "utils/assets.utils";
import { buildLocalUrl } from "utils/url.utils";
import {
  LeftNavigationItemsOrientation,
  ProgressBarStyle,
  PermissionTypes,
  TroubleshootGuideMediaTypes,
} from "constants/index";

const CONFIGURATION_SERVICE_ROUTES = {
  CONFIGURATION_SERVICE_CLIENT_CONFIGURATIONS:
    "/configuration-service/configuration-service/rest/v1/client/configurations",
  CONFIGURATION_SERVICE_CLIENT_TRANSLATIONS:
    "/configuration-service/configuration-service/rest/v1/client/translations/:uuid",
};

export interface MenuItem {
  id?: string;
  icon: string;
  label: string;
  link?: string;
  color?: string;
  backgroundColor?: string;
  onClick?: () => void;
  orientation?: LeftNavigationItemsOrientation;
  params?: { languages: string[] };
  show?: boolean;
  permissions?: PermissionTypes[];
}

export interface Translations {
  [key: string]: string;
}

export interface Language {
  id: string;
  label: string;
}

export interface Logo {
  small: string;
  large: string;
}

export interface Config {
  favicon: string;
  languages: Language[];
  logo?: Logo;
  drawerTransitionDuration?: number;
  experimentSummaryPage?: any;
  trialStatus?: any;
  heading: {
    style: ProgressBarStyle;
  };
  uptimeRobot?: { statusCheck?: number; pspsId?: number };
}

interface Media {
  type: TroubleshootGuideMediaTypes.IMAGE | TroubleshootGuideMediaTypes.VIDEO;
  alt: string;
  url: string;
}

interface Actions {
  name: string;
  params: object;
}

export interface Guide {
  title?: string;
  subTitle?: string;
  text?: string;
  media?: Media[];
  actions?: Actions[];
  contextTag?: string[];
}

export interface TroubleshootGuide {
  id: string;
  label: string;
  guide: Guide[];
}

export interface ContentAsset {
  url: string;
  alt: string;
  timeout?: number;
}

interface NotificationActions {
  label: string;
  actions: Actions[];
}

export interface ContentConfiguration {
  title: string;
  action?: {
    pending?: NotificationActions[];
    success?: NotificationActions[];
    warning?: NotificationActions[];
    fail?: NotificationActions[];
    progress?: NotificationActions[];
  };
  assets?: {
    pending: ContentAsset[];
    success: ContentAsset[];
    warning: ContentAsset[];
    fail: ContentAsset[];
    progress: ContentAsset[];
  };
  description: {
    fail: string;
    pending: string;
    success: string;
    warning: string;
    progress: string;
  };
}

export interface Pages {
  [key: string]: {
    content: { [key: string]: string };
  };
}

export interface Content {
  [key: string]: ContentConfiguration;
}

export interface Configuration {
  theme: any;
  config: Config;
  menuItems: MenuItem[];
  pages: Pages;
  content: Content;
  translations: {
    [key: string]: Translations;
  };
  troubleshootGuide?: TroubleshootGuide[];
}

export interface MetaData {
  uuid: string;
  name: string;
  description: string;
  type: "APPLICATION";
  domains: string;
  version: Number;
}
class _ConfigurationService {
  private _configuration: Configuration;
  private _isInitialized: boolean = false;
  private _metaData: MetaData;

  constructor() {
    this._configuration = localConfig as Configuration;
    this._metaData = {} as MetaData;
  }

  async init(): Promise<Configuration> {
    try {
      const apiUrl = buildLocalUrl({
        path: CONFIGURATION_SERVICE_ROUTES.CONFIGURATION_SERVICE_CLIENT_CONFIGURATIONS,
      });
      const result = await get(apiUrl);
      const { data, ...rest } = result[0];
      // eslint-disable-next-line
      data.translations = await this.fetchTranslationData(data.translations);
      this._metaData = rest;
      // eslint-disable-next-line
      this._configuration = this._replacePaths(data);
      this._isInitialized = true;
      return this._configuration;
    } catch (error) {
      // eslint-disable-next-line
      this._configuration = this._replacePaths(localConfig);
      this._isInitialized = true;
      throw error;
    }
  }

  fetchTranslationData = async (
    uuid: string
  ): Promise<{
    [key: string]: Translations;
  }> => {
    try {
      if (!uuid) return {};
      const apiUrl = buildLocalUrl({
        path: CONFIGURATION_SERVICE_ROUTES.CONFIGURATION_SERVICE_CLIENT_TRANSLATIONS,
        params: {
          uuid,
        },
      });
      const response = await get(apiUrl);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  get configuration(): Configuration {
    return this._configuration;
  }

  get initialized(): boolean {
    return this._isInitialized;
  }

  get metaData(): MetaData {
    return this._metaData;
  }

  private _replacePaths(configuration: any) {
    let stringifiedData = JSON.stringify(configuration);
    stringifiedData = stringifiedData.replaceAll(
      "{STORAGE_BASE_PATH}",
      getBucketUrl()
    );
    return JSON.parse(stringifiedData);
  }
}

export const ConfigurationService = new _ConfigurationService();
