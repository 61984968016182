import { useQuery } from "@tanstack/react-query";

import {
    getMonitors,
} from "services/uptimeRobot.service";
import { QueryKeys } from "constants/queryKeys.constants";
import { useState } from "react";

interface Options {
  refetchInterval?: number;
}

function useSystemCheckApi(options: Options) {
  const [refetchInterval, setRefetchInterval] = useState(
    options.refetchInterval ? options.refetchInterval * 1000 : 600000
  );
  const systemStatusQuery = useQuery({
    queryKey: [QueryKeys.SYSTEM_CHECK],
    queryFn: async () => getMonitors(),
    refetchInterval,
    refetchIntervalInBackground: false,
  });

  return {
    systemStatusQuery,
    setRefetchInterval,
  };
}

export default useSystemCheckApi;
