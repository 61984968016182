import React from "react";
import { Backdrop, Box, Grid2 as Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { getAssetsPath } from "utils/assets.utils";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import { TrialDetail, TrialStatus } from "services/cde.service";

import StatisticsBar from "components/StatisticsBar.component";
import CardContainer from "components/CardContainer";
import Button, { ButtonColor } from "components/Button";
import IconButton from "components/IconButton";
import EditIcon from "components/icons/EditIcon";

import Label from "../components/Label";
import TrialDetails from "../components/trialDetails/TrialDetails.component";
import TrialPopover from "../components/trialDetails/TrialPopover.component";

import useTrialCardApi from "./useTrialCardApi.hook";
import { buildLocalUrl } from "utils/url.utils";
import { statusIcon } from "utils/trial.utils";
import { CardStatus, getFiltersColor } from "components/Card/Card.component";
import { ROUTES } from "constants/routes.constants";
import { StyledTrialDescription, StyledTrialName } from "../components.styles";

interface TrialCardProps {
  trial: TrialDetail;
}

const TrialCard: React.FC<TrialCardProps> = ({ trial }) => {
  const [openDrawerNewTrial, setOpenDrawerNewTrial] = useState(false);
  const [openDrawerParticipants, setOpenDrawerParticipants] = useState(false);
  const [openTrialDetails, setOpenTrialDetails] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const { t } = useTranslation();
  const queries = useTrialCardApi({ trialId: trial.uuid });

  const handleOpenParticipantClick = async (
    uuid: string,
    setOpenTrialFunction: Function
  ) => {
    const { trialId, pui } = params;
    if (trialId && pui) {
      const url = buildLocalUrl({
        path: ROUTES.TRIAL_PARTICIPANT_DETAILS,
        params: {
          trialId: uuid,
          trialMemberId: pui,
        },
      });
      navigate(url);
    } else {
      const url = buildLocalUrl({
        path: ROUTES.TRIAL_PARTICIPANTS,
        params: { trialId: uuid },
      });
      navigate(url);
    }
    setOpenTrialFunction(true);
  };

  const handleDetailClickOpen = async (uuid: string) => {
    setOpenTrialDetails(true);
    const url = buildLocalUrl({
      path: ROUTES.TRIAL_DETAILS,
      params: { trialId: uuid },
    });
    navigate(url, { replace: true });
  };

  const handleClose = () => {
    const url = buildLocalUrl({ path: ROUTES.TRIALS_MANAGEMENT });
    navigate(url, { replace: true });
    setOpenDrawerNewTrial(false);
    setOpenDrawerParticipants(false);
    setOpenTrialDetails(false);
  };

  const handleDetailClose = async () => {
    const url = buildLocalUrl({ path: ROUTES.TRIALS_MANAGEMENT });
    navigate(url, { replace: true });
    setOpenTrialDetails(false);
  };

  const handleEditClickOpen = (event: any) => {
    setEditOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleEditClose = () => {
    setEditOpen(false);
    setAnchorEl(null);
  };

  const handleTrialStatusClick = async (status: string) => {
    const { uuid, name, description, preferences } = trial;
    queries.updateTrialStatus.mutate({
      uuid,
      name,
      description,
      preferences,
      status,
    });
  };

  const theme = useTheme();

  useEffect(() => {
    if (openDrawerNewTrial || openDrawerParticipants || openTrialDetails) {
      return;
    }
    const { trialId } = params as { trialId: string };
    const participantPattern = buildLocalUrl({
      path: ROUTES.TRIAL_PARTICIPANTS,
      params: {
        trialId,
      },
    });
    const trialPattern = buildLocalUrl({
      path: ROUTES.TRIAL_DETAILS,
      params: { trialId },
    });
    if (
      trialId === trial.uuid &&
      location.pathname.includes(participantPattern)
    ) {
      handleOpenParticipantClick(trialId, setOpenDrawerNewTrial);
    } else if (
      trialId === trial.uuid &&
      location.pathname.includes(trialPattern)
    ) {
      handleOpenParticipantClick(trialId, handleDetailClickOpen);
    }
  }, []);

  return (
    <CardContainer>
      <Grid
        container
        spacing={0}
        alignItems={"center"}
        justifyContent={"flex-end"}
      >
        <Grid flex={"1"}>
          <StyledTrialName variant="h6" sx={{ display: "table" }}>
            {trial.name}
          </StyledTrialName>
        </Grid>
        <Grid>
          <Label
            showIcon={true}
            status={trial.status}
            color={getFiltersColor(
              (trial.status === TrialStatus.COMPLETE
                ? CardStatus.SUCCESS
                : trial.status === TrialStatus.TERMINATED
                ? CardStatus.FAIL
                : trial.status === TrialStatus.IN_PROGRESS
                ? CardStatus.WARNING
                : trial.status) as CardStatus,
              theme
            )}
            icon={statusIcon[trial.status as TrialStatus] as React.ElementType}
          />
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid size={{ md: 12, sm: 12, xs: 12 }}>
          <StyledTrialDescription>{trial.description}</StyledTrialDescription>
        </Grid>
      </Grid>

      <StatisticsBar
        statsData={[
          {
            icon: getAssetsPath("icons/ic_person.svg"),
            value: queries.analytics?.data?.members.members,
            label: t("total_participants"),
            hasBorder: true,
          },
          {
            icon: getAssetsPath("icons/file-icons_test-generic.svg"),
            value: queries.analytics?.data?.annotations?.total,
            label: t("trial_execution"),
          },
        ]}
        childIcons={[
          {
            show:
              trial.status !== TrialStatus.TERMINATED &&
              trial.status !== TrialStatus.COMPLETE,
            node: (
              <Box
                key="add"
                sx={{
                  padding: "1rem",
                }}
              >
                <Button
                  onClick={() =>
                    handleOpenParticipantClick(
                      trial.uuid,
                      setOpenDrawerNewTrial
                    )
                  }
                  color={ButtonColor.primary}
                >
                  {t("select")}
                </Button>
              </Box>
            ),
          },
          {
            show: true,
            node: (
              <Box
                key="edit"
                sx={{
                  padding: "1rem",
                }}
              >
                <IconButton
                  onClick={editOpen ? handleEditClose : handleEditClickOpen}
                >
                  <EditIcon />
                </IconButton>
                <TrialPopover
                  trialStatus={trial.status}
                  open={editOpen}
                  anchorEl={anchorEl}
                  trialId={trial.uuid}
                  onClose={handleEditClose}
                  handleDetailClickOpen={() =>
                    handleDetailClickOpen(trial.uuid)
                  }
                  handleUpdateStatus={(status) =>
                    handleTrialStatusClick(status)
                  }
                  handleParticipantClickOpen={() =>
                    handleOpenParticipantClick(
                      trial.uuid,
                      setOpenDrawerParticipants
                    )
                  }
                />
              </Box>
            ),
          },
        ]}
      />

      {openTrialDetails && (
        <TrialDetails
          isOpen={openTrialDetails}
          trialId={trial.uuid}
          onClose={handleDetailClose}
        />
      )}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer - 1 }}
        open={openDrawerNewTrial || openDrawerParticipants || openTrialDetails}
        onClick={handleClose}
      ></Backdrop>
    </CardContainer>
  );
};

export default TrialCard;
