import { SystemStatus } from "stores/systemCheck.store";
import { post } from "utils/publicHttp.utils";
import { ConfigurationService } from "services/configuration.service";

const UPTIMEROBOT_SERVICE_ROUTES = {
  GET_MONITORS: `https://api.uptimerobot.com/v2/getMonitors?format=json`,
  GET_PSPS: `https://api.uptimerobot.com/v2/getPSPs?format=json`,
};

const apiKey = process.env.REACT_APP_UPTIMEROBOT_APIKEY;

interface Monitor {
  id: number;
  friendly_name: string;
  url: string;
  type: number;
  sub_type: string;
  keyword_type: number;
  keyword_case_type: number;
  keyword_value: string;
  port: string;
  interval: number;
  timeout: number;
  status: number;
  create_datetime: number;
}

interface Psps {
  id: number;
  friendly_name: string;
  monitors: string[];
  sort: number;
  status: number;
  standard_url: number;
  custom_url: number;
}

const fetchUptimeRobotData = async (route: string, body: object) => {
  try {
    const response = await post(route, body);
    if (response.stat === "fail") throw new Error(response.error.message);
    return response;
  } catch (error) {
    console.error(`Error fetching data from ${route}:`, error);
    throw error;
  }
};

export const getMonitors = async (): Promise<SystemStatus | undefined> => {
  const configurationData = ConfigurationService.configuration;
  const pspsId = configurationData.config?.uptimeRobot?.pspsId;

  try {
    const pspsResponse = await fetchUptimeRobotData(
      UPTIMEROBOT_SERVICE_ROUTES.GET_PSPS,
      { api_key: apiKey, psps: pspsId }
    );

    const psps: Psps[] = pspsResponse.psps;

    if (!psps.length || psps[0].status !== 1) {
      return SystemStatus.FAIL;
    }

    const monitorsList = psps[0].monitors.join("-");
    const monitorsResponse = await fetchUptimeRobotData(
      UPTIMEROBOT_SERVICE_ROUTES.GET_MONITORS,
      { api_key: apiKey, monitors: monitorsList }
    );

    const monitors: Monitor[] = monitorsResponse.monitors;

    if (monitors.some(({ status }) => status === 9)) {
      return SystemStatus.FAIL;
    }

    return monitors.every(({ status }) => status === 2)
      ? SystemStatus.SUCCESS
      : SystemStatus.WARNING;
  } catch (error) {
    console.error("Error fetching monitors:", error);
    return undefined;
  }
};
