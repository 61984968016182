import { styled } from "@mui/system";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";

export const Header = styled(Typography)(({ theme }) => ({
  fontWeight: "700",
  fontSize: "1.5rem",
  marginBottom: "0.5rem",
}));

export const StyleAccordion = styled(Accordion)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[400]}`,
  borderRadius: "4px",
  marginBottom: "1rem",
  boxShadow: "none",
}));

export const StyleAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  borderRadius: "4px",
  backgroundColor: theme.palette.grey[200],
  "& .MuiAccordionSummary-content": {
    alignItems: "center",
  },
}));

export const StyleSubTitle = styled(Typography)(({ theme }) => ({
  fontWeight: "500",
}));

export const StyleAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  padding: "1rem",
  borderRadius: "4px",
}));
