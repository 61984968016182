import {
  TroubleshootGuideContextTag,
  TroubleshootGuideTypes,
} from "constants/index";
import { TroubleshootGuide, Guide } from "services/configuration.service";
import { create } from "zustand";

interface FilterGuidesParams {
  search: string;
  activeGuideState: string;
  guides: { [key: string]: TroubleshootGuide[] };
  contextTag?: string[];
  currentGuideId?: string;
}

interface GuideStore {
  open: boolean;
  setOpen: (open: boolean) => void;
  guideContent: { [key: string]: TroubleshootGuide[] };
  setGuideContent: (name: string, state: TroubleshootGuide[]) => void;
  searchInput: string;
  setSearchInput: (search: string) => void;
  filteredGuides: TroubleshootGuide[];
  activeGuideState: string;
  setActiveGuideState: (activeGuideState: string) => void;
  contextTag: string[];
  setContextTag: (contextTag: string[]) => void;
  resetGuide: () => void;
  currentGuideId: string;
  setCurrentGuideId: (currentGuideId: string) => void;
}

const useGuideStore = create<GuideStore>((set, get) => {
  const filterContent = (
    guides: TroubleshootGuide[],
    searchLower: string,
    filteredContextTag: string[]
  ) => {
    return guides
      .map((content: TroubleshootGuide) => {
        const contextFilteredGuide = content.guide.filter(
          (guide: Guide) =>
            !filteredContextTag.length ||
            filteredContextTag.some((tag) => guide.contextTag?.includes(tag))
        );
        const searchFilteredGuide = contextFilteredGuide.filter(
          (guide: Guide) =>
            guide?.title?.toLowerCase().includes(searchLower) ||
            guide?.subTitle?.toLowerCase().includes(searchLower) ||
            guide?.text?.toLowerCase().includes(searchLower)
        );
        return searchFilteredGuide.length
          ? { ...content, guide: searchFilteredGuide }
          : null;
      })
      .filter((content): content is TroubleshootGuide => content !== null);
  };

  const filterGuides = ({
    search,
    activeGuideState,
    guides,
    contextTag = [],
    currentGuideId = "",
  }: FilterGuidesParams) => {
    const searchLower = search.toLowerCase();
    const filteredContextTag = contextTag.filter(
      (tag) => tag !== TroubleshootGuideContextTag.VALIDATOR
    );

    let filtered = Object.entries(guides).reduce((acc, [key, value]) => {
      if (
        activeGuideState === TroubleshootGuideTypes.APPLICATION &&
        key !== activeGuideState
      ) {
        return acc;
      }
      return [...acc, ...filterContent(value, searchLower, filteredContextTag)];
    }, [] as TroubleshootGuide[]);

    if (
      contextTag.includes(TroubleshootGuideContextTag.VALIDATOR) &&
      filtered.length === 0
    ) {
      filtered = Object.entries(guides).reduce((acc, [key, value]) => {
        if (
          activeGuideState === TroubleshootGuideTypes.APPLICATION &&
          key !== activeGuideState
        ) {
          return acc;
        }
        return [
          ...acc,
          ...filterContent(value, searchLower, [
            TroubleshootGuideContextTag.VALIDATOR,
          ]),
        ];
      }, [] as TroubleshootGuide[]);
    }

    filtered = !contextTag.length
      ? filtered.filter((content) => content.id === currentGuideId).reverse()
      : filtered;

    const applicationGuideIndex = filtered.findIndex(
      (guide) => guide.id === TroubleshootGuideTypes.APPLICATION
    );
    if (applicationGuideIndex !== -1) {
      const [applicationGuide] = filtered.splice(applicationGuideIndex, 1);
      filtered.push(applicationGuide);
    }

    set({ filteredGuides: filtered });
  };

  const updateAndFilter = (partialState: Partial<GuideStore>) => {
    set(partialState);
    const {
      searchInput,
      guideContent,
      activeGuideState,
      contextTag,
      currentGuideId,
    } = get();
    filterGuides({
      search: searchInput,
      activeGuideState,
      guides: guideContent,
      contextTag,
      currentGuideId,
    });
  };

  return {
    open: false,
    currentGuideId: "",
    guideContent: { [TroubleshootGuideTypes.APPLICATION]: [] },
    setOpen: (open) => set({ open }),
    setGuideContent: (name, state) =>
      updateAndFilter({
        guideContent: { ...get().guideContent, [name]: state },
      }),
    searchInput: "",
    setSearchInput: (search) => updateAndFilter({ searchInput: search }),
    filteredGuides: [],
    activeGuideState: TroubleshootGuideTypes.APPLICATION,
    setActiveGuideState: (activeGuideState) =>
      updateAndFilter({ activeGuideState }),
    contextTag: [],
    setContextTag: (contextTag) => updateAndFilter({ contextTag }),
    resetGuide: () => updateAndFilter({ contextTag: [], searchInput: "" }),
    setCurrentGuideId: (currentGuideId) =>
      updateAndFilter({ contextTag: [], currentGuideId }),
  };
});

export default useGuideStore;
