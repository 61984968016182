import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  iconsLink,
  annotationPerPage,
  SearchType,
} from "../../constants";
import {
  InitHeaderOptions,
  InitProgressBarOptions,
  ProgressBarStatus,
  useHeaderStore,
} from "stores/header.store";
import { Box, Theme } from "@mui/system";
import { useLeftNavigationStore } from "stores/navigation.store";
import useTrialApi from "hooks/useTrialApi.hook";
import { ROUTES } from "constants/routes.constants";
import { buildLocalUrl } from "utils/url.utils";
import { Grid2 as Grid, Typography } from "@mui/material";
import { StyledTitle } from "pages/Participants/Participants.styles";
import Button, { ButtonColor } from "components/Button";
import Search from "components/Search.component";
import ResultCard from "./Components/ResultCard.component";
import Chart from "components/Chart/Chart.component";
import QuickNavigation from "components/QuickNavigation/QuickNavigation.component";
import FullWidthCardContainer from "components/FullWidthCardContainer.component";
import { getAssetsPath } from "utils/assets.utils";
import useParticipantDashboardApi from "./participant.store";
import { TrialAnnotationStatus } from "services/cde.service";
import { AnnotationStatus } from "services/annotation.service";
import useNavigationOptionsStore from "components/QuickNavigation/quicknavigation.store";
import { statusIcon } from "utils/participant.utills";
import { useStudiesStore } from "pages/StudiesDashboard/studies.store";
import Loading from "components/Loading";
import { Paginator } from "components/Paginator.component";
import ParticipantDetails from "pages/Participants/Components/ParticipantDetails.component";
import {
  annotationCountFontStyle,
  annotationTotalFontStyle,
  graphHoverFontStyle,
} from "pages/StudiesDashboard/StudiesDashboard.styles";
import { getTitle } from "utils/chart.utils";
import { useIsBetween768And1400 } from "hooks/media.hook";
import { CustomError } from "utils/privateHttp.utils";

export function ParticipantDashboard() {
  const { trialId, trialMemberId } = useParams();
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const isBetween768And1400 = useIsBetween768And1400();
  const initHeader = useHeaderStore((state) => state.initialize);
  const dashboardCdes = useLeftNavigationStore((state) => state.dashboardCdes);
  const canViewDashboard = useStudiesStore((state) => state.canViewDashboard);
  const { addNavigationState, switchNavigationState } = useLeftNavigationStore(
    (state) => state
  );
  const { navigationOptions, setNavigationOptions } =
    useNavigationOptionsStore();
  const pieChartconfig = useStudiesStore(
    (state) => state.studiesPieChartConfig
  );
  const participantStudiesPieChartLayout = useStudiesStore(
    (state) => state.studiesPieChartLayout
  );
  const pieChartData = useStudiesStore((state) => state.studiesPieChartData);
  const setParticipantStudiesPieChartData = useStudiesStore(
    (state) => state.setStudiesPieChartData
  );
  const reset = useStudiesStore((state) => state.reset);

  const [participant, setParticipants] = useState<any>({});
  const [total, setTotal] = useState(0);
  const [openParticipantDetails, setOpenParticipantDetails] = useState(false);
  const participantDefaultStatus = [
    AnnotationStatus.COMPLETE,
    AnnotationStatus.TERMINATED,
    AnnotationStatus.TIMEOUT,
  ];

  const queries = useTrialApi({
    trialId: trialId || "",
    take: 0,
    skip: 0,
    participants: false,
  });

  const participantsQueries = useParticipantDashboardApi({
    participantId: trialMemberId || "",
    trialId: trialId || "",
    take: annotationPerPage,
    expand: "INGEST",
    status: participantDefaultStatus,
  });

  const handleNavigation = (path: string) => {
    navigate(
      buildLocalUrl({
        path,
        params: { trialId },
      })
    );
  };

  const handleQuickNavigationClick = async (key: any) => {
    participantsQueries.options.status ??= participantDefaultStatus;
    const isKeySelected = participantsQueries.options?.status?.includes(key);
    participantsQueries.options.status = isKeySelected
      ? participantsQueries.options.status.filter(
          (status: any) => status !== key
        )
      : [...participantsQueries.options.status, key];

    const updatedOptions = navigationOptions.map((option) => ({
      ...option,
      defaultSelected:
        option.key === key ? !isKeySelected : option.defaultSelected,
    }));
    setNavigationOptions(updatedOptions);
    const options = {
      ...participantsQueries.options,
    };
    participantsQueries.setOptions(options);
  };

  const handleSearch = async (params: any) => {
    const { participantId, trialId } = participantsQueries.options;
    const options = {
      participantId,
      trialId,
      expand: "INGEST",
      status: participantDefaultStatus,
      ...(params.search !== "" && { search: params.search }),
    };

    const updatedOptions = navigationOptions.map((option) => ({
      ...option,
      defaultSelected: true,
    }));
    setNavigationOptions(updatedOptions);
    participantsQueries.setOptions(options);
  };

  const handleOpenParticipantClick = async () => {
    const url = buildLocalUrl({
      path: ROUTES.TRIAL_PARTICIPANT_DEVICES,
      params: { trialId, trialMemberId },
    });
    navigate(url);
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    const options = {
      ...participantsQueries.options,
      skip: (page - 1) * annotationPerPage,
    };
    if (!options.skip) delete (options as { skip?: number }).skip;
    participantsQueries.setOptions(options);
  };

  const handleParticipantDetailsOpen = () => {
    setOpenParticipantDetails(true);
  };

  const handleParticipantDetailsClose = () => {
    participantsQueries.refreshParticipantQuery();
    setOpenParticipantDetails(false);
  };

  useEffect(() => {
    if (!dashboardCdes.length) return;
    const cdeId = queries.trial.data?.cdeId;
    if (!cdeId) return;
    const cdeExists = canViewDashboard(cdeId);
    if (!cdeExists) {
      throw new CustomError(
        t("studiesDashboard.forbidden.error.message"),
        "403"
      );
    }
  }, [queries.trial.isFetched, dashboardCdes]);

  useEffect(() => {
    addNavigationState("studiesDashboard", [
      {
        icon: iconsLink.back,
        label: "participantDashboard.leftnavigation.backToStudyOverview.label",
        onClick: () =>
          handleNavigation(ROUTES.STUDIES_DASHBOARD_STUDY_OVERVIEW),
        backgroundColor: theme.palette.common.white,
        color: theme.palette.primary.dark,
      },
    ]);
    switchNavigationState("studiesDashboard");
    initHeader(
      {
        mainTitle: {
          label: "header.title.main.label.study",
          text: queries.trial.data?.name || "",
        },
        subTitleItems: [],
        showMenu: true,
      } as InitHeaderOptions,
      {
        activeStep: 2,
        allowAction: true,
        steps: [
          {
            label: "progressBar.step.trialsManagement",
            status: ProgressBarStatus.SUCCESS,
            link: ROUTES.STUDIES_DASHBOARD,
            enableLink: true,
          },
          {
            label: "progressBar.step.studyParticipants",
            status: ProgressBarStatus.SUCCESS,
            link: ROUTES.STUDIES_DASHBOARD_STUDY_PARTICIPANTS,
            enableLink: true,
          },
          {
            label: "progressBar.step.participantDashboard",
            status: ProgressBarStatus.IN_PROGRESS,
            link: ROUTES.PARTICIPANT_DASHBOARD,
            enableLink: false,
          },
        ],
      } as InitProgressBarOptions,
      [
        {
          key: "trialId",
          value: trialId || "",
        },
      ],
      false
    );
  }, [queries.trial.isFetched]);

  useEffect(() => {
    if (!participantsQueries.participant.data) return;
    setParticipants(participantsQueries.participant.data);
  }, [participantsQueries.participant.isFetching]);

  useEffect(() => {
    navigationOptions.forEach((option) => {
      option.totalCount = 0;
    });
    reset();
    const annotations =
      participantsQueries.annotationAnalyticsQuery.data?.annotations;
    if (!annotations) return;
    const { total, inProgress, pending, ...rest } = annotations;
    setTotal(total);

    const navigationOptionsObject = Object.entries(rest).map(([key, value]) => {
      const status =
        key === TrialAnnotationStatus.complete
          ? AnnotationStatus.COMPLETE
          : key === TrialAnnotationStatus.terminated
          ? AnnotationStatus.TERMINATED
          : AnnotationStatus.TIMEOUT;
      const color =
        status === AnnotationStatus.COMPLETE
          ? theme.palette.success
          : status === AnnotationStatus.TERMINATED
          ? theme.palette.warning
          : theme.palette.error;
      const totalCount = value as number;
      const result = {
        key: status as any,
        icon: statusIcon[status],
        value: status,
        color: {
          ...color,
          light: color.main,
        } as any,
        totalCount: totalCount,
        defaultSelected: totalCount > 0,
      };
      return result;
    });

    setParticipantStudiesPieChartData([
      {
        values: Object.values(rest).filter((value) => value !== 0),
        labels: Object.keys(rest).map((key) =>
          t(`participantDashboard.studies.chart.${key}.label`)
        ),
        type: "pie",
        hole: 0.6,
        marker: {
          colors: [
            theme.palette.success.main,
            theme.palette.warning.main,
            theme.palette.error.main,
          ],
        },
        pull: [0.01, 0.01, 0.01, 0.01],
        textinfo: "value",
        textfont: {
          size: 20,
          color: theme.palette.common.white,
        },
      },
    ]);
    setNavigationOptions(navigationOptionsObject);
  }, [participantsQueries.annotationAnalyticsQuery.isFetched]);

  const totalRecords = participantsQueries.annotationsQuery?.data?.total;
  const currentPage =
    (participantsQueries.annotationsQuery.data?.skip || 0) / annotationPerPage +
    1;
  const totalPages = Math.ceil((totalRecords || 0) / annotationPerPage) || 0;

  const pieChartLayout = {
    ...participantStudiesPieChartLayout,
    margin: {
      l: 0,
      r: 0,
    },
    title: {
      ...participantStudiesPieChartLayout.title,
      y: isBetween768And1400 ? 0.99 : 0.97,
      text: getTitle(
        t("participantDashboard.studies.chart.heading"),
        isBetween768And1400 ? "1.5rem" : "2rem"
      ),
      font: { color: theme.palette.text.primary, size: 24 },
    },
    annotations: [
      {
        showarrow: false,
        text: `${total}`,
        x: 0.5,
        y: 0.5,
        font: annotationTotalFontStyle(theme),
      },
      {
        showarrow: false,
        text: t("participantDashboard.studies.chart.total.label"),
        x: 0.5,
        y: 0.41,
        font: annotationCountFontStyle(theme),
      },
    ],
  };

  const annotationConfiguration = queries.trial.data?.configuration;

  if (!annotationConfiguration) return;
  const configurations = annotationConfiguration.data;

  return (
    <>
      {!queries.trial.isFetched ? (
        <Loading />
      ) : (
        <FullWidthCardContainer key="default">
          <Grid flexGrow={1}>
            <Grid
              container
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Grid>
                <StyledTitle pr={1}>{participant.pui}</StyledTitle>
              </Grid>
              <Grid>
                <img
                  src={getAssetsPath("icons/edit_participant.svg")}
                  alt={participant.pui}
                  onClick={handleParticipantDetailsOpen}
                />
              </Grid>
              <Grid flexGrow={1} display={"flex"} justifyContent={"right"}>
                <Button
                  onClick={handleOpenParticipantClick}
                  color={ButtonColor.primary}
                >
                  {t("participantDashboard.startSample.button.label")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid flexGrow={1}>
            <Grid
              container
              display={"flex"}
              gap={5}
              justifyContent={"center"}
              alignItems={"center"}
            >
              {navigationOptions.map((option) => (
                <Grid key={option.key}>
                  <QuickNavigation
                    text={option.value}
                    icon={option.icon}
                    color={option.color as Theme["palette"]}
                    count={option.totalCount}
                    defaultSelected={option.defaultSelected}
                    textColor={theme.palette.common.white}
                    onClick={() =>
                      option.totalCount
                        ? handleQuickNavigationClick(option.key)
                        : null
                    }
                  />
                </Grid>
              ))}
              <Grid flexGrow={1} display={"flex"} justifyContent={"end"}>
                <Search
                  onSearch={handleSearch}
                  placeholder={"participantDashboard.search.placeholder.label"}
                  searchType={SearchType.DATE}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid flexGrow={1}>
            <Grid container display={"flex"} spacing={0} columns={12}>
              <Grid
                size={{ md: 3, sm: 12 }}
                sx={{ minHeight: { sm: "30rem" } }}
              >
                <Typography
                  component="div"
                  sx={{
                    background: theme.palette.common.white,
                    borderRadius: "10px",
                    marginRight: { md: "1rem" },
                    marginBottom: { xs: "1rem", md: "0" },
                    padding: "1rem",
                  }}
                >
                  {pieChartData.length ? (
                    <Chart
                      data={pieChartData}
                      layout={{
                        ...pieChartLayout,
                        font: { family: theme.typography.fontFamily },
                        hoverlabel: {
                          font: graphHoverFontStyle(theme),
                        },
                      }}
                      config={pieChartconfig}
                      sx={{ margin: "1rem 0.3rem" }}
                    />
                  ) : (
                    <Loading sx={{ height: "20rem", width: "20rem" }} />
                  )}
                </Typography>
              </Grid>
              <Grid
                size={{ md: 9, sm: 12 }}
                flexGrow={1}
                sx={{
                  background: theme.palette.common.white,
                  borderRadius: "10px",
                }}
              >
                <Grid
                  container
                  p={3}
                  display={"flex"}
                  gap={2}
                  justifyContent={"space-around"}
                  flexDirection={"column"}
                >
                  <Grid flexGrow={1}>
                    <Typography
                      component="div"
                      sx={{ fontSize: isBetween768And1400 ? "1.5rem" : "2rem" }}
                    >
                      {t("participantDashboard.resultCard.heading.label")}
                    </Typography>
                  </Grid>
                  {participantsQueries.annotationsQuery.isFetched ? (
                    participantsQueries.annotationsQuery.data.total > 0 ? (
                      <Grid flexGrow={1}>
                        <Box sx={{ width: "100%" }}>
                          <Grid container spacing={2} columns={12}>
                            {participantsQueries.annotationsQuery.data?.data.map(
                              (annotation: any, index: number) => (
                                <ResultCard
                                  key={index}
                                  annotation={annotation}
                                  configurations={configurations}
                                />
                              )
                            )}
                          </Grid>
                        </Box>
                        <Grid flexGrow={1} justifyContent={"left"}>
                          <Paginator
                            total={totalPages}
                            page={currentPage}
                            show={totalPages > 1}
                            onChange={handlePageChange}
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid
                        flexGrow={1}
                        display={"flex"}
                        justifyContent={"center"}
                      >
                        <Typography component={"div"}>
                          {t(
                            "participantDashboard.resultCard.notRecordFound.text"
                          )}
                        </Typography>
                      </Grid>
                    )
                  ) : (
                    <Grid flexGrow={1}>
                      <Loading />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {queries.trial.isFetched ? (
            <ParticipantDetails
              isOpen={openParticipantDetails}
              participant={participant}
              onClose={handleParticipantDetailsClose}
              trial={queries.trial.data}
              showAnnotations={false}
            />
          ) : null}
        </FullWidthCardContainer>
      )}
    </>
  );
}
